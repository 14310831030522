import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/add_existing_specifications_to_set_wizard/AddExistingSpecificationsToSetForm.scss';
import Button from '../../components/Button';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';
import { withRouter } from 'react-router';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import Select from '../../components/Select';
import * as _ from 'lodash';
import MultiSelect from '../../components/MultiSelect';

@withRouter
@subscribe(Localization, 'localization')
@reduxForm({
  form: 'add_existing_specifications_to_set_wizard.add_existing_specifications_to_set',
})
@mapStateToProps((state) => ({
  application_language_id: state.auth.language_id,
}))
class AddExistingSpecificationsToSetForm extends Component {
  render() {
    const { specification_groups, specification_subgroups } =
      this.props.localization;

    const {
      project_language,
      application_language_id,
      possible_specification_codes,
      options,
    } = this.props;

    const specification_ids = _.map(
      _.filter(
        possible_specification_codes,
        (item) =>
          !_.includes(_.map(options, 'value'), item.specification_subgroup_id)
      ),
      (item) => {
        const specification_subgroup =
          _.get(specification_subgroups, [item.specification_subgroup_id]) ||
          {};
        const specification_group =
          _.get(
            specification_groups,
            _.get(specification_subgroup, 'specification_group_id')
          ) || {};

        const code =
          getLocalized(specification_group.code, project_language) +
          specification_subgroup.code +
          _.padStart(item.code, 2, '0');
        const title = _.filter(
          [
            getLocalized(item.description, this.props.application_language),
            item.model,
            item.brand,
          ],
          _.identity
        ).join(' / ');

        return {
          value: item.specification_id,
          label: _.filter([code, title], _.identity).join(': '),
        };
      }
    );

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.form}>
          <Field
            name='specification_ids'
            searchable={true}
            component={MultiSelect}
            label={__('table.columns.specification-set-specification-ids')}
            options={specification_ids}
            noSort
          />
        </div>
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('specification_sets', {
                add_existing_specifications_to_set_wizard_active: undefined,
              })
            }
          />
          <Button
            lightBlue
            medium
            right
            submit
            middleText={__('button.add')}
            spinning={this.props.submitting}
          />
        </div>
      </Form>
    );
  }
}

export default AddExistingSpecificationsToSetForm;
