import * as React from 'react';
import styles from '../../styles/flyouts/specification_sets/Properties.scss';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import { __, validateForm } from '../../core/utils';
import { setNotification } from '../../redux/actions/general/notification';
import { connect } from 'react-redux';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import classNames from 'classnames';
import EditSpecificationSetForm from '../../forms/specification_sets/EditSpecificationSetForm';
import {
  listSpecificationSets,
  updateSpecificationSet,
} from '../../redux/actions/table/specification_sets';

const Properties = (props) => {
  const _handleSubmit = (formData) => {
    formData = {
      ...formData,
      editors: _.map(formData.editors, 'id'),
      administrators: _.map(formData.administrators, 'id'),
    };

    return validateForm(
      updateSpecificationSet(formData.id, _.omit(formData, ['id'])).then(() => {
        listSpecificationSets(props.match.params.stage || props.table.stage_id);

        props.readAction(formData.id);

        setNotification(__('specification_sets.properties.saved'));
      })
    );
  };

  const { data, collapsed } = props;

  return (
    <div className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
      <EditSpecificationSetForm
        onSubmit={_handleSubmit}
        data={data}
        initialValues={{
          ..._.pick(data, [
            'id',
            'title',
            'description',
            'administrators',
            'editors',
          ]),
          languages: _.map(data.languages, _.toString),
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    table: state.table['specification_sets'],
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(subscribe(Localization, 'localization')(Properties))
);
