import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/MoveGenericSpecificationToGroup.scss';
import autobind from 'autobind-decorator';
import { __, mapStateToProps, validateForm } from '../core/utils';
import MoveIcon from '../assets/images/move-64x64.svg';
import { withRouter } from 'react-router';
import { subscribe } from 'react-contextual';
import Localization from '../helpers/Localization';
import _ from 'lodash';
import MoveGenericSpecificationForm from '../forms/move_generic_specification_wizard/MoveGenericSpecificationForm';
import {
  listPossibleSpecificationSubgroups,
  moveGenericSpecification,
  readSpecificationSetItems,
} from '../redux/actions/table/specification_sets';

@mapStateToProps((state) => ({
  id: _.get(
    state.table,
    'specification_sets.move_generic_specification_wizard.id'
  ),
  name: _.get(
    state.table,
    'specification_sets.move_generic_specification_wizard.name'
  ),
  store: state.table['specification_sets'],
}))
@subscribe(Localization, 'localization')
@withRouter
class MoveGenericSpecificationWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
    };
  }

  componentDidMount() {
    listPossibleSpecificationSubgroups(
      this.props.match.params.specification_set
    ).then(({ response }) => {
      this.setState({ options: response.data });
    });
  }

  @autobind
  _handleSubmit(formData) {
    const id = _.last(_.split(this.props.id, '-'));

    return validateForm(
      moveGenericSpecification(id, {
        specification_subgroup_id: formData.specification_subgroup_id,
      }).then(() => {
        readSpecificationSetItems(this.props.match.params.specification_set);

        this.props.onClose();
      })
    );
  }

  render() {
    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>
              {__('specification_set_items.row.unallocated.move.title')}
            </span>
            <span>
              {__('specification_set_items.row.unallocated.move.description')}
            </span>
          </div>
          <div className={styles.right}>
            <MoveIcon />
          </div>
        </div>
        <div className={styles.body}>
          <MoveGenericSpecificationForm
            onSubmit={this._handleSubmit}
            initialValues={{}}
            options={this.state.options}
          />
        </div>
      </>
    );
  }
}

export default MoveGenericSpecificationWizard;
