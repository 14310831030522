import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/specification_sets/DynamicColumns.scss';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';
import * as _ from 'lodash';

@withRouter
@mapStateToProps((state) => ({
  language_id: state.auth.language_id,
  tab_opened: _.get(state.table, ['working_set_specifications', 'tab']),
  user_id: state.auth.id,
}))
class DynamicColumns extends Component {
  render() {
    const { data, collapsed, language_id, user_id } = this.props;

    const { status } = data;
    const is_administrator = _.isObject(
      _.find(data.administrators, ['id', user_id])
    );

    const disabled =
      !is_administrator || !_.includes(['closed', 'canceled'], status);

    return (
      <div
        className={classNames(styles.scroller, collapsed && styles.collapsed)}
      >
        {_.map(data.dynamic_columns, (row, i) => (
          <div className={styles.row} key={i}>
            <div className={styles.left}>
              <span>{getLocalized(row.name, language_id)}</span>
            </div>
          </div>
        ))}
        {disabled && (
          <div className={styles.addRow}>
            <button
              onClick={(e) => {
                e.stopPropagation();

                setTableParams('specification_sets', {
                  edit_specification_set_dynamic_columns_wizard: data.id,
                });
              }}
            >
              {__('specification_sets.button.edit-dynamic-columns')}
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default DynamicColumns;
