import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/EditSpecificationCodeWizard.scss';
import autobind from 'autobind-decorator';
import {
  __,
  mapStateToProps,
  setTableParams,
  validateForm,
} from '../core/utils';
import EditSpecificationCodeIcon from '../assets/images/edit-specification-code-64x64.svg';
import { withRouter } from 'react-router';
import {
  listWorkingSetSpecifications,
  readWorkingSetSpecification,
  updateSpecificationCode,
} from '../redux/actions/table/working_set_specifications';
import { setNotification } from '../redux/actions/general/notification';
import * as _ from 'lodash';
import EditSpecificationCodeForm from '../forms/edit_specification_code_wizard/EditSpecificationCodeForm';
import { readSpecificationSetItems } from '../redux/actions/table/specification_sets';

@mapStateToProps((state) => ({
  store: _.get(state.table, 'working_set_specifications'),
}))
@withRouter
class EditSpecificationCodeWizard extends Component {
  @autobind
  _handleSubmit(formData) {
    return validateForm(
      updateSpecificationCode(
        this.props.store.flyout.specification_code_id,
        formData
      ).then(() =>
        readWorkingSetSpecification(this.props.store.flyout.id).then(
          ({ response }) => {
            setNotification('A specification code has been updated.');

            // If 'specification_set' exists, read its items, otherwise list working set specs and set table params.
            if (this.props.match.params.specification_set) {
              readSpecificationSetItems(
                this.props.match.params.specification_set
              );
            } else {
              listWorkingSetSpecifications(this.props.match.params.stage);

              setTableParams('working_set_specifications', {
                flyout: response.data,
              });
            }

            this.props.onClose();
          }
        )
      )
    );
  }

  render() {
    const { flyout } = this.props.store;

    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>
              {__(
                'wizard.working-set-specifications.title.edit-specification-code'
              )}
            </span>
            <span>
              {__(
                'wizard.working-set-specifications.subtitle.edit-specification-code'
              )}
            </span>
          </div>
          <div className={styles.right}>
            <EditSpecificationCodeIcon />
          </div>
        </div>
        <div className={styles.body}>
          <EditSpecificationCodeForm
            onSubmit={this._handleSubmit}
            initialValues={{
              specification_subgroup_id: flyout.specification_subgroup_id,
              code: _.padStart(flyout.code, 2, '0'),
            }}
          />
        </div>
      </>
    );
  }
}

export default EditSpecificationCodeWizard;
