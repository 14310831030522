import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/move_generic_specification_wizard/MoveGenericSpecificationForm.scss';
import Button from '../../components/Button';
import { __, setTableParams } from '../../core/utils';
import { withRouter } from 'react-router';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import Select from '../../components/Select';
import * as _ from 'lodash';
import Textarea from '../../components/Textarea';

@withRouter
@subscribe(Localization, 'localization')
@reduxForm({
  form: 'move_unallocated_generic_specification_wizard.move_unallocated_generic_specification',
})
class MoveGenericSpecificationForm extends Component {
  render() {
    const { options } = this.props;

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.form}>
          {/* <Field
            name='name'
            component={Textarea}
            label={__('move-generic-specification-item.name')}
          /> */}

          <Field
            name='specification_subgroup_id'
            component={Select}
            searchable
            noSort
            label={__(
              'move-generic-specification-item.specification_subgroup_id'
            )}
            options={options}
          />
        </div>
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('specification_sets', {
                move_generic_specification_wizard: undefined,
              })
            }
          />
          <Button
            lightBlue
            medium
            right
            submit
            middleText={__('button.move')}
            spinning={this.props.submitting}
          />
        </div>
      </Form>
    );
  }
}

export default MoveGenericSpecificationForm;
