import * as React from 'react';
import { Component } from 'react';
import MainLayout from '../../layouts/MainLayout';
import * as _ from 'lodash';
import { createSidebar } from '../../redux/actions/general/sidebar';
import {
  enableProjectsDropdown,
  enableStagesDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import {
  __,
  dateFrom,
  getActiveProject,
  getActiveStage,
  getLocalized,
  mapStateToProps,
  redirect,
  setTableParams,
} from '../../core/utils';
import Table from '../../components/Table';
import styles from '../../styles/views/specifications/SpecificationApprovals.scss';
import ThumbUpIcon from '../../assets/images/thumb-up-15x15.svg';
import ThumbDownIcon from '../../assets/images/thumb-down-15x15.svg';
import Tooltip from '../../components/Tooltip';
import { setNotification } from '../../redux/actions/general/notification';
import TableNA from '../../components/TableNA';
import Avatar from '../../components/Avatar';
import ArrowDoubleRightSmallIcon from '../../assets/images/arrow-double-right-small-15x15.svg';
import { listSpecificationApprovals } from '../../redux/actions/table/specification_approvals';
import { readProjectGeneral } from '../../redux/actions/projects';
import Image from '../../components/Image';
import SpecificationCustomIcon from '../../assets/images/specification-custom-16x16.svg';
import SpecificationFromProductIcon from '../../assets/images/specification-from-product-16x16.svg';
import SpecificationByArchitectIcon from '../../assets/images/specification-by-architect-16x16.svg';
import OriginProductIcon from '../../assets/images/product-origin-16x16.svg';

@mapStateToProps((state) => ({
  table: state.table['specification_approvals'],
  auth: state.auth,
}))
class SpecificationApprovals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      project_language: null,
    };
  }

  componentDidMount() {
    enableProjectsDropdown(({ stage_id }) =>
      redirect(
        '/stages/' +
          stage_id +
          '/specification_approvals' +
          (this.props.match.params.status
            ? '/' + this.props.match.params.status
            : '')
      )
    );
    enableStagesDropdown(({ stage_id }) =>
      redirect(
        '/stages/' +
          stage_id +
          '/specification_approvals' +
          (this.props.match.params.status
            ? '/' + this.props.match.params.status
            : '')
      )
    );

    createSidebar({
      title: __('submenu.title.specifications'),
      items: [
        {
          title: __('submenu.title.current-set-specifications'),
          link: () => '/stages/' + getActiveStage() + '/specifications/current',
        },
        {
          title: __('submenu.title.working-set-specifications'),
          link: () => '/stages/' + getActiveStage() + '/specifications/working',
        },
        {
          title: __('submenu.title.specification-sets'),
          link: () => '/stages/' + getActiveStage() + '/specifications/sets',
        },
        {
          title: __('submenu.title.deliveries'),
          link: () =>
            '/stages/' + getActiveStage() + '/specification_deliveries',
        },
        {
          title: __('submenu.title.approvals'),
          link: () =>
            '/stages/' + getActiveStage() + '/specification_approvals',
          active: true,
          items: [
            {
              title: __('submenu.title.approvals.pending'),
              link: () =>
                '/stages/' +
                getActiveStage() +
                '/specification_approvals/pending',
              active: this.props.match.params.status == 'pending',
            },
            {
              title: __('submenu.title.approvals.approved'),
              link: () =>
                '/stages/' +
                getActiveStage() +
                '/specification_approvals/approved',
              active: this.props.match.params.status == 'approved',
            },
            {
              title: __('submenu.title.approvals.rejected'),
              link: () =>
                '/stages/' +
                getActiveStage() +
                '/specification_approvals/rejected',
              active: this.props.match.params.status == 'rejected',
            },
          ],
        },
      ],
    });
  }

  render() {
    const { specification_groups, specification_subgroups } =
      this.props.localization;
    const { project_language } = this.state;

    const columns = [
      {
        key: 'code',
        name: __('specifications.approvals.table-title.code'),
        value: (code, row) => {
          const specification_subgroup =
            _.get(specification_subgroups, [row.specification_subgroup_id]) ||
            {};
          const specification_group =
            _.get(
              specification_groups,
              _.get(specification_subgroup, 'specification_group_id')
            ) || {};

          return (
            getLocalized(specification_group.code, project_language) +
            specification_subgroup.code +
            _.padStart(code, 2, '0')
          );
        },
        width: 90,
      },
      {
        key: 'custom',
        name: __('specifications.approvals.table-title.type'),
        value: (custom, row) =>
          row.product ? (
            <Tooltip
              text={__(
                'specifications.approvals.specification-created-from-product'
              )}
            >
              <SpecificationFromProductIcon className={styles.type} />
            </Tooltip>
          ) : (
            <Tooltip text={__('specifications.approvals.flyout.specification')}>
              <SpecificationByArchitectIcon className={styles.type} />
            </Tooltip>
          ),
        sortable: false,
        width: 50,
      },
      {
        key: 'thumb',
        name: __('specifications.approvals.table-title.thumbnail'),
        value: (created_by, row) =>
          row.thumbnail_url ? (
            <Tooltip
              placement='right'
              text={
                <Image
                  className={styles.thumbPreview}
                  src={row.thumbnail_url}
                />
              }
              html={true}
              dark={true}
            >
              <Image className={styles.thumb} src={row.thumbnail_url} />
            </Tooltip>
          ) : (
            <TableNA />
          ),
        width: 50,
        sortable: false,
      },
      {
        key: 'description',
        name: __('specifications.approvals.table-title.description'),
        width: 120,
        value: (value, row) => {
          const description = getLocalized(value, project_language);
          return description ? (
            <Tooltip text={description}>{description}</Tooltip>
          ) : (
            <TableNA />
          );
        },
      },
      {
        key: 'model',
        name: __('specifications.approvals.table-title.model'),
        required: true,
        default: true,
      },
      {
        key: 'brand',
        name: __('specifications.approvals.table-title.manufacturer'),
        value: (brand, row) => (row.product ? brand : row.manufacturer_company),
      },
      {
        key: 'delivery',
        name: __('specifications.approvals.table-title.delivery'),
      },
      {
        key: 'discipline',
        name: __('specifications.approvals.table-title.discipline'),
        value: (discipline, row) => {
          const prefixed_discipline = [
            row.discipline.code,
            row.discipline.id == null
              ? row.discipline.description + '*'
              : _.get(this.props.localization.disciplines, [
                  row.discipline.id,
                  'name',
                ]),
          ].join(': ');

          return (
            <Tooltip text={prefixed_discipline}>{row.discipline.code}</Tooltip>
          );
        },
        width: 50,
      },
      {
        key: 'team',
        name: __('specifications.approvals.table-title.team'),
        value: (team) => <Tooltip text={team.company}>{team.company}</Tooltip>,
      },
      {
        key: 'product',
        name: __('specifications.approvals.product-in-catalog'),
        value: (product, row) =>
          product ? (
            <Tooltip
              text={__('specifications.approvals.view-product-in-catalog')}
            >
              <OriginProductIcon
                className={styles.product}
                onClick={(e) => {
                  e.stopPropagation();

                  redirect('/products?id=' + product.id);
                }}
              />
            </Tooltip>
          ) : (
            <TableNA />
          ),
        width: 50,
      },
      {
        key: 'created_at',
        name: __('specifications.approvals.table-title.added'),
        value: (value) => dateFrom(value),
        width: 80,
      },
      {
        key: 'created_by',
        name: __('specifications.approvals.table-title.added-by'),
        value: (created_by) => (
          <Avatar
            avatar_url={created_by.avatar_url}
            name={created_by.fullname}
            active={created_by.active}
          />
        ),
        width: 50,
      },
      {
        key: 'status',
        name: __('specifications.approvals.table-title.status'),
        sortable: false,
        value: (status) => (
          <>
            {status === 0 && (
              <div className={styles.pending}>
                {__('specifications.approvals.pending')}
              </div>
            )}
            {status === 1 && (
              <div className={styles.approved}>
                {__('specifications.approvals.approved')}
              </div>
            )}
            {status === 2 && (
              <div className={styles.rejected}>
                {__('specifications.approvals.rejected')}
              </div>
            )}
          </>
        ),
        width: 120,
      },
    ];

    const singleActions = (specification_id) => {
      const pending =
        _.get(
          _.find(this.props.table.data, { id: specification_id }),
          'status'
        ) == '0';

      return pending
        ? [
            <Tooltip
              text={__('specifications.approvals.approve-specification')}
              key={_.uniqueId()}
            >
              <ThumbUpIcon
                onClick={(e) => {
                  e.stopPropagation();

                  setTableParams('specification_approvals', {
                    actioned_row: undefined,
                    wizard_active: true,
                    approve: true,
                    selected_specifications: [specification_id],
                  });
                }}
              />
            </Tooltip>,
            <Tooltip
              text={__('specifications.approvals.reject-specification')}
              key={_.uniqueId()}
            >
              <ThumbDownIcon
                onClick={(e) => {
                  e.stopPropagation();

                  setTableParams('specification_approvals', {
                    actioned_row: undefined,
                    wizard_active: true,
                    approve: false,
                    selected_specifications: [specification_id],
                  });
                }}
              />
            </Tooltip>,
          ]
        : undefined;
    };

    const groupActions = (specification_ids) => [
      <Tooltip
        text={__('specifications.approvals.approve-specifications')}
        key={_.uniqueId()}
      >
        <ThumbUpIcon
          onClick={(e) => {
            e.stopPropagation();

            if (
              _.size(
                _.filter(
                  this.props.table.data,
                  (specification) =>
                    _.includes(specification_ids, specification.id) &&
                    specification.status != 0
                )
              ) >= 1
            ) {
              setNotification({
                text: __(
                  'specifications.approvals.not-perform-action-more-specifications-approved-rejected'
                ),
                type: 'warning',
              });
            } else {
              setTableParams('specification_approvals', {
                selected_rows: [],
                wizard_active: true,
                approve: true,
                selected_specifications: specification_ids,
              });
            }
          }}
        />
      </Tooltip>,
      <Tooltip
        text={__('specifications.approvals.reject-specifications')}
        key={_.uniqueId()}
      >
        <ThumbDownIcon
          onClick={(e) => {
            e.stopPropagation();

            if (
              _.size(
                _.filter(
                  this.props.table.data,
                  (specification) =>
                    _.includes(specification_ids, specification.id) &&
                    specification.status != 0
                )
              ) >= 1
            ) {
              setNotification({
                text: __(
                  'specifications.approvals.not-perform-action-more-specifications-approved-rejected'
                ),
                type: 'warning',
              });
            } else {
              setTableParams('specification_approvals', {
                selected_rows: [],
                wizard_active: true,
                approve: false,
                selected_specifications: specification_ids,
              });
            }
          }}
        />
      </Tooltip>,
    ];

    const gridOptions = {
      section: __('submenu.title.approvals'),
      image: (id, row) => row.thumbnail_url,
      title: (row) => {
        const specification_subgroup =
          _.get(specification_subgroups, [row.specification_subgroup_id]) || {};
        const specification_group =
          _.get(
            specification_groups,
            _.get(specification_subgroup, 'specification_group_id')
          ) || {};

        return (
          getLocalized(specification_group.code, project_language) +
          specification_subgroup.code +
          _.padStart(row.code, 2, '0')
        );
      },
      subTitle: (row) => {
        return _.filter(
          [_.get(row.description, project_language), row.model, row.brand],
          _.identity
        ).join(' / ');
      },
      content: (row) => [],
    };

    return (
      <Table
        listAction={() =>
          listSpecificationApprovals(
            this.props.match.params.stage,
            this.props.match.params.status
          )
        }
        name='specification_approvals'
        title={__('submenu.title.approvals')}
        columns={columns}
        singleActions={
          (!this.props.match.params.status ||
            this.props.match.params.status == 'pending') &&
          singleActions
        }
        groupActions={
          (!this.props.match.params.status ||
            this.props.match.params.status == 'pending') &&
          groupActions
        }
        gridOptions={gridOptions}
        onLoad={() => {
          listActiveModules().then(() => {
            readProjectGeneral(getActiveProject()).then(({ response }) => {
              this.setState({ project_language: response.data.main_language });
            });
          });
        }}
      />
    );
  }
}

export default SpecificationApprovals;
