import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import Wizard from './Wizard';
import CompanyInfoWizard from '../wizards/CompanyInfoWizard';
import CompanyPartnersWizard from '../wizards/CompanyPartnersWizard';
import AddProjectWizard from '../wizards/AddProjectWizard';
import AddStageWizard from '../wizards/AddStageWizard';
import InviteNewCompanyMemberWizard from '../wizards/InviteNewCompanyMemberWizard';
import AddTeamWizard from '../wizards/AddTeamWizard';
import InviteNewUserWizard from '../wizards/InviteNewUserWizard';
import AddTeamMembersWizard from '../wizards/AddTeamMemberWizard';
import UploadPlansWizard from '../wizards/UploadPlansWizard';
import CreateDeliveryWizard from '../wizards/CreateDeliveryWizard';
import PlanApprovalWizard from '../wizards/PlanApprovalWizard';
import AddTaskWizard from '../wizards/AddTaskWizard';
import AddProductWizard from '../wizards/AddProductWizard';
import AddSpecificationWizard from '../wizards/AddSpecificationWizard';
import CopySpecificationWizard from '../wizards/CopySpecificationWizard';
import UploadDocumentsWizard from '../wizards/UploadDocumentsWizard';
import PrintTasksReportWizard from '../wizards/PrintTasksReportWizard';
import MultiplePlanScaleEditWizard from '../wizards/MultiplePlanScaleEditWizard';
import MultiplePlanTagsEditWizard from '../wizards/MultiplePlanTagsEditWizard';
import MultipleSpecificationTagsEditWizard from '../wizards/MultipleSpecificationTagsEditWizard';
import CreateMeetingWizard from '../wizards/CreateMeetingWizard';
import AddTasksToMeetingWizard from '../wizards/AddTasksToMeetingWizard';
import CreateMeetingFromTasksWizard from '../wizards/CreateMeetingFromTasksWizard';
import { setTableParams } from '../core/utils';
import UnregisteredMeetingMemberWizard from '../wizards/UnregisteredMeetingMemberWizard';
import PrintMeetingReportWizard from '../wizards/PrintMeetingReportWizard';
import SendMeetingReportWizard from '../wizards/SendMeetingReportWizard';
import CreateFollowUpMeetingWizard from '../wizards/CreateFollowUpMeetingWizard';
import AddSiteWizard from '../wizards/AddSiteWizard';
import AddBuildingWizard from '../wizards/AddBuildingWizard';
import AddStoreyWizard from '../wizards/AddStoreyWizard';
import AddZoneWizard from '../wizards/AddZoneWizard';
import AddExpansionJointWizard from '../wizards/AddExpansionJointWizard';
import AddEntranceWizard from '../wizards/AddEntranceWizard';
import AddRoomWizard from '../wizards/AddRoomWizard';
import DuplicateRoomWizard from '../wizards/DuplicateRoomWizard';
import AddRoomGroupWizard from '../wizards/AddRoomGroupWizard';
import AddRoomTypeWizard from '../wizards/AddRoomTypeWizard';
import AddOCRTemplateWizard from '../wizards/AddOCRTemplateWizard';
import OCRPlansWizard from '../wizards/OCRPlansWizard';
import { hideCreateCompanyWizard } from '../redux/actions/profile';
import EditTeamWizard from '../wizards/EditTeamWizard';
import AttachTaskToPlanWizard from '../wizards/AttachTaskToPlanWizard';
import AddProductAttributeGroupWizard from '../wizards/AddProductAttributeGroupWizard';
import EditProductAttributeGroupWizard from '../wizards/EditProductAttributeGroupWizard';
import AddProductAttributeGroupFieldWizard from '../wizards/AddProductAttributeGroupFieldWizard';
import EditProductAttributeGroupFieldWizard from '../wizards/EditProductAttributeGroupFieldWizard';
import AddSpecificationProductAttributeGroupWizard from '../wizards/AddSpecificationProductAttributeGroupWizard';
import EditSpecificationProductAttributeGroupWizard from '../wizards/EditSpecificationProductAttributeGroupWizard';
import AddSpecificationProductAttributeGroupFieldWizard from '../wizards/AddSpecificationProductAttributeGroupFieldWizard';
import EditSpecificationProductAttributeGroupFieldWizard from '../wizards/EditSpecificationProductAttributeGroupFieldWizard';
import CreateSpecificationFromProductWizard from '../wizards/CreateSpecificationFromProductWizard';
import CreateSpecificationDeliveryWizard from '../wizards/CreateSpecificationDeliveryWizard';
import PrintCurrentSetSpecificationsWizard from '../wizards/PrintCurrentSetSpecificationsWizard';
import PrintWorkingSetSpecificationsWizard from '../wizards/PrintWorkingSetSpecificationsWizard';
import SpecificationApprovalWizard from '../wizards/SpecificationApprovalWizard';
import AddMaterialWizard from '../wizards/superadmin/AddMaterialWizard';
import AddRawMaterialWizard from '../wizards/superadmin/AddRawMaterialWizard';
import AddLanguageWizard from '../wizards/superadmin/AddLanguageWizard';
import AddColorWizard from '../wizards/superadmin/AddColorWizard';
import AddCountryWizard from '../wizards/superadmin/AddCountryWizard';
import AddUnitWizard from '../wizards/superadmin/AddUnitWizard';
import AddPaperSizeWizard from '../wizards/superadmin/AddPaperSizeWizard';
import AddBuildingTypeWizard from '../wizards/superadmin/AddBuildingTypeWizard';
import AddLinkedSpecificationWizard from '../wizards/AddLinkedSpecificationWizard';
import AddPartToProductWizard from '../wizards/AddPartToProductWizard';
import EditLinkedSpecificationWizard from '../wizards/EditLinkedSpecificationWizard';
import EditSpecificationFileCategoryWizard from '../wizards/EditSpecificationFileCategoryWizard';
import EditSpecificationCodeWizard from '../wizards/EditSpecificationCodeWizard';
import AddAddressBookCompanyWizard from '../wizards/AddAddressBookCompanyWizard';
import AddAddressBookContactWizard from '../wizards/AddAddressBookContactWizard';
import AddTranslationWizard from '../wizards/superadmin/AddTranslationWizard';
import AddRegionWizard from '../wizards/superadmin/AddRegionWizard';
import AddUnitGroupWizard from '../wizards/superadmin/AddUnitGroupWizard';
import AddFunctionalSpaceWizard from '../wizards/superadmin/AddFunctionalSpaceWizard';
import AddDisciplineWizard from '../wizards/superadmin/AddDisciplineWizard';
import AddNoteWizard from '../wizards/superadmin/AddNoteWizard';
import AddRawColorWizard from '../wizards/superadmin/AddRawColorWizard';
import AddProductCategoryWizard from '../wizards/superadmin/AddProductCategoryWizard';
import AddProductGroupWizard from '../wizards/superadmin/AddProductGroupWizard';
import AddProductSubgroupWizard from '../wizards/superadmin/AddProductSubgroupWizard';
import AddSpecificationGroupWizard from '../wizards/superadmin/AddSpecificationGroupWizard';
import AddSpecificationSubgroupWizard from '../wizards/superadmin/AddSpecificationSubgroupWizard';
import AddProductAttributeTemplateGroupWizard from '../wizards/superadmin/AddProductAttributeTemplateGroupWizard';
import AddProductAttributeTemplateFieldWizard from '../wizards/superadmin/AddProductAttributeTemplateFieldWizard';
import AddBOQTemplateGroupWizard from '../wizards/superadmin/AddBOQTemplateGroupWizard';
import AddBOQTemplateSubgroupWizard from '../wizards/superadmin/AddBOQTemplateSubgroupWizard';
import AddBOQTemplateCalculationSuperadmin from '../wizards/superadmin/AddBOQTemplateCalculationWizard';
import AddBOQSupplyTypesWizard from '../wizards/superadmin/AddBOQSupplyTypesWizard';
import AddBOQSupplyTypeGroupsWizard from '../wizards/superadmin/AddBOQSupplyTypeGroupsWizard';
import AddProductAttributeGroupAsTemplateWizard from '../wizards/AddProductAttributeGroupAsTemplateWizard';
import AddProductNoteWizard from '../wizards/AddProductNoteWizard';
import AddSpecificationProductAttributeGroupAsTemplateWizard from '../wizards/AddSpecificationProductAttributeGroupAsTemplateWizard';
import AddSpecificationProductNoteWizard from '../wizards/AddSpecificationProductNoteWizard';
import EditSpecificationProductNoteWizard from '../wizards/EditSpecificationProductNoteWizard';
import AddBrandWizard from '../wizards/AddBrandWizard';
import AddSpecificationToCalculationWizard from '../wizards/AddSpecificationToCalculationWizard';
import CopyBOQItemWizard from '../wizards/CopyBOQItemWizard';
import MoveBOQItemWizard from '../wizards/MoveBOQItemWizard';
import AddRelatedTaskWizard from '../wizards/AddRelatedTaskWizard';
import AddFilterWizard from '../wizards/superadmin/AddFilterWizard';
import EditProductAttributeGroupFieldValuesWizard from '../wizards/EditProductAttributeGroupFieldValuesWizard';
import ChangeProductVariantAttributesWizard from '../wizards/ChangeProductVariantAttributesWizard';
import AddAccessoryToProductWizard from '../wizards/AddAccessoryToProductWizard';
import AddNoteCategoryWizard from '../wizards/superadmin/AddNoteCategoryWizard';
import CopySpecificationsWizard from '../wizards/CopySpecificationsWizard';
import EditProductFileCategoryWizard from '../wizards/EditProductFileCategoryWizard';
import ImportSpreadsheetWizard from '../wizards/ImportSpreadsheetWizard';
import CreateTenderWizard from '../wizards/CreateTenderWizard';
import AddBidderWizard from '../wizards/AddBidderWizard';
import CreateTenderBOQFromBOQWizard from '../wizards/CreateTenderBOQFromBOQWizard';
import CreateSpecificationFromWSBOQWizard from '../wizards/CreateSpecificationFromWSBOQWizard';
import EditProductNoteWizard from '../wizards/EditProductNoteWizard';
import MoveMeetingItemWizard from '../wizards/MoveMeetingItemWizard';
import AddBOQItemsAsTemplateWizard from '../wizards/AddBOQItemsAsTemplateWizard';
import AddBOQItemsFromTemplateWizard from '../wizards/AddBOQItemsFromTemplateWizard';
import AddBOQCalculationFromTemplateWizard from '../wizards/AddBOQCalculationFromTemplateWizard';
import AddBOQCalculationAsTemplateWizard from '../wizards/AddBOQCalculationAsTemplateWizard';
import AttachTemplatesToBOQItemWizard from '../wizards/AttachTemplatesToBOQItemWizard';
import MoveBOQPositionWizard from '../wizards/MoveBOQPositionWizard';
import MergeBOQItemWizard from '../wizards/MergeBOQItemWizard';
import AddSpecificationsToSetWizard from '../wizards/AddSpecificationsToSetWizard';
import EditSpecificationSetDynamicColumnsWizard from '../wizards/EditSpecificationSetDynamicColumnsWizard';
import CreateSpecificationSetWizard from '../wizards/CreateSpecificationSetWizard';
import ConvertGenericSpecificationToSpecificationWizard from '../wizards/ConvertGenericSpecificationToSpecificationWizard';
import MoveGenericSpecificationWizard from '../wizards/MoveGenericSpecificationWizard';
import CreateGenericSpecificationWizard from '../wizards/CreateGenericSpecificationWizard';
import AddExistingSpecificationsToSetWizard from '../wizards/AddExistingSpecificationsToSetWizard';
import AddSpecificationSetTemplateWizard from '../wizards/AddSpecificationSetTemplateWizard';
import EditSpecificationRevisionDescriptionWizard from '../wizards/EditSpecificationRevisionDescriptionWizard';

const mapStateToProps = (state) => ({
  auth: state.auth,
  wizard_add_company_partners: _.get(
    state.table,
    'partner_companies.wizard_active'
  ),
  wizard_add_project: _.get(state.table, 'all_projects.wizard_active'),
  wizard_add_stage: _.get(state.table, 'stages.wizard_active'),
  wizard_invite_new_company_member: _.get(
    state.table,
    'my_company_members.wizard_active'
  ),
  wizard_import_spreadsheet: _.get(
    state.table,
    'import_spreadsheet.wizard_active'
  ),
  wizard_add_team: _.get(state.table, 'teams.add_team_wizard_active'),
  wizard_invite_new_user: _.get(state.table, 'all_companies.wizard_active'),
  wizard_add_team_members: _.get(
    state.table,
    'teams.add_team_members_wizard_active'
  ),
  wizard_upload_plans: _.get(state.table, 'plan_uploads.wizard_active'),
  wizard_create_delivery: _.get(state.table, 'working_set_plans.wizard_active'),
  wizard_plan_approvals: _.get(state.table, 'plan_approvals.wizard_active'),
  wizard_add_task: _.get(state.table, 'all_tasks.wizard_active'),
  wizard_add_tender: _.get(state.table, 'tenders.wizard_active'),
  wizard_add_specification_set: _.get(
    state.table,
    'specification_sets.wizard_active'
  ),
  wizard_add_specification_set_template: _.get(
    state.table,
    'specification_sets.add_specification_set_template_wizard_active'
  ),
  wizard_move_generic_specification: _.get(
    state.table,
    'specification_sets.move_generic_specification_wizard'
  ),
  wizard_create_generic_specification: _.get(
    state.table,
    'specification_sets.create_generic_specification_wizard_active'
  ),
  wizard_add_existing_specifications_to_set: _.get(
    state.table,
    'specification_sets.add_existing_specifications_to_set_wizard_active'
  ),
  wizard_convert_generic_specification_to_specification: _.get(
    state.table,
    'specification_sets.wizard_convert_generic_specification_to_specification'
  ),
  wizard_add_bidder: _.get(state.table, 'tenders.add_bidder_wizard_active'),
  wizard_add_product: _.get(state.table, 'products.wizard_active'),
  wizard_add_specification: _.get(
    state.table,
    'working_set_specifications.wizard_active'
  ),
  wizard_copy_specification: _.get(
    state.table,
    'working_set_specifications.copy_wizard_active'
  ),
  wizard_copy_specifications: _.get(
    state.table,
    'working_set_specifications.copy_specifications_wizard_active'
  ),
  wizard_upload_documents: _.get(state.table, 'documents.wizard_active'),
  wizard_print_tasks_report: _.get(
    state.table,
    'all_tasks.print_tasks_report_wizard'
  ),
  wizard_multiple_plan_scale_edit: _.get(
    state.table,
    'plan_uploads.wizard_multiple_plan_scale_edit'
  ),
  wizard_multiple_plan_tags_edit: _.get(
    state.table,
    'plan_uploads.wizard_multiple_plan_tags_edit'
  ),
  wizard_create_meeting: _.get(state.table, 'all_meetings.wizard_active'),
  wizard_add_tasks_to_meeting: _.get(
    state.table,
    'all_meetings.wizard_add_tasks_to_meeting'
  ),
  wizard_create_meeting_from_tasks: _.get(
    state.table,
    'all_meetings.meeting_from_tasks'
  ),
  wizard_unregistered_meeting_member: _.get(
    state.table,
    'all_meetings.wizard_unregistered_meeting_member'
  ),
  wizard_print_meeting_report: _.get(
    state.table,
    'all_meetings.print_meeting_report_wizard'
  ),
  wizard_send_meeting_report: _.get(
    state.table,
    'all_meetings.send_meeting_report_wizard'
  ),
  wizard_create_followup_meeting: _.get(
    state.table,
    'all_meetings.create_followup_meeting_wizard'
  ),
  wizard_move_meeting_item: _.get(
    state.table,
    'all_meetings.move_meeting_item_wizard'
  ),
  wizard_add_site: _.get(state.table, 'sites.add_site_wizard'),
  wizard_add_building: _.get(state.table, 'buildings.add_building_wizard'),
  wizard_add_storey: _.get(state.table, 'storeys.add_storey_wizard'),
  wizard_add_zone: _.get(state.table, 'zones.add_zone_wizard'),
  wizard_add_expansion_joint: _.get(
    state.table,
    'expansion_joints.add_expansion_joint_wizard'
  ),
  wizard_add_entrance: _.get(state.table, 'entrances.add_entrance_wizard'),
  wizard_add_room: _.get(state.table, 'rooms.add_room_wizard'),
  wizard_duplicate_room: _.get(state.table, 'rooms.duplicate'),
  wizard_add_room_group: _.get(
    state.table,
    'room_groups.add_room_group_wizard'
  ),
  wizard_add_room_type: _.get(state.table, 'room_types.add_room_type_wizard'),
  wizard_add_ocr_template: _.get(
    state.table,
    'ocr_templates.add_ocr_template_wizard'
  ),
  wizard_ocr_plans: _.get(state.table, 'plan_uploads.ocr_plans_wizard'),
  wizard_create_company: _.get(state.profile, 'create_company_wizard'),
  wizard_edit_team: _.get(state.table, 'teams.edit_team'),
  wizard_attach_task: _.get(state.table, 'all_tasks.attach_task_wizard'),
  wizard_add_product_attribute_group: _.get(
    state.table,
    'products.add_product_attribute_group_wizard'
  ),
  wizard_edit_product_attribute_group: _.get(
    state.table,
    'products.edit_product_attribute_group_wizard'
  ),
  wizard_add_product_attribute_group_field: _.get(
    state.table,
    'products.add_product_attribute_group_field_wizard'
  ),
  wizard_edit_product_attribute_group_field: _.get(
    state.table,
    'products.edit_product_attribute_group_field_wizard'
  ),
  wizard_add_specification_product_attribute_group: _.get(
    state.table,
    'working_set_specifications.add_specification_product_attribute_group_wizard'
  ),
  wizard_edit_specification_set_dynamic_columns: _.get(
    state.table,
    'specification_sets.edit_specification_set_dynamic_columns_wizard'
  ),
  wizard_edit_specification_product_attribute_group: _.get(
    state.table,
    'working_set_specifications.edit_specification_product_attribute_group_wizard'
  ),
  wizard_edit_specification_revision_description: _.get(
    state.table,
    'working_set_specifications.edit_specification_revision_description_wizard'
  ),
  wizard_add_specification_product_attribute_group_field: _.get(
    state.table,
    'working_set_specifications.add_specification_product_attribute_group_field_wizard'
  ),
  wizard_edit_specification_product_attribute_group_field: _.get(
    state.table,
    'working_set_specifications.edit_specification_product_attribute_group_field_wizard'
  ),
  wizard_edit_specification_file_category: _.get(
    state.table,
    'working_set_specifications.edit_specification_file_category'
  ),
  wizard_create_specification_from_product: _.get(
    state.table,
    'products.create_specification_from_product_wizard'
  ),
  wizard_create_specification_delivery: _.get(
    state.table,
    'working_set_specifications.delivery_wizard_active'
  ),
  wizard_print_current_set_specifications: _.get(
    state.table,
    'current_set_specifications.print_specifications_wizard'
  ),
  wizard_print_working_set_specifications: _.get(
    state.table,
    'working_set_specifications.print_specifications_wizard'
  ),
  wizard_edit_specification_revision_description: _.get(
    state.table,
    'working_set_specifications.edit_specification_revision_description_wizard'
  ),
  wizard_multiple_specification_tags_edit: _.get(
    state.table,
    'working_set_specifications.multiple_specification_tags_edit_wizard'
  ),
  wizard_specification_approvals: _.get(
    state.table,
    'specification_approvals.wizard_active'
  ),
  wizard_superadmin_add_material: _.get(
    state.table,
    'superadmin_materials.add_material_wizard'
  ),
  wizard_superadmin_add_raw_material: _.get(
    state.table,
    'superadmin_raw_materials.add_raw_material_wizard'
  ),
  wizard_superadmin_add_language: _.get(
    state.table,
    'superadmin_languages.add_language_wizard'
  ),
  wizard_superadmin_add_translation: _.get(
    state.table,
    'superadmin_translations.add_translation_wizard'
  ),
  wizard_superadmin_add_note: _.get(
    state.table,
    'superadmin_notes.add_note_wizard'
  ),
  wizard_superadmin_add_color: _.get(
    state.table,
    'superadmin_colors.add_color_wizard'
  ),
  wizard_superadmin_add_paper_size: _.get(
    state.table,
    'superadmin_paper_sizes.add_paper_size_wizard'
  ),
  wizard_superadmin_add_country: _.get(
    state.table,
    'superadmin_countries.add_country_wizard'
  ),
  wizard_superadmin_add_product_attribute_template_group: _.get(
    state.table,
    'superadmin_product_attribute_template_groups.add_product_attribute_template_group_wizard'
  ),
  wizard_superadmin_add_product_attribute_template_field: _.get(
    state.table,
    'superadmin_product_attribute_template_fields.add_product_attribute_template_field_wizard'
  ),
  wizard_superadmin_add_unit: _.get(
    state.table,
    'superadmin_units.add_unit_wizard'
  ),
  wizard_superadmin_add_unit_group: _.get(
    state.table,
    'superadmin_unit_groups.add_unit_group_wizard'
  ),
  wizard_superadmin_add_region: _.get(
    state.table,
    'superadmin_regions.add_region_wizard'
  ),
  wizard_superadmin_add_building_type: _.get(
    state.table,
    'superadmin_building_types.add_building_type_wizard'
  ),
  wizard_superadmin_add_functional_space: _.get(
    state.table,
    'superadmin_functional_spaces.add_functional_space_wizard'
  ),
  wizard_superadmin_add_discipline: _.get(
    state.table,
    'superadmin_disciplines.add_discipline_wizard'
  ),
  wizard_superadmin_add_raw_color: _.get(
    state.table,
    'superadmin_raw_colors.add_raw_color_wizard'
  ),
  wizard_superadmin_add_product_category: _.get(
    state.table,
    'superadmin_product_categories.add_product_category_wizard'
  ),
  wizard_superadmin_add_note_category: _.get(
    state.table,
    'superadmin_note_categories.add_note_category_wizard'
  ),
  wizard_superadmin_add_product_group: _.get(
    state.table,
    'superadmin_product_groups.add_product_group_wizard'
  ),
  wizard_superadmin_add_product_subgroup: _.get(
    state.table,
    'superadmin_product_subgroups.add_product_subgroup_wizard'
  ),
  wizard_superadmin_add_specification_group: _.get(
    state.table,
    'superadmin_specification_groups.add_specification_group_wizard'
  ),
  wizard_superadmin_add_specification_subgroup: _.get(
    state.table,
    'superadmin_specification_subgroups.add_specification_subgroup_wizard'
  ),
  wizard_superadmin_add_boq_template_group: _.get(
    state.table,
    'superadmin_boq_template_groups.add_boq_template_group_wizard'
  ),
  wizard_superadmin_add_boq_template_subgroup: _.get(
    state.table,
    'superadmin_boq_template_subgroups.add_boq_template_subgroup_wizard'
  ),
  wizard_superadmin_add_boq_template_calculation: _.get(
    state.table,
    'superadmin_boq_template_calculations.add_boq_template_calculation_wizard'
  ),
  wizard_superadmin_add_boq_supply_types: _.get(
    state.table,
    'superadmin_boq_supply_types.add_boq_supply_types_wizard'
  ),
  wizard_superadmin_add_boq_supply_type_groups: _.get(
    state.table,
    'superadmin_boq_supply_type_groups.add_boq_supply_type_groups_wizard'
  ),
  wizard_superadmin_add_filter: _.get(
    state.table,
    'superadmin_filters.add_filter_wizard'
  ),
  wizard_add_linked_specification: _.get(
    state.table,
    'working_set_specifications.add_linked_specification_wizard'
  ),
  wizard_edit_linked_specification: _.get(
    state.table,
    'working_set_specifications.edit_linked_specification_wizard'
  ),
  wizard_edit_specification_code: _.get(
    state.table,
    'working_set_specifications.edit_specification_code_wizard'
  ),
  wizard_add_address_book_company: _.get(
    state.table,
    'address_book_companies.wizard_active'
  ),
  wizard_add_address_book_contact: _.get(
    state.table,
    'address_book_contacts.wizard_active'
  ),
  wizard_save_product_attribute_group_as_template: _.get(
    state.table,
    'products.save_product_attribute_group_as_template_wizard'
  ),
  wizard_save_specification_product_attribute_group_as_template: _.get(
    state.table,
    'working_set_specifications.save_specification_product_attribute_group_as_template_wizard'
  ),
  wizard_add_product_note: _.get(
    state.table,
    'products.add_product_note_wizard'
  ),
  wizard_add_specification_product_note: _.get(
    state.table,
    'working_set_specifications.add_specification_product_note_wizard'
  ),
  wizard_edit_specification_product_note: _.get(
    state.table,
    'working_set_specifications.edit_specification_product_note_wizard'
  ),
  wizard_edit_product_note: _.get(
    state.table,
    'products.edit_product_note_wizard'
  ),
  wizard_add_brand: _.get(state.table, 'brands.wizard_active'),
  wizard_add_part_to_product: _.get(
    state.table,
    'products.add_part_to_product_wizard'
  ),
  wizard_add_accessory_to_product: _.get(
    state.table,
    'products.add_accessory_to_product_wizard'
  ),
  wizard_edit_product_attribute_group_field_values: _.get(
    state.table,
    'products.edit_product_attribute_group_field_values_wizard'
  ),
  wizard_change_product_variant_attributes: _.get(
    state.table,
    'products.change_product_variant_attributes_wizard'
  ),
  wizard_create_specification_from_ws_boq: _.get(
    state.table,
    'working_set_boq.create_specification_from_ws_boq_wizard'
  ),
  wizard_add_boq_template_calculation: _.get(
    state.table,
    'working_set_boq.add_boq_template_calculation_wizard'
  ),

  wizard_add_boq_calculation_from_template: _.get(
    state.table,
    'working_set_boq.add_boq_calculation_from_template_wizard'
  ),

  wizard_attach_templates_to_boq: _.get(
    state.table,
    'working_set_boq.attach_templates_to_boq_wizard'
  ),

  wizard_add_specification_to_calculation: _.get(
    state.table,
    'working_set_boq.add_specification_to_calculation_wizard'
  ),
  wizard_copy_boq_item: _.get(
    state.table,
    'working_set_boq.copy_boq_item_wizard'
  ),
  wizard_move_boq_item: _.get(
    state.table,
    'working_set_boq.move_boq_item_wizard'
  ),
  wizard_create_tender_boq_from_boq: _.get(
    state.table,
    'working_set_boq.create_tender_boq_from_boq'
  ),
  wizard_save_boq_items_as_template: _.get(
    state.table,
    'working_set_boq.save_boq_items_as_template'
  ),
  wizard_add_boq_items_from_template: _.get(
    state.table,
    'working_set_boq.add_boq_items_from_template'
  ),
  wizard_add_related_task: _.get(
    state.table,
    'all_tasks.add_related_task_wizard'
  ),
  wizard_edit_product_file_category: _.get(
    state.table,
    'products.edit_product_file_category'
  ),
  wizard_move_boq_position: _.get(
    state.table,
    'working_set_specifications.move_boq_position_wizard'
  ),
  wizard_merge_boq_item: _.get(
    state.table,
    'working_set_boq.merge_boq_item_wizard'
  ),
  wizard_add_specifications_to_set: _.get(
    state.table,
    'working_set_specifications.add_specifications_to_set_wizard'
  ),
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    hideAddCompanyPartnersWizard: () =>
      dispatch({
        type: 'table.partner_companies/SET_TABLE_PARAMS',
        params: {
          wizard_active: undefined,
        },
      }),
    hideAddProjectWizard: () =>
      dispatch({
        type: 'table.all_projects/SET_TABLE_PARAMS',
        params: {
          wizard_active: undefined,
        },
      }),
    hideAddStageWizard: () =>
      dispatch({
        type: 'table.stages/SET_TABLE_PARAMS',
        params: {
          wizard_active: undefined,
        },
      }),
    hideInviteNewCompanyMemberWizard: () =>
      dispatch({
        type: 'table.my_company_members/SET_TABLE_PARAMS',
        params: {
          wizard_active: undefined,
        },
      }),
    hideAddTeamWizard: () =>
      dispatch({
        type: 'table.teams/SET_TABLE_PARAMS',
        params: {
          add_team_wizard_active: undefined,
        },
      }),
    hideInviteNewUserWizard: () =>
      dispatch({
        type: 'table.all_companies/SET_TABLE_PARAMS',
        params: {
          wizard_active: undefined,
        },
      }),
    hideAddTeamMembersWizard: () =>
      dispatch({
        type: 'table.teams/SET_TABLE_PARAMS',
        params: {
          add_team_members_wizard_active: undefined,
          team_id: undefined,
        },
      }),
    hideCreateDeliveryWizard: () =>
      dispatch({
        type: 'table.working_set_plans/SET_TABLE_PARAMS',
        params: {
          wizard_active: undefined,
        },
      }),
    hidePlanApprovalsWizard: () =>
      dispatch({
        type: 'table.plan_approvals/SET_TABLE_PARAMS',
        params: {
          wizard_active: undefined,
          approve: undefined,
        },
      }),
    hideAddTaskWizard: () =>
      dispatch({
        type: 'table.all_tasks/SET_TABLE_PARAMS',
        params: {
          wizard_active: undefined,
        },
      }),
    hideCreateTenderWizard: () =>
      dispatch({
        type: 'table.tenders/SET_TABLE_PARAMS',
        params: {
          wizard_active: undefined,
        },
      }),
    hideCreateSpecificationSetWizard: () =>
      dispatch({
        type: 'table.specification_sets/SET_TABLE_PARAMS',
        params: {
          wizard_active: undefined,
        },
      }),
    hideSpecificationSetTemplateWizard: () =>
      dispatch({
        type: 'table.specification_sets/SET_TABLE_PARAMS',
        params: {
          add_specification_set_template_wizard_active: undefined,
        },
      }),
    hideConvertGenericSpecificationToSpecification: () =>
      dispatch({
        type: 'table.specification_sets/SET_TABLE_PARAMS',
        params: {
          wizard_convert_generic_specification_to_specification: undefined,
        },
      }),
    hideAddBidderWizard: () =>
      dispatch({
        type: 'table.tenders/SET_TABLE_PARAMS',
        params: {
          add_bidder_wizard_active: undefined,
        },
      }),
    hideAddProductWizard: () =>
      dispatch({
        type: 'table.products/SET_TABLE_PARAMS',
        params: {
          wizard_active: undefined,
        },
      }),
    hidePrintTasksReportWizard: () =>
      dispatch({
        type: 'table.all_tasks/SET_TABLE_PARAMS',
        params: {
          print_tasks_report_wizard: undefined,
        },
      }),
    hideMultiplePlanScaleEditWizard: () =>
      dispatch({
        type: 'table.plan_uploads/SET_TABLE_PARAMS',
        params: {
          wizard_multiple_plan_scale_edit: undefined,
        },
      }),
    hideMultiplePlanTagsEditWizard: () =>
      dispatch({
        type: 'table.plan_uploads/SET_TABLE_PARAMS',
        params: {
          wizard_multiple_plan_tags_edit: undefined,
        },
      }),
    hideCreateMeetingWizard: () =>
      dispatch({
        type: 'table.all_meetings/SET_TABLE_PARAMS',
        params: {
          wizard_active: undefined,
        },
      }),
    hideAddTasksToMeetingWizard: () =>
      dispatch({
        type: 'table.all_meetings/SET_TABLE_PARAMS',
        params: {
          wizard_add_tasks_to_meeting: undefined,
        },
      }),
    hideCreateMeetingFromTasksWizard: () =>
      dispatch({
        type: 'table.all_meetings/SET_TABLE_PARAMS',
        params: {
          meeting_from_tasks: undefined,
        },
      }),
  },
});

@connect(mapStateToProps, mapDispatchToProps)
class Wizards extends Component {
  render() {
    return (
      <>
        {this.props.wizard_create_company && (
          <Wizard>
            <CompanyInfoWizard onClose={hideCreateCompanyWizard} />
          </Wizard>
        )}
        {this.props.wizard_add_company_partners && (
          <Wizard
            extraSmall
            onClose={this.props.actions.hideAddCompanyPartnersWizard}
          >
            <CompanyPartnersWizard />
          </Wizard>
        )}
        {this.props.wizard_add_project && (
          <Wizard small onClose={this.props.actions.hideAddProjectWizard}>
            <AddProjectWizard />
          </Wizard>
        )}
        {this.props.wizard_add_stage && (
          <Wizard small onClose={this.props.actions.hideAddStageWizard}>
            <AddStageWizard />
          </Wizard>
        )}
        {this.props.wizard_invite_new_company_member && (
          <Wizard
            small
            onClose={this.props.actions.hideInviteNewCompanyMemberWizard}
          >
            <InviteNewCompanyMemberWizard />
          </Wizard>
        )}

        {this.props.wizard_add_team && (
          <Wizard extraSmall onClose={this.props.actions.hideAddTeamWizard}>
            <AddTeamWizard />
          </Wizard>
        )}
        {this.props.wizard_invite_new_user && (
          <Wizard
            extraSmall
            onClose={this.props.actions.hideInviteNewUserWizard}
          >
            <InviteNewUserWizard />
          </Wizard>
        )}
        {this.props.wizard_add_team_members && (
          <Wizard small onClose={this.props.actions.hideAddTeamMembersWizard}>
            <AddTeamMembersWizard />
          </Wizard>
        )}
        {this.props.wizard_upload_plans && (
          <Wizard small>
            <UploadPlansWizard />
          </Wizard>
        )}
        {this.props.wizard_create_delivery && (
          <Wizard small onClose={this.props.actions.hideCreateDeliveryWizard}>
            <CreateDeliveryWizard />
          </Wizard>
        )}
        {this.props.wizard_plan_approvals && (
          <Wizard small onClose={this.props.actions.hidePlanApprovalsWizard}>
            <PlanApprovalWizard />
          </Wizard>
        )}
        {this.props.wizard_add_task && (
          <Wizard small onClose={this.props.actions.hideAddTaskWizard}>
            <AddTaskWizard />
          </Wizard>
        )}
        {this.props.wizard_add_tender && (
          <Wizard small onClose={this.props.actions.hideCreateTenderWizard}>
            <CreateTenderWizard />
          </Wizard>
        )}
        {this.props.wizard_add_bidder && (
          <Wizard small onClose={this.props.actions.hideAddBidderWizard}>
            <AddBidderWizard />
          </Wizard>
        )}
        {this.props.wizard_add_product && (
          <Wizard onClose={this.props.actions.hideAddProductWizard}>
            <AddProductWizard />
          </Wizard>
        )}
        {this.props.wizard_add_specification && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_specifications', {
                wizard_active: undefined,
                linked_specification_code_id: undefined,
              })
            }
          >
            <AddSpecificationWizard />
          </Wizard>
        )}
        {this.props.wizard_copy_specification && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_specifications', {
                copy_wizard_active: undefined,
              })
            }
          >
            <CopySpecificationWizard />
          </Wizard>
        )}
        {this.props.wizard_copy_specifications && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_specifications', {
                copy_specifications_wizard_active: undefined,
              })
            }
          >
            <CopySpecificationsWizard />
          </Wizard>
        )}
        {this.props.wizard_upload_documents && (
          <Wizard small>
            <UploadDocumentsWizard />
          </Wizard>
        )}
        {this.props.wizard_print_tasks_report && (
          <Wizard small onClose={this.props.actions.hidePrintTasksReportWizard}>
            <PrintTasksReportWizard />
          </Wizard>
        )}
        {this.props.wizard_multiple_plan_scale_edit && (
          <Wizard
            small
            onClose={this.props.actions.hideMultiplePlanScaleEditWizard}
          >
            <MultiplePlanScaleEditWizard />
          </Wizard>
        )}
        {this.props.wizard_multiple_plan_tags_edit && (
          <Wizard
            small
            onClose={this.props.actions.hideMultiplePlanTagsEditWizard}
          >
            <MultiplePlanTagsEditWizard />
          </Wizard>
        )}
        {this.props.wizard_create_meeting && (
          <Wizard small onClose={this.props.actions.hideCreateMeetingWizard}>
            <CreateMeetingWizard />
          </Wizard>
        )}
        {this.props.wizard_add_tasks_to_meeting && (
          <Wizard
            small
            onClose={this.props.actions.hideAddTasksToMeetingWizard}
          >
            <AddTasksToMeetingWizard />
          </Wizard>
        )}
        {this.props.wizard_create_meeting_from_tasks && (
          <Wizard
            small
            onClose={this.props.actions.hideCreateMeetingFromTasksWizard}
          >
            <CreateMeetingFromTasksWizard />
          </Wizard>
        )}
        {this.props.wizard_unregistered_meeting_member && (
          <Wizard
            extraSmall
            onClose={() =>
              setTableParams('all_meetings', {
                wizard_unregistered_meeting_member: undefined,
              })
            }
          >
            <UnregisteredMeetingMemberWizard />
          </Wizard>
        )}
        {this.props.wizard_print_meeting_report && (
          <Wizard
            small
            onClose={() =>
              setTableParams('all_meetings', {
                print_meeting_report_wizard: undefined,
              })
            }
          >
            <PrintMeetingReportWizard />
          </Wizard>
        )}
        {this.props.wizard_send_meeting_report && (
          <Wizard
            small
            onClose={() =>
              setTableParams('all_meetings', {
                send_meeting_report_wizard: undefined,
              })
            }
          >
            <SendMeetingReportWizard />
          </Wizard>
        )}
        {this.props.wizard_create_followup_meeting && (
          <Wizard
            medium
            onClose={() =>
              setTableParams('all_meetings', {
                create_followup_meeting_wizard: undefined,
              })
            }
          >
            <CreateFollowUpMeetingWizard />
          </Wizard>
        )}
        {this.props.wizard_move_meeting_item && (
          <Wizard
            small
            onClose={() =>
              setTableParams('all_meetings', {
                move_meeting_item_wizard: undefined,
              })
            }
          >
            <MoveMeetingItemWizard />
          </Wizard>
        )}
        {this.props.wizard_add_site && (
          <Wizard
            small
            onClose={() =>
              setTableParams('sites', {
                add_site_wizard: undefined,
              })
            }
          >
            <AddSiteWizard />
          </Wizard>
        )}
        {this.props.wizard_add_building && (
          <Wizard
            small
            onClose={() =>
              setTableParams('buildings', {
                add_building_wizard: undefined,
              })
            }
          >
            <AddBuildingWizard />
          </Wizard>
        )}
        {this.props.wizard_add_storey && (
          <Wizard
            small
            onClose={() =>
              setTableParams('storeys', {
                add_storey_wizard: undefined,
              })
            }
          >
            <AddStoreyWizard />
          </Wizard>
        )}
        {this.props.wizard_add_zone && (
          <Wizard
            small
            onClose={() =>
              setTableParams('zones', {
                add_zone_wizard: undefined,
              })
            }
          >
            <AddZoneWizard />
          </Wizard>
        )}
        {this.props.wizard_add_expansion_joint && (
          <Wizard
            small
            onClose={() =>
              setTableParams('expansion_joints', {
                add_expansion_joint_wizard: undefined,
              })
            }
          >
            <AddExpansionJointWizard />
          </Wizard>
        )}
        {this.props.wizard_add_entrance && (
          <Wizard
            small
            onClose={() =>
              setTableParams('entrances', {
                add_entrance_wizard: undefined,
              })
            }
          >
            <AddEntranceWizard />
          </Wizard>
        )}
        {this.props.wizard_add_room && (
          <Wizard
            small
            onClose={() =>
              setTableParams('rooms', {
                add_room_wizard: undefined,
              })
            }
          >
            <AddRoomWizard />
          </Wizard>
        )}
        {this.props.wizard_duplicate_room && (
          <Wizard
            extraSmall
            onClose={() =>
              setTableParams('rooms', {
                duplicate: undefined,
              })
            }
          >
            <DuplicateRoomWizard />
          </Wizard>
        )}
        {this.props.wizard_add_room_group && (
          <Wizard
            small
            onClose={() =>
              setTableParams('room_groups', {
                add_room_group_wizard: undefined,
              })
            }
          >
            <AddRoomGroupWizard />
          </Wizard>
        )}
        {this.props.wizard_add_room_type && (
          <Wizard
            small
            onClose={() =>
              setTableParams('room_types', {
                add_room_type_wizard: undefined,
              })
            }
          >
            <AddRoomTypeWizard />
          </Wizard>
        )}
        {this.props.wizard_add_ocr_template && (
          <Wizard
            small
            onClose={() =>
              setTableParams('ocr_templates', {
                add_ocr_template_wizard: undefined,
              })
            }
          >
            <AddOCRTemplateWizard />
          </Wizard>
        )}
        {this.props.wizard_ocr_plans && (
          <Wizard
            small
            onClose={() =>
              setTableParams('plan_uploads', {
                ocr_plans_wizard: undefined,
              })
            }
          >
            <OCRPlansWizard />
          </Wizard>
        )}
        {this.props.wizard_edit_team && (
          <Wizard
            extraSmall
            onClose={() =>
              setTableParams('teams', {
                edit_team: undefined,
              })
            }
          >
            <EditTeamWizard />
          </Wizard>
        )}
        {this.props.wizard_attach_task && (
          <Wizard
            small
            onClose={() =>
              setTableParams('all_tasks', {
                attach_task_wizard: undefined,
              })
            }
          >
            <AttachTaskToPlanWizard />
          </Wizard>
        )}
        {this.props.wizard_add_product_attribute_group && (
          <Wizard
            small
            onClose={() =>
              setTableParams('products', {
                add_product_attribute_group_wizard: undefined,
              })
            }
          >
            <AddProductAttributeGroupWizard />
          </Wizard>
        )}
        {this.props.wizard_edit_product_attribute_group && (
          <Wizard
            small
            onClose={() =>
              setTableParams('products', {
                edit_product_attribute_group_wizard: undefined,
              })
            }
          >
            <EditProductAttributeGroupWizard />
          </Wizard>
        )}
        {this.props.wizard_add_product_attribute_group_field && (
          <Wizard
            small
            onClose={() =>
              setTableParams('products', {
                add_product_attribute_group_field_wizard: undefined,
              })
            }
          >
            <AddProductAttributeGroupFieldWizard />
          </Wizard>
        )}
        {this.props.wizard_edit_product_attribute_group_field && (
          <Wizard
            small
            onClose={() =>
              setTableParams('products', {
                edit_product_attribute_group_field_wizard: undefined,
                template_field_id: undefined,
              })
            }
          >
            <EditProductAttributeGroupFieldWizard />
          </Wizard>
        )}
        {this.props.wizard_add_specification_product_attribute_group && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_specifications', {
                add_specification_product_attribute_group_wizard: undefined,
              })
            }
          >
            <AddSpecificationProductAttributeGroupWizard />
          </Wizard>
        )}
        {this.props.wizard_edit_specification_product_attribute_group && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_specifications', {
                edit_specification_product_attribute_group_wizard: undefined,
              })
            }
          >
            <EditSpecificationProductAttributeGroupWizard />
          </Wizard>
        )}
        {this.props.wizard_edit_specification_revision_description && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_specifications', {
                edit_specification_revision_description_wizard: undefined,
              })
            }
          >
            <EditSpecificationRevisionDescriptionWizard />
          </Wizard>
        )}
        {this.props.wizard_add_specification_product_attribute_group_field && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_specifications', {
                add_specification_product_attribute_group_field_wizard:
                  undefined,
              })
            }
          >
            <AddSpecificationProductAttributeGroupFieldWizard />
          </Wizard>
        )}
        {this.props.wizard_edit_specification_set_dynamic_columns && (
          <Wizard
            small
            onClose={() =>
              setTableParams('specification_sets', {
                edit_specification_set_dynamic_columns_wizard: undefined,
              })
            }
          >
            <EditSpecificationSetDynamicColumnsWizard />
          </Wizard>
        )}
        {this.props.wizard_create_generic_specification && (
          <Wizard
            small
            onClose={() =>
              setTableParams('specification_sets', {
                create_generic_specification_wizard_active: undefined,
              })
            }
          >
            <CreateGenericSpecificationWizard />
          </Wizard>
        )}
        {this.props.wizard_add_existing_specifications_to_set && (
          <Wizard
            small
            onClose={() =>
              setTableParams('specification_sets', {
                add_existing_specifications_to_set_wizard_active: undefined,
              })
            }
          >
            <AddExistingSpecificationsToSetWizard />
          </Wizard>
        )}
        {this.props.wizard_move_generic_specification && (
          <Wizard
            small
            onClose={() =>
              setTableParams('specification_sets', {
                move_generic_specification_wizard: undefined,
              })
            }
          >
            <MoveGenericSpecificationWizard />
          </Wizard>
        )}
        {this.props.wizard_edit_specification_product_attribute_group_field && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_specifications', {
                edit_specification_product_attribute_group_field_wizard:
                  undefined,
                template_field_id: undefined,
              })
            }
          >
            <EditSpecificationProductAttributeGroupFieldWizard />
          </Wizard>
        )}
        {this.props.wizard_create_specification_from_product && (
          <Wizard
            small
            onClose={() =>
              setTableParams('products', {
                create_specification_from_product_wizard: undefined,
              })
            }
          >
            <CreateSpecificationFromProductWizard />
          </Wizard>
        )}

        {this.props.wizard_create_specification_delivery && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_specifications', {
                delivery_wizard_active: undefined,
              })
            }
          >
            <CreateSpecificationDeliveryWizard />
          </Wizard>
        )}
        {this.props.wizard_print_current_set_specifications && (
          <Wizard
            medium
            onClose={() =>
              setTableParams('current_set_specifications', {
                print_specifications_wizard: undefined,
              })
            }
          >
            <PrintCurrentSetSpecificationsWizard />
          </Wizard>
        )}
        {this.props.wizard_print_working_set_specifications && (
          <Wizard
            medium
            onClose={() =>
              setTableParams('working_set_specifications', {
                print_specifications_wizard: undefined,
              })
            }
          >
            <PrintWorkingSetSpecificationsWizard />
          </Wizard>
        )}
        {this.props.wizard_move_boq_position && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_specifications', {
                move_boq_position: undefined,
              })
            }
          >
            <MoveBOQPositionWizard />
          </Wizard>
        )}
        {this.props.wizard_merge_boq_item && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_specifications', {
                merge_boq_item: undefined,
              })
            }
          >
            <MergeBOQItemWizard />
          </Wizard>
        )}
        {this.props.wizard_specification_approvals && (
          <Wizard
            small
            onClose={() =>
              setTableParams('specification_approvals', {
                wizard_active: undefined,
                approve: undefined,
              })
            }
          >
            <SpecificationApprovalWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_material && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_materials', {
                add_material_wizard: undefined,
              })
            }
          >
            <AddMaterialWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_raw_material && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_raw_materials', {
                add_raw_material_wizard: undefined,
              })
            }
          >
            <AddRawMaterialWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_language && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_languages', {
                add_language_wizard: undefined,
              })
            }
          >
            <AddLanguageWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_color && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_colors', {
                add_color_wizard: undefined,
              })
            }
          >
            <AddColorWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_country && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_countries', {
                add_country_wizard: undefined,
              })
            }
          >
            <AddCountryWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_unit && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_units', {
                add_unit_wizard: undefined,
              })
            }
          >
            <AddUnitWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_unit_group && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_unit_groups', {
                add_unit_group_wizard: undefined,
              })
            }
          >
            <AddUnitGroupWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_product_category && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_product_categories', {
                add_product_category_wizard: undefined,
              })
            }
          >
            <AddProductCategoryWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_product_group && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_product_groups', {
                add_product_group_wizard: undefined,
              })
            }
          >
            <AddProductGroupWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_product_subgroup && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_product_subgroups', {
                add_product_subgroup_wizard: undefined,
              })
            }
          >
            <AddProductSubgroupWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_specification_group && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_specification_groups', {
                add_specification_group_wizard: undefined,
              })
            }
          >
            <AddSpecificationGroupWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_specification_subgroup && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_specification_subgroups', {
                add_specification_subgroup_wizard: undefined,
              })
            }
          >
            <AddSpecificationSubgroupWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_paper_size && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_paper_sizes', {
                add_paper_size_wizard: undefined,
              })
            }
          >
            <AddPaperSizeWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_product_attribute_template_group && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_product_attribute_template_groups', {
                add_product_attribute_template_group_wizard: undefined,
              })
            }
          >
            <AddProductAttributeTemplateGroupWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_product_attribute_template_field && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_product_attribute_template_fields', {
                add_product_attribute_template_field_wizard: undefined,
              })
            }
          >
            <AddProductAttributeTemplateFieldWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_region && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_regions', {
                add_region_wizard: undefined,
              })
            }
          >
            <AddRegionWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_building_type && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_building_types', {
                add_building_type_wizard: undefined,
              })
            }
          >
            <AddBuildingTypeWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_functional_space && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_functional_spaces', {
                add_functional_space_wizard: undefined,
              })
            }
          >
            <AddFunctionalSpaceWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_discipline && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_disciplines', {
                add_discipline_wizard: undefined,
              })
            }
          >
            <AddDisciplineWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_raw_color && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_raw_colors', {
                add_raw_color_wizard: undefined,
              })
            }
          >
            <AddRawColorWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_boq_template_group && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_boq_template_groups', {
                add_boq_template_group_wizard: undefined,
              })
            }
          >
            <AddBOQTemplateGroupWizard />
          </Wizard>
        )}
        {this.props.wizard_create_tender_boq_from_boq && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_boq', {
                create_tender_boq_from_boq: undefined,
              })
            }
          >
            <CreateTenderBOQFromBOQWizard />
          </Wizard>
        )}
        {this.props.wizard_save_boq_items_as_template && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_boq', {
                save_boq_items_as_template: undefined,
              })
            }
          >
            <AddBOQItemsAsTemplateWizard />
          </Wizard>
        )}
        {this.props.wizard_add_boq_items_from_template && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_boq', {
                add_boq_items_from_template: undefined,
              })
            }
          >
            <AddBOQItemsFromTemplateWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_boq_template_subgroup && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_boq_template_subgroups', {
                add_boq_template_subgroup_wizard: undefined,
              })
            }
          >
            <AddBOQTemplateSubgroupWizard />
          </Wizard>
        )}

        {this.props.wizard_superadmin_add_boq_template_calculation && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_boq_template_calculations', {
                add_boq_template_calculation_wizard: undefined,
              })
            }
          >
            <AddBOQTemplateCalculationSuperadmin />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_boq_supply_types && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_boq_supply_types', {
                add_boq_supply_types_wizard: undefined,
              })
            }
          >
            <AddBOQSupplyTypesWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_boq_supply_type_groups && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_boq_supply_type_groups', {
                add_boq_supply_type_groups_wizard: undefined,
              })
            }
          >
            <AddBOQSupplyTypeGroupsWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_filter && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_filters', {
                add_filter_wizard: undefined,
              })
            }
          >
            <AddFilterWizard />
          </Wizard>
        )}
        {this.props.wizard_add_linked_specification && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_specifications', {
                add_linked_specification_wizard: undefined,
              })
            }
          >
            <AddLinkedSpecificationWizard />
          </Wizard>
        )}
        {this.props.wizard_edit_linked_specification && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_specifications', {
                edit_linked_specification_wizard: undefined,
              })
            }
          >
            <EditLinkedSpecificationWizard />
          </Wizard>
        )}
        {this.props.wizard_edit_specification_file_category && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_specifications', {
                edit_specification_file_category: undefined,
              })
            }
          >
            <EditSpecificationFileCategoryWizard />
          </Wizard>
        )}
        {this.props.wizard_edit_specification_code && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_specifications', {
                edit_specification_code_wizard: undefined,
              })
            }
          >
            <EditSpecificationCodeWizard />
          </Wizard>
        )}
        {this.props.wizard_add_specifications_to_set && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_specifications', {
                add_specifications_to_set_wizard: undefined,
              })
            }
          >
            <AddSpecificationsToSetWizard />
          </Wizard>
        )}
        {this.props.wizard_add_brand && (
          <Wizard
            small
            onClose={() =>
              setTableParams('brands', {
                wizard_active: undefined,
              })
            }
          >
            <AddBrandWizard />
          </Wizard>
        )}
        {this.props.wizard_add_address_book_company && (
          <Wizard
            onClose={() =>
              setTableParams('address_book_companies', {
                wizard_active: undefined,
              })
            }
          >
            <AddAddressBookCompanyWizard />
          </Wizard>
        )}
        {this.props.wizard_add_address_book_contact && (
          <Wizard
            onClose={() =>
              setTableParams('address_book_contacts', {
                wizard_active: undefined,
              })
            }
          >
            <AddAddressBookContactWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_translation && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_translations', {
                add_translation_wizard: undefined,
              })
            }
          >
            <AddTranslationWizard />
          </Wizard>
        )}
        {this.props.wizard_save_product_attribute_group_as_template && (
          <Wizard
            small
            onClose={() =>
              setTableParams('products', {
                save_product_attribute_group_as_template_wizard: undefined,
              })
            }
          >
            <AddProductAttributeGroupAsTemplateWizard />
          </Wizard>
        )}
        {this.props
          .wizard_save_specification_product_attribute_group_as_template && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_specifications', {
                save_specification_product_attribute_group_as_template_wizard:
                  undefined,
              })
            }
          >
            <AddSpecificationProductAttributeGroupAsTemplateWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_note && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_notes', {
                add_note_wizard: undefined,
              })
            }
          >
            <AddNoteWizard />
          </Wizard>
        )}
        {this.props.wizard_superadmin_add_note_category && (
          <Wizard
            small
            onClose={() =>
              setTableParams('superadmin_note_categories', {
                add_note_category_wizard: undefined,
              })
            }
          >
            <AddNoteCategoryWizard />
          </Wizard>
        )}
        {this.props.wizard_add_product_note && (
          <Wizard
            small
            onClose={() =>
              setTableParams('products', {
                add_product_note_wizard: undefined,
              })
            }
          >
            <AddProductNoteWizard />
          </Wizard>
        )}
        {this.props.wizard_add_specification_product_note && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_specifications', {
                add_specification_product_note_wizard: undefined,
              })
            }
          >
            <AddSpecificationProductNoteWizard />
          </Wizard>
        )}
        {this.props.wizard_edit_specification_product_note && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_specifications', {
                edit_specification_product_note_wizard: undefined,
              })
            }
          >
            <EditSpecificationProductNoteWizard />
          </Wizard>
        )}
        {this.props.wizard_edit_product_note && (
          <Wizard
            small
            onClose={() =>
              setTableParams('products', {
                edit_product_note_wizard: undefined,
              })
            }
          >
            <EditProductNoteWizard />
          </Wizard>
        )}
        {this.props.wizard_add_part_to_product && (
          <Wizard
            small
            onClose={() =>
              setTableParams('products', {
                add_part_to_product_wizard: undefined,
              })
            }
          >
            <AddPartToProductWizard />
          </Wizard>
        )}
        {this.props.wizard_add_accessory_to_product && (
          <Wizard
            small
            onClose={() =>
              setTableParams('products', {
                add_accessory_to_product_wizard: undefined,
              })
            }
          >
            <AddAccessoryToProductWizard />
          </Wizard>
        )}
        {this.props.wizard_create_specification_from_ws_boq && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_boq', {
                create_specification_from_ws_boq_wizard: undefined,
              })
            }
          >
            <CreateSpecificationFromWSBOQWizard />
          </Wizard>
        )}
        {this.props.wizard_add_boq_template_calculation && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_boq', {
                add_boq_template_calculation_wizard: undefined,
              })
            }
          >
            <AddBOQCalculationAsTemplateWizard />
          </Wizard>
        )}

        {this.props.wizard_add_boq_calculation_from_template && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_boq', {
                add_boq_calculation_from_template_wizard: undefined,
              })
            }
          >
            <AddBOQCalculationFromTemplateWizard />
          </Wizard>
        )}

        {this.props.wizard_attach_templates_to_boq && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_boq', {
                attach_templates_to_boq_wizard: undefined,
              })
            }
          >
            <AttachTemplatesToBOQItemWizard />
          </Wizard>
        )}
        {this.props.wizard_add_specification_to_calculation && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_boq', {
                add_specification_to_calculation_wizard: undefined,
              })
            }
          >
            <AddSpecificationToCalculationWizard />
          </Wizard>
        )}
        {this.props.wizard_copy_boq_item && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_boq', {
                copy_boq_item_wizard: undefined,
              })
            }
          >
            <CopyBOQItemWizard />
          </Wizard>
        )}
        {this.props.wizard_move_boq_item && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_boq', {
                move_boq_item_wizard: undefined,
              })
            }
          >
            <MoveBOQItemWizard />
          </Wizard>
        )}

        {this.props.wizard_add_related_task && (
          <Wizard
            small
            onClose={() =>
              setTableParams('all_tasks', {
                add_related_task_wizard: undefined,
              })
            }
          >
            <AddRelatedTaskWizard />
          </Wizard>
        )}
        {this.props.wizard_multiple_specification_tags_edit && (
          <Wizard
            small
            onClose={() =>
              setTableParams('working_set_specifications', {
                multiple_specification_tags_edit_wizard: undefined,
              })
            }
          >
            <MultipleSpecificationTagsEditWizard />
          </Wizard>
        )}
        {this.props.wizard_edit_product_attribute_group_field_values && (
          <Wizard
            small
            onClose={() =>
              setTableParams('products', {
                edit_product_attribute_group_field_values_wizard: undefined,
              })
            }
          >
            <EditProductAttributeGroupFieldValuesWizard />
          </Wizard>
        )}
        {this.props.wizard_change_product_variant_attributes && (
          <Wizard
            small
            onClose={() =>
              setTableParams('products', {
                change_product_variant_attributes_wizard: undefined,
              })
            }
          >
            <ChangeProductVariantAttributesWizard />
          </Wizard>
        )}
        {this.props.wizard_edit_product_file_category && (
          <Wizard
            small
            onClose={() =>
              setTableParams('products', {
                edit_product_file_category: undefined,
              })
            }
          >
            <EditProductFileCategoryWizard />
          </Wizard>
        )}
        {this.props.wizard_import_spreadsheet && (
          <Wizard
            small
            onClose={() =>
              setTableParams('import_spreadsheet', {
                wizard_active: undefined,
              })
            }
          >
            <ImportSpreadsheetWizard />
          </Wizard>
        )}
        {this.props.wizard_add_specification_set && (
          <Wizard
            small
            onClose={this.props.actions.hideCreateSpecificationSetWizard}
          >
            <CreateSpecificationSetWizard />
          </Wizard>
        )}
        {this.props.wizard_add_specification_set_template && (
          <Wizard
            small
            onClose={this.props.actions.hideSpecificationSetTemplateWizard}
          >
            <AddSpecificationSetTemplateWizard />
          </Wizard>
        )}
        {this.props.wizard_convert_generic_specification_to_specification && (
          <Wizard
            small
            onClose={
              this.props.actions.hideConvertGenericSpecificationToSpecification
            }
          >
            <ConvertGenericSpecificationToSpecificationWizard />
          </Wizard>
        )}
      </>
    );
  }
}

export default Wizards;
