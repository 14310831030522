import * as React from 'react';
import { Component } from 'react';
import classNames from 'classnames';
import ReactDropzone from 'react-dropzone';
import styles from '../styles/components/Dropzone.scss';
import * as _ from 'lodash';
import UploadIcon from '../assets/images/upload-150x150.svg';
import CloseBigIcon from '../assets/images/close-big-15x15.svg';
import { __ } from '../core/utils';
import autobind from 'autobind-decorator';

class Dropzone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: '',
    };
  }

  @autobind
  _handleDrop(uploadedFiles) {
    this.setState({ drag: false });

    if (!_.isEmpty(uploadedFiles)) {
      const file = uploadedFiles[0];

      this.props.input.onFocus();

      let preview = new FileReader();

      preview.onloadend = () => {
        this.setState({
          preview: preview.result,
        });
      };

      preview.readAsDataURL(file);

      setTimeout(() => {
        this.props.input.onChange(file);
      }, 100);

      setTimeout(() => {
        this.props.input.onBlur();
      }, 200);
    }
  }

  @autobind
  _handleRemove(e) {
    e.stopPropagation();

    this.props.input.onFocus();

    this.setState({ preview: '' });

    setTimeout(() => {
      this.props.input.onChange(null);
      this.props.input.onBlur();
    }, 100);
  }

  render() {
    const { disabled } = this.props;

    return (
      <div
        className={classNames(
          styles.wrapper,
          disabled && styles.disabled,
          _.get(this.props, 'meta.error') &&
            _.get(this.props, 'meta.touched') &&
            styles.error
        )}
      >
        {this.props.input.value && (
          <div className={styles.preview}>
            <img src={this.state.preview || this.props.input.value} />
            {!disabled && (
              <span className={styles.delete} onClick={this._handleRemove}>
                <CloseBigIcon />
              </span>
            )}
            <span>
              {_.get(this.props, 'meta.error') &&
                _.get(this.props, 'meta.touched') &&
                __('dropzone.title.invalid-format')}
            </span>
          </div>
        )}
        {!this.props.input.value && (
          <ReactDropzone
            accept={this.props.accept}
            disabled={disabled}
            className={classNames(
              styles.dropzone,
              _.get(this.state, 'drag') && styles.drag
            )}
            onDrop={this._handleDrop}
            onDragOver={() => this.setState({ drag: true })}
            onDragLeave={() => this.setState({ drag: false })}
          >
            <UploadIcon />
            <div className={styles.hint}>
              <span>
                {_.get(this.props, 'meta.error') &&
                _.get(this.props, 'meta.touched')
                  ? (this.props.item || __('dropzone.title.file')) +
                    ' ' +
                    __('dropzone.title.required')
                  : __('dropzone.title.drop-your') +
                    ' ' +
                    (this.props.item || _('dropzone.title.file')) +
                    ' ' +
                    __('dropzone.title.here')}
              </span>
              <span>{__('dropzone.title.or-select')}</span>
            </div>
          </ReactDropzone>
        )}
      </div>
    );
  }
}

export default Dropzone;
