import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/AddExistingSpecificationsToSetWizard.scss';
import autobind from 'autobind-decorator';
import {
  __,
  getActiveProject,
  getActiveStage,
  mapStateToProps,
  validateForm,
} from '../core/utils';
import MoveIcon from '../assets/images/move-64x64.svg';
import { withRouter } from 'react-router';
import { subscribe } from 'react-contextual';
import Localization from '../helpers/Localization';
import _ from 'lodash';
import { readProjectGeneral } from '../redux/actions/projects';
import AddExistingSpecificationsToSetForm from '../forms/add_existing_specifications_to_set_wizard/AddExistingSpecificationsToSetForm';
import { listPossibleSpecificationCodes } from '../redux/actions/table/working_set_boq';
import {
  addSpecificationsToExistingSet,
  listPossibleSpecificationSubgroups,
  readSpecificationSetItems,
} from '../redux/actions/table/specification_sets';
import { setNotification } from '../redux/actions/general/notification';

@mapStateToProps((state) => ({
  store: state.table['specification_sets'],
}))
@subscribe(Localization, 'localization')
@withRouter
class AddExistingSpecificationsToSetWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      possible_specification_codes: [],
      project_language: null,
      options: [],
    };
  }

  componentDidMount() {
    listPossibleSpecificationCodes(getActiveStage())
      .then(({ response }) => {
        this.setState({ possible_specification_codes: response.data });
      })
      .then(() => {
        listPossibleSpecificationSubgroups(
          this.props.match.params.specification_set
        )
          .then(({ response }) => {
            this.setState({ options: response.data });
          })
          .then(() => {
            readProjectGeneral(getActiveProject()).then(({ response }) => {
              this.setState({ project_language: response.data.main_language });
            });
          });
      });
  }

  @autobind
  _handleSubmit(formData) {
    const specification_set = this.props.match.params.specification_set;

    return validateForm(
      addSpecificationsToExistingSet(specification_set, {
        specification_ids: formData.specification_ids,
      }).then(() => {
        setNotification(
          __(
            'specification-sets.notification.selected-specifications-have-been-added-to-the-set'
          )
        );

        readSpecificationSetItems(specification_set);

        this.props.onClose();
      })
    );
  }

  render() {
    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>
              {__(
                'specification-sets.add-existing-specifications-to-set.title'
              )}
            </span>
            <span>
              {__(
                'specification-sets.add-existing-specifications-to-set.description'
              )}
            </span>
          </div>
          <div className={styles.right}>
            <MoveIcon />
          </div>
        </div>
        <div className={styles.body}>
          {this.state.project_language && (
            <AddExistingSpecificationsToSetForm
              onSubmit={this._handleSubmit}
              project_language={this.state.project_language}
              options={this.state.options}
              possible_specification_codes={
                this.state.possible_specification_codes
              }
            />
          )}
        </div>
      </>
    );
  }
}

export default AddExistingSpecificationsToSetWizard;
