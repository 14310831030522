import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import { enableAutosave } from '../../redux/actions/general/autosave';
import styles from '../../styles/forms/specification_sets/EditSpecificationSetForm.scss';
import { withRouter } from 'react-router';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import { __, mapStateToProps, setTableParams } from '../../core/utils';
import * as _ from 'lodash';
import classNames from 'classnames';
import Input from '../../components/Input';
import DateTimePicker from '../../components/DateTimePicker';
import Textarea from '../../components/Textarea';
import PeopleChooser from '../../components/PeopleChooser';
import MultiSelect from '../../components/MultiSelect';
import {
  listPossibleSpecificationSetAdministrators,
  listPossibleSpecificationSetFollowers,
} from '../../redux/actions/table/specification_sets';

@reduxForm({
  form: 'specification_sets.edit_specification_set',
  enableReinitialize: true,
})
@withRouter
@mapStateToProps((state) => ({
  table: state.table['specification_sets'],
  user_id: state.auth.id,
}))
@subscribe(Localization, 'localization')
class EditSpecificationSetForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      possible_specification_set_administrators: [],
      possible_specification_set_editors: [],
    };
  }

  UNSAFE_componentWillReceiveProps() {
    enableAutosave(this.props.form);
  }

  componentDidMount() {
    listPossibleSpecificationSetAdministrators(
      this.props.match.params.stage || this.props.table.stage_id
    ).then(({ response }) => {
      this.setState({
        possible_specification_set_administrators: response.data,
      });
    });

    listPossibleSpecificationSetFollowers(
      this.props.match.params.stage || this.props.table.stage_id
    ).then(({ response }) => {
      this.setState({ possible_specification_set_editors: response.data });
    });
  }

  render() {
    const { data, user_id } = this.props;
    const { project_languages, status } = data;
    const is_administrator = _.isObject(
      _.find(data.administrators, ['id', user_id])
    );

    const disabled =
      !is_administrator || _.includes(['closed', 'canceled'], status);

    const languages = _.map(project_languages, (primary, language_id) => ({
      label: _.get(this.props.localization.languages, [language_id, 'name']),
      value: language_id,
    }));

    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div className={styles.form}>
          <div className={styles.flex}>
            <div className={styles.f2}>
              <Field
                disabled={disabled}
                name='title'
                component={Input}
                localized={project_languages}
                label={__('table.columns.specification_set.title')}
                maxLength={50}
              />
            </div>
            <div className={styles.f1}>
              <Field
                disabled={disabled}
                name='languages'
                component={MultiSelect}
                searchable
                label={__('table.columns.specification_set.languages')}
                noSort
                options={languages}
              />
            </div>
          </div>
          <Field
            disabled={disabled}
            name='description'
            component={Textarea}
            localized={project_languages}
            label={__('table.columns.specification_set.description')}
            maxLength={1000}
            rows={1}
            maxRows={10}
          />

          <div className={styles.flex}>
            <div className={styles.f1}>
              <Field
                disabled={disabled}
                name='administrators'
                component={PeopleChooser}
                label={__('table.columns.specification_set.administrators')}
                searchable
                options={this.state.possible_specification_set_administrators}
              />
            </div>

            <div className={styles.f1}>
              <Field
                disabled={disabled}
                name='editors'
                component={PeopleChooser}
                label={__('table.columns.specification_set.editors')}
                searchable
                options={this.state.possible_specification_set_editors}
              />
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

export default EditSpecificationSetForm;
