import React, { Component } from 'react';
import styles from '../styles/components/ColorPicker.scss';
import classNames from 'classnames';
import SearchIcon from '../assets/images/search-15x15.svg';
import * as _ from 'lodash';
import autobind from 'autobind-decorator';
import { __, getLocalized } from '../core/utils';
import {
  createCompanyColor,
  listMyCompanyColors,
} from '../redux/actions/companies';
import CloseSmallIcon from '../assets/images/close-small-15x15.svg';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../helpers/Localization';
import ColorWrap from '../helpers/ColorWrap';
import MultiSelect from './MultiSelect';
import convert from 'color-convert';
import idToKey from 'id-to-key';
import ArrowDownMiddleIcon from '../assets/images/arrow-down-middle-15x15.svg';
import * as hue from 'react-color/lib/helpers/hue';
import * as saturation from 'react-color/lib/helpers/saturation';
import FieldComponent from './FieldComponent';
import OutsideClickWrapper from './OutsideClickWrapper';
import ButtonGroup from './ButtonGroup';
import Button from './Button';
import Input from './Input';
import { isMobileOnly } from 'react-device-detect';
import Tooltip from './Tooltip';
import colorMatcher from 'hex-color-regex';
import UncheckedIcon from '../assets/images/unchecked-15x15.svg';
import CheckedIcon from '../assets/images/checked-15x15.svg';

@FieldComponent
@ColorWrap
@subscribe(Localization, 'localization')
class ColorPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active_tab: 'system',
      query: '',
      raw_color_dropdown_active: false,
      filter_colors: [],
      color_name: {},
      my_company_save: false,

      r: 38,
      g: 145,
      b: 217,
      lab_l: 0,
      lab_a: 0,
      lab_b: 0,
      hex: '#3498db',
      lrv: '',

      r_active: false,
      g_active: false,
      b_active: false,
      lab_l_active: false,
      lab_a_active: false,
      lab_b_active: false,
      hex_active: false,
      lrv_active: false,
      code_active: false,
      name_active: false,

      r_error: false,
      g_error: false,
      b_error: false,
      lab_l_error: false,
      lab_a_error: false,
      lab_b_error: false,
      hex_error: false,
      lrv_error: false,
      color_name_error: false,
    };

    this.saturationInterval = _.throttle((fn, data, e) => {
      fn(data, e);
    }, 50);
  }

  componentWillUnmount() {
    this.unbindHueEventListeners();
    this.unbindSaturationEventListeners();
    this.saturationInterval.cancel();
  }

  componentDidMount() {
    listMyCompanyColors(this.props.myCompanyColors ? 0 : 1).then(
      ({ response }) => {
        this.setState({ my_company_colors: response.data });
      }
    );
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (this.props.rgb && this.props.rgb != nextProps.rgb) {
      this.setState({
        r: nextProps.rgb.r,
        g: nextProps.rgb.g,
        b: nextProps.rgb.b,
        hex: _.toUpper(nextProps.hex),
      });

      if (
        this.state.lab_l == nextState.lab_l &&
        this.state.lab_a == nextState.lab_a &&
        this.state.lab_b == nextState.lab_b
      ) {
        const [lab_l, lab_a, lab_b] = convert.rgb.lab(
          nextProps.rgb.r,
          nextProps.rgb.g,
          nextProps.rgb.b
        );

        this.setState({
          lab_l,
          lab_a,
          lab_b,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState != this.state || prevProps.meta != this.props.meta) {
      this.props.recalculateOffset();
    }
  }

  @autobind
  _handleClick() {
    this._handleOpen();
  }

  @autobind
  _handleOpen() {
    if (!this.props.disabled) {
      this.setState({
        query: '',
        raw_color_dropdown_active: false,
      });

      listMyCompanyColors(this.props.myCompanyColors ? 0 : 1).then(
        ({ response }) => {
          this.setState({ my_company_colors: response.data });
        }
      );

      this.props.input.onFocus();

      if (this.props.input.value) {
        if (_.isInteger(this.props.input.value)) {
          if (
            _.find(this.props.localization.colors, [
              'id',
              this.props.input.value,
            ])
          ) {
            this._changeTab('system');
          } else {
            this._changeTab('my_company');
          }
        }

        if (_.isObject(this.props.input.value)) {
          this._changeTab('new');
        }
      }
    }
  }

  @autobind
  _handleClose() {
    this.props.input.onBlur();
  }

  @autobind
  _chooseColor(color_id) {
    this.props.input.onChange(color_id);

    this._handleClose();
  }

  @autobind
  _handleClearSearch() {
    this.setState({
      query: '',
    });

    if (this.state.active_tab == 'system' && this.refs.system_search) {
      this.refs.system_search.focus();
    }

    if (this.state.active_tab == 'my_company' && this.refs.my_company_search) {
      this.refs.my_company_search.focus();
    }
  }

  @autobind
  _handleSearch(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  _changeTab(tab) {
    this.setState(
      {
        active_tab: tab,
      },
      () => {
        this._handleClearSearch();

        if (tab == 'new') {
          setTimeout(() => {
            //TODO - fix this (react-color is resetting to default blue color every time the picker is loaded)
            this.props.onChange({
              hex: _.isObject(this.props.input.value)
                ? _.get(this.props.input.value, 'hex')
                : '#3498db',
            });
          }, 100);
        }
      }
    );
  }

  @autobind
  _createColor() {
    if (_.isEmpty(_.filter(this.state.color_name, _.identity))) {
      this.setState({ color_name_error: true });
    } else {
      this.setState({ color_name_error: false });
    }

    if (_.isEmpty(this.state.filter_colors)) {
      this.setState({ raw_color_error: true });
    } else {
      this.setState({ raw_color_error: false });
    }

    if (
      !_.isEmpty(_.filter(this.state.color_name, _.identity)) &&
      !_.isEmpty(this.state.filter_colors) &&
      !this.state.lrv_error
    ) {
      createCompanyColor({
        my_company_save: _.toInteger(this.state.my_company_save),
        lrv: this.state.lrv,
        hex: _.replace(this.props.hex, '#', ''),
        raw_colors: this.state.filter_colors,
        name: this.state.color_name,
        code: this.state.color_code,
      }).then(({ response }) => {
        listMyCompanyColors(this.props.myCompanyColors ? 0 : 1).then(
          ({ response }) => {
            this.setState({ my_company_colors: response.data });
          }
        );

        this.props.input.onChange(response.data.id);
        this.props.input.onBlur();
      });

      this._handleClose();
    }
  }

  // @autobind
  // _invertTextColor(bg_hex) {
  //     const rgb = convert.hex.rgb(bg_hex);
  //
  //     return Math.round(((parseInt(rgb[0]) * 299) + (parseInt(rgb[1]) * 587) + (parseInt(rgb[2]) * 114)) / 1000) > 125;
  // }

  @autobind
  _handleHueChange(e) {
    const change = hue.calculateChange(
      e,
      null,
      {
        hsl: {
          ...this.props.hsl,
        },
        direction: 'vertical',
      },
      this.hue
    );

    change &&
      typeof this.props.onChange === 'function' &&
      this.props.onChange(change, e);
  }

  @autobind
  _handleHueMouseDown(e) {
    this._handleHueChange(e);

    window.addEventListener('mousemove', this._handleHueChange);
    window.addEventListener('mouseup', this._handleHueMouseUp);
  }

  @autobind
  _handleHueMouseUp() {
    this.unbindHueEventListeners();
  }

  @autobind
  unbindHueEventListeners() {
    window.removeEventListener('mousemove', this._handleHueChange);
    window.removeEventListener('mouseup', this._handleHueMouseUp);
  }

  @autobind
  _handleSaturationChange(e) {
    typeof this.props.onChange === 'function' &&
      this.saturationInterval(
        this.props.onChange,
        saturation.calculateChange(
          e,
          null,
          {
            hsl: {
              ...this.props.hsl,
            },
          },
          this.saturation
        ),
        e
      );
  }

  @autobind
  _handleSaturationMouseDown(e) {
    this._handleSaturationChange(e);

    window.addEventListener('mousemove', this._handleSaturationChange);
    window.addEventListener('mouseup', this._handleSaturationMouseUp);
  }

  @autobind
  _handleSaturationMouseUp() {
    this.unbindSaturationEventListeners();
  }

  @autobind
  unbindSaturationEventListeners() {
    window.removeEventListener('mousemove', this._handleSaturationChange);
    window.removeEventListener('mouseup', this._handleSaturationMouseUp);
  }

  @autobind
  _handleChangeRGBColor(new_value, name) {
    const value = new_value == '' ? 0 : _.parseInt(new_value);

    const rgb = {
      r: this.props.rgb.r,
      g: this.props.rgb.g,
      b: this.props.rgb.b,
    };

    rgb[name] = value;

    if (new_value < 0 || new_value > 255) {
      this.setState({
        [`${name}_error`]: true,
        ...rgb,
      });
    } else {
      this.setState({ [`${name}_error`]: false });

      this.props.onChange(rgb);
    }
  }

  @autobind
  _handleChangeHex(value) {
    if (_.size(value) == 7 && colorMatcher({ strict: true }).test(value)) {
      this.setState({
        hex: value,
        hex_error: false,
      });

      this.props.onChange({
        hex: value,
      });
    } else {
      this.setState({
        hex: value,
        hex_error: true,
      });
    }
  }

  @autobind
  _handleChangeLABColor(new_value, name) {
    const value = _.parseInt(new_value);

    const lab = {
      lab_l: this.state.lab_l,
      lab_a: this.state.lab_a,
      lab_b: this.state.lab_b,
    };

    lab[name] = new_value;

    this.setState(lab);

    if (name === 'lab_l') {
      if (value < 0 || value > 100) {
        this.setState({ lab_l_error: true });

        return;
      } else {
        this.setState({ lab_l_error: false });
      }
    }

    if (name === 'lab_a' || name === 'lab_b') {
      if (value < -110 || value > 110) {
        this.setState({ [`${name}_error`]: true });

        return;
      } else {
        this.setState({ [`${name}_error`]: false });
      }
    }

    const lab_l = _.parseInt(lab['lab_l']);
    const lab_a = _.parseInt(lab['lab_a']);
    const lab_b = _.parseInt(lab['lab_b']);

    if (
      lab_l >= 0 &&
      lab_l <= 100 &&
      lab_a >= -110 &&
      lab_a <= 110 &&
      lab_b >= -110 &&
      lab_b <= 110
    ) {
      const [r, g, b] = convert.xyz.rgb(convert.lab.xyz(lab_l, lab_a, lab_b));

      this.props.onChange({
        r,
        g,
        b,
      });
    }
  }

  @autobind
  _handleChangeLRV(value) {
    this.setState({
      lrv: value,
      lrv_error: (value < 0 || value > 100) && value != '',
    });
  }

  render() {
    const { lab_l, lab_a, lab_b, r, g, b, hex, lrv, my_company_save } =
      this.state;
    const {
      lab_l_active,
      lab_a_active,
      lab_b_active,
      r_active,
      g_active,
      b_active,
      hex_active,
      lrv_active,
      code_active,
      name_active,
    } = this.state;
    const {
      lab_l_error,
      lab_a_error,
      lab_b_error,
      hex_error,
      r_error,
      g_error,
      b_error,
      lrv_error,
    } = this.state;
    const {
      hideBarAndAssist,
      leftPosition,
      topPosition,
      elementRef,
      dropdownRef,
      language,
    } = this.props;
    const {
      active_tab,
      query,
      my_company_colors,
      raw_color_dropdown_active,
      filter_colors,
      color_name_error,
      raw_color_error,
    } = this.state;
    const { active } = this.props.meta;
    const system_colors = this.props.localization.colors;
    const { value } = this.props.input;

    const filtered_system_colors = _.filter(
      system_colors,
      (color) =>
        _.isEmpty(query) ||
        _.includes(
          getLocalized(color.name, language).toLowerCase(),
          query.toLowerCase()
        )
    );
    const filtered_my_company_colors = _.filter(my_company_colors, (color) => {
      return (
        (_.isEmpty(query) ||
          _.includes(
            getLocalized(color.name, language).toLowerCase(),
            query.toLowerCase()
          )) &&
        (!color.hidden || color.id == _.toInteger(this.props.input.value))
      );
    });
    const localized_raw_colors = _.sortBy(
      _.map(this.props.localization.raw_colors, (item) => ({
        value: item.id,
        priority: item.priority,
        label: getLocalized(item.name, this.props.language),
      })),
      'priority'
    );

    const active_color = _.isObject(value)
      ? {
          hex: _.get(value, 'hex'),
          name: getLocalized(_.get(value, 'name'), language),
        }
      : {
          hex:
            '#' +
            _.get(
              _.find(
                {
                  ...system_colors,
                  ...idToKey(my_company_colors),
                },
                ['id', _.toInteger(value)]
              ),
              'hex'
            ),
          name: getLocalized(
            _.get(
              _.find(
                {
                  ...system_colors,
                  ...idToKey(my_company_colors),
                },
                ['id', _.toInteger(value)]
              ),
              'name'
            ),
            language
          ),
        };

    const application_languages = _.transform(
      _.filter(
        this.props.localization.languages,
        (language) => language.application_language == true
      ),
      (languages, language) => {
        languages[language.id] = language.id == 1;

        return languages;
      },
      {}
    );

    return (
      <div
        className={classNames(
          styles.wrapper,
          active && styles.focus,
          this.props.disabled && styles.disabled,
          _.get(this.props, 'meta.error') &&
            _.get(this.props, 'meta.touched') &&
            styles.error
        )}
      >
        {this.props.label && (
          <div className={styles.label}>
            <label onClick={this._handleClick}>
              {this.props.labelTooltip ? (
                <Tooltip text={this.props.labelTooltip}>
                  {this.props.label}
                </Tooltip>
              ) : (
                this.props.label
              )}
            </label>
          </div>
        )}
        <div
          className={styles.inputGroup}
          ref={elementRef}
          onClick={this._handleClick}
        >
          <div className={styles.input}>
            {value ? (
              <Tooltip
                placement='left'
                text={
                  <span
                    className={styles.thumbPreview}
                    style={{
                      background: active_color.hex,
                      width: '250px',
                      height: '250px',
                      borderRadius: '3px',
                      display: 'flex',
                    }}
                  />
                }
                html={true}
                dark={true}
              >
                <span
                  className={styles.colorThumb}
                  style={{
                    background: active_color.hex,
                  }}
                />
              </Tooltip>
            ) : (
              ''
            )}
            {value
              ? active_color.name
              : __('specifications.working-set.flyout.attributes.select-color')}
            <ArrowDownMiddleIcon />
          </div>
        </div>

        {active && (
          <OutsideClickWrapper
            //closable={active_tab != 'new'}
            onClickOutside={(e) => {
              e.stopPropagation();

              active_tab != 'new' && this._handleClose();
            }}
          >
            <div
              className={styles.dropdown}
              ref={dropdownRef}
              style={{
                top: topPosition + 'px',
                left: leftPosition + 'px',
                marginTop: (this.props.top || 27) + 'px',
                marginLeft: (this.props.left || 0) + 'px',
              }}
            >
              {isMobileOnly && (
                <div className={styles.mobileHeader}>{this.props.label}</div>
              )}
              <div className={styles.tabs}>
                <span
                  className={classNames(
                    active_tab == 'system' && styles.active
                  )}
                  onClick={() => this._changeTab('system')}
                >
                  {__(
                    'specifications.working-set.flyout.attributes.select-color.system-colors'
                  )}
                </span>
                <span
                  className={classNames(
                    active_tab == 'my_company' && styles.active
                  )}
                  onClick={() => this._changeTab('my_company')}
                >
                  {__(
                    'specifications.working-set.flyout.attributes.select-color.my-company-colors'
                  )}
                </span>
                <span
                  className={classNames(active_tab == 'new' && styles.active)}
                  onClick={() => this._changeTab('new')}
                >
                  {__(
                    'specifications.working-set.flyout.attributes.select-color.add-color'
                  )}
                </span>
              </div>
              <div className={styles.content}>
                {active_tab == 'system' && (
                  <>
                    <div className={styles.title}>
                      {__(
                        'specifications.working-set.flyout.attributes.select-color.available-colors'
                      )}
                    </div>
                    <div className={styles.search}>
                      <SearchIcon className={styles.searchIcon} />
                      <input
                        type='text'
                        value={query}
                        autoFocus={true}
                        ref='system_search'
                        placeholder={__(
                          'specifications.working-set.flyout.attributes.select-color.search'
                        )}
                        onChange={this._handleSearch}
                      />
                      {!_.isEmpty(query) && (
                        <CloseSmallIcon
                          className={styles.close}
                          onClick={this._handleClearSearch}
                        />
                      )}
                    </div>
                    <div className={styles.list}>
                      {_.map(filtered_system_colors, (color, i) => (
                        <div
                          className={classNames(
                            styles.color,
                            value == color.id && styles.active
                          )}
                          key={i}
                          onClick={() => this._chooseColor(color.id)}
                        >
                          <span
                            className={styles.thumb}
                            style={{ backgroundColor: '#' + color.hex }}
                          />
                          <span className={styles.name}>
                            {getLocalized(color.name, language)}
                          </span>
                          <span className={styles.dot} />
                        </div>
                      ))}
                      {_.isEmpty(filtered_system_colors) && (
                        <div className={styles.noResults}>
                          {__('select.info.no-results')}
                        </div>
                      )}
                    </div>
                  </>
                )}
                {active_tab == 'my_company' && (
                  <>
                    <div className={styles.title}>
                      {__(
                        'specifications.working-set.flyout.attributes.select-color.available-colors'
                      )}
                    </div>
                    <div className={styles.search}>
                      <SearchIcon className={styles.searchIcon} />
                      <input
                        type='text'
                        value={query}
                        autoFocus={true}
                        ref='my_company_search'
                        placeholder={__(
                          'specifications.working-set.flyout.attributes.select-color.search'
                        )}
                        onChange={this._handleSearch}
                      />
                      {!_.isEmpty(query) && (
                        <CloseSmallIcon
                          className={styles.close}
                          onClick={this._handleClearSearch}
                        />
                      )}
                    </div>
                    <div className={styles.list}>
                      {_.map(filtered_my_company_colors, (color, i) => (
                        <div
                          className={classNames(
                            styles.color,
                            value == color.id && styles.active
                          )}
                          key={i}
                          onClick={() => this._chooseColor(color.id)}
                        >
                          <span
                            className={styles.thumb}
                            style={{ backgroundColor: '#' + color.hex }}
                          />
                          <span className={styles.name}>
                            {getLocalized(color.name, language)}
                            {color.code ? ` · ${color.code}` : ''}
                          </span>
                          <span className={styles.dot} />
                        </div>
                      ))}
                      {_.isEmpty(filtered_my_company_colors) && (
                        <div className={styles.noResults}>
                          {__('select.info.no-results')}
                        </div>
                      )}
                    </div>
                  </>
                )}
                {active_tab == 'new' && (
                  <>
                    <div className={styles.colorPicker}>
                      <div
                        ref={(saturation) => (this.saturation = saturation)}
                        className={styles.saturation}
                        onMouseDown={this._handleSaturationMouseDown}
                        onTouchMove={this._handleSaturationChange}
                        onTouchStart={this._handleSaturationChange}
                        style={{
                          backgroundColor:
                            'hsl(' + this.props.hsl.h + ', 100%, 50%)',
                        }}
                      >
                        <div className={styles.overlayWhite}>
                          <div className={styles.overlayBlack}>
                            <span
                              style={{
                                top: -(this.props.hsv.v * 100) + 100 + '%',
                                left: this.props.hsv.s * 100 + '%',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        ref={(hue) => (this.hue = hue)}
                        className={styles.hue}
                        onMouseDown={this._handleHueMouseDown}
                        onTouchMove={this._handleHueChange}
                        onTouchStart={this._handleHueChange}
                      >
                        <span
                          style={{
                            top: -((this.props.hsl.h * 100) / 360) + 100 + '%',
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.flex}>
                      <div className={styles.f3}>
                        <Input
                          name='r'
                          label={__(
                            'specifications.working-set.flyout.attributes.select-color.red'
                          )}
                          meta={{
                            active: r_active,
                            error: r_error ? ['Invalid value'] : false,
                            touched: r_error,
                          }}
                          input={{
                            value: r,
                            onBlur: () => this.setState({ r_active: false }),
                            onFocus: () => this.setState({ r_active: true }),
                            onChange: (value) =>
                              this._handleChangeRGBColor(value, 'r'),
                          }}
                          numeric
                        />
                      </div>
                      <div className={styles.f3}>
                        <Input
                          name='g'
                          label={__(
                            'specifications.working-set.flyout.attributes.select-color.green'
                          )}
                          meta={{
                            active: g_active,
                            error: g_error ? ['Invalid value'] : false,
                            touched: g_error,
                          }}
                          input={{
                            value: g,
                            onBlur: () => this.setState({ g_active: false }),
                            onFocus: () => this.setState({ g_active: true }),
                            onChange: (value) =>
                              this._handleChangeRGBColor(value, 'g'),
                          }}
                          numeric
                        />
                      </div>
                      <div className={styles.f3}>
                        <Input
                          name='b'
                          label={__(
                            'specifications.working-set.flyout.attributes.select-color.blue'
                          )}
                          meta={{
                            active: b_active,
                            error: b_error ? ['Invalid value'] : false,
                            touched: b_error,
                          }}
                          input={{
                            value: b,
                            onBlur: () => this.setState({ b_active: false }),
                            onFocus: () => this.setState({ b_active: true }),
                            onChange: (value) =>
                              this._handleChangeRGBColor(value, 'b'),
                          }}
                          numeric
                        />
                      </div>
                      <div className={styles.f4}>
                        <Input
                          name='hex'
                          label={__(
                            'specifications.working-set.flyout.attributes.select-color.hex'
                          )}
                          meta={{
                            active: hex_active,
                            error: hex_error ? ['Invalid value'] : false,
                            touched: hex_error,
                          }}
                          input={{
                            value: hex,
                            onBlur: () => this.setState({ hex_active: false }),
                            onFocus: () => this.setState({ hex_active: true }),
                            onChange: (value) =>
                              this._handleChangeHex(_.toUpper(value)),
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.flex}>
                      <div className={styles.f3}>
                        <Input
                          name='lab_l'
                          label='L'
                          meta={{
                            active: lab_l_active,
                            error: lab_l_error ? ['Invalid value'] : false,
                            touched: lab_l_error,
                          }}
                          input={{
                            value: lab_l,
                            onBlur: () =>
                              this.setState({ lab_l_active: false }),
                            onFocus: () =>
                              this.setState({ lab_l_active: true }),
                            onChange: (value) =>
                              this._handleChangeLABColor(value, 'lab_l'),
                          }}
                          numeric
                          options={{
                            numeral: true,
                            numeralThousandsGroupStyle: 'none',
                            numeralDecimalScale: 0,
                          }}
                        />
                      </div>
                      <div className={styles.f3}>
                        <Input
                          name='lab_a'
                          label='A'
                          meta={{
                            active: lab_a_active,
                            error: lab_a_error ? ['Invalid value'] : false,
                            touched: lab_a_error,
                          }}
                          input={{
                            value: lab_a,
                            onBlur: () =>
                              this.setState({ lab_a_active: false }),
                            onFocus: () =>
                              this.setState({ lab_a_active: true }),
                            onChange: (value) =>
                              this._handleChangeLABColor(value, 'lab_a'),
                          }}
                          numeric
                          options={{
                            numeral: true,
                            numeralThousandsGroupStyle: 'none',
                            numeralDecimalScale: 0,
                          }}
                        />
                      </div>
                      <div className={styles.f3}>
                        <Input
                          name='lab_b'
                          label='B'
                          meta={{
                            active: lab_b_active,
                            error: lab_b_error ? ['Invalid value'] : false,
                            touched: lab_b_error,
                          }}
                          input={{
                            value: lab_b,
                            onBlur: () =>
                              this.setState({ lab_b_active: false }),
                            onFocus: () =>
                              this.setState({ lab_b_active: true }),
                            onChange: (value) =>
                              this._handleChangeLABColor(value, 'lab_b'),
                          }}
                          numeric
                          options={{
                            numeral: true,
                            numeralThousandsGroupStyle: 'none',
                            numeralDecimalScale: 0,
                          }}
                        />
                      </div>
                      <div className={styles.f4}>
                        <Input
                          name='lrv'
                          label='LRV'
                          numeric
                          decimal
                          meta={{
                            active: lrv_active,
                            error: lrv_error ? ['Invalid value'] : false,
                            touched: lrv_error,
                          }}
                          input={{
                            value: lrv,
                            onBlur: () => this.setState({ lrv_active: false }),
                            onFocus: () => this.setState({ lrv_active: true }),
                            onChange: (lrv) => this._handleChangeLRV(lrv),
                          }}
                        />
                      </div>
                    </div>
                    <Input
                      name='color_code'
                      label={__(
                        'specifications.working-set.flyout.attributes.select-color.color-code'
                      )}
                      meta={{
                        active: code_active,
                      }}
                      input={{
                        value: this.state.color_code,
                        onBlur: () => this.setState({ code_active: false }),
                        onFocus: () => this.setState({ code_active: true }),
                        onChange: (color_code) => this.setState({ color_code }),
                      }}
                    />
                    <Input
                      name='color_name'
                      label={__(
                        'specifications.working-set.flyout.attributes.select-color.color-title'
                      )}
                      localized={application_languages}
                      wrapperClassName={styles.colorTitleInput}
                      meta={{
                        active: name_active,
                        error: color_name_error,
                        touched: color_name_error,
                      }}
                      input={{
                        value: this.state.color_name,
                        onBlur: () => this.setState({ name_active: false }),
                        onFocus: () => this.setState({ name_active: true }),
                        onChange: (color_name) => this.setState({ color_name }),
                      }}
                    />
                    <div className={styles.raw}>
                      <span
                        className={classNames(
                          raw_color_dropdown_active && styles.active
                        )}
                      >
                        {__(
                          'specifications.working-set.flyout.attributes.select-color.filter-color'
                        )}
                      </span>
                      <MultiSelect
                        input={{
                          value: filter_colors,
                          onFocus: () =>
                            this.setState({
                              raw_color_dropdown_active: true,
                              raw_color_error: false,
                            }),
                          onBlur: () =>
                            this.setState({ raw_color_dropdown_active: false }),
                          onChange: (filter_colors) =>
                            this.setState({ filter_colors }),
                        }}
                        meta={{
                          active: raw_color_dropdown_active,
                          error: raw_color_error,
                          touched: raw_color_error,
                        }}
                        noSort // options are already sorted by priority
                        options={localized_raw_colors}
                      />
                    </div>
                    <span
                      className={styles.checkbox}
                      onClick={() =>
                        this.setState({ my_company_save: !my_company_save })
                      }
                    >
                      {!my_company_save && (
                        <UncheckedIcon className={styles.unchecked} />
                      )}
                      {my_company_save && (
                        <CheckedIcon className={styles.checked} />
                      )}
                      Save to my company colors
                    </span>
                  </>
                )}
              </div>
              {(active_tab == 'new' || isMobileOnly) && (
                <div className={styles.footer}>
                  <ButtonGroup right>
                    <Button
                      lightGray
                      medium
                      middleText={__('button.cancel')}
                      onClick={this._handleClose}
                    />
                    {active_tab == 'new' && (
                      <Button
                        lightBlue
                        medium
                        middleText={__('button.add-and-select')}
                        onClick={this._createColor}
                      />
                    )}
                  </ButtonGroup>
                </div>
              )}
            </div>
          </OutsideClickWrapper>
        )}

        {!hideBarAndAssist && (
          <>
            <div className={styles.bar} />
            <div
              className={classNames(
                styles.assist,
                ((_.get(this.props, 'meta.error') &&
                  _.get(this.props, 'meta.touched')) ||
                  this.props.hint) &&
                  styles.hint
              )}
            >
              {((_.get(this.props, 'meta.error') &&
                _.get(this.props, 'meta.touched')) ||
                this.props.hint) && (
                <span>
                  {_.get(this.props, 'meta.error') || this.props.hint}
                </span>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ColorPicker;
