import React from 'react';
import styles from '../../../styles/components/RoomChooser.scss';

const Label = ({ text, count }) => {
  return (
    <div className={styles.label}>
      {text} {count > 0 && `(${count})`}
    </div>
  );
};

export default Label;
