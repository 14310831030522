import * as React from 'react';
import { Component } from 'react';
import * as _ from 'lodash';
import { createSidebar } from '../../redux/actions/general/sidebar';
import {
  enableProjectsDropdown,
  enableStagesDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import {
  __,
  dateFrom,
  getActiveProject,
  getActiveStage,
  getLocalized,
  mapStateToProps,
  redirect,
} from '../../core/utils';
import Table from '../../components/Table';
import Tooltip from '../../components/Tooltip';
import styles from '../../styles/views/specifications/DeliverySpecifications.scss';
import { setNotification } from '../../redux/actions/general/notification';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import TableNA from '../../components/TableNA';
import Avatar from '../../components/Avatar';
import { readProjectGeneral } from '../../redux/actions/projects';
import {
  deleteDeliverySpecifications,
  listDeliverySpecifications,
} from '../../redux/actions/table/delivery_specifications';
import Image from '../../components/Image';
import SpecificationCustomIcon from '../../assets/images/specification-custom-16x16.svg';
import SpecificationFromProductIcon from '../../assets/images/specification-from-product-16x16.svg';
import SpecificationByArchitectIcon from '../../assets/images/specification-by-architect-16x16.svg';
import OriginProductIcon from '../../assets/images/product-origin-16x16.svg';

@mapStateToProps((state) => ({
  table: state.table['delivery_specifications'],
  my_specification_deliveries: state.table['my_specification_deliveries'],
  auth: state.auth,
}))
class DeliverySpecifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      delivery_title: null,
      project_language: null,
    };
  }

  componentDidMount() {
    // if(this.props.match.params.type == 'my') {
    //     readMyDelivery(this.props.match.params.specification_delivery).then(({response}) => {
    //         this.setState({
    //             delivery_title: response.data.title,
    //             unsent: response.data.delivered_at == null
    //         });
    //     });
    // } else if(this.props.match.params.type == 'public') {
    //     readPublicDelivery(this.props.match.params.specification_delivery).then(({response}) => {
    //         this.setState({delivery_title: response.data.title});
    //     });
    // }

    enableProjectsDropdown(({ stage_id }) =>
      redirect(
        '/stages/' +
          stage_id +
          '/specification_deliveries' +
          (this.props.match.params.type == 'my' ? '/my' : '')
      )
    );
    enableStagesDropdown(({ stage_id }) =>
      redirect(
        '/stages/' +
          stage_id +
          '/specification_deliveries' +
          (this.props.match.params.type == 'my' ? '/my' : '')
      )
    );

    createSidebar({
      title: __('submenu.title.specifications'),
      items: [
        {
          title: __('submenu.title.current-set-specifications'),
          link: () => '/stages/' + getActiveStage() + '/specifications/current',
        },
        {
          title: __('submenu.title.working-set-specifications'),
          link: () => '/stages/' + getActiveStage() + '/specifications/working',
        },
        {
          title: __('submenu.title.specification-sets'),
          link: () => '/stages/' + getActiveStage() + '/specifications/sets',
        },
        {
          title: __('submenu.title.deliveries'),
          link: () =>
            '/stages/' + getActiveStage() + '/specification_deliveries',
          active: true,
          items: [
            {
              title: __('submenu.title.deliveries.public'),
              link: () =>
                '/stages/' + getActiveStage() + '/specification_deliveries',
              active: this.props.match.params.type == 'public',
            },
            {
              title: __('submenu.title.deliveries.my-team'),
              link: () =>
                '/stages/' + getActiveStage() + '/specification_deliveries/my',
              active: this.props.match.params.type == 'my',
            },
          ],
        },
        {
          title: __('submenu.title.approvals'),
          link: () =>
            '/stages/' + getActiveStage() + '/specification_approvals',
        },
      ],
    });
  }

  render() {
    const { specification_groups, specification_subgroups } =
      this.props.localization;
    const { project_language } = this.state;
    const { my_specification_deliveries, auth } = this.props;

    const my_delivery =
      _.find(
        my_specification_deliveries.data,
        (delivery) =>
          delivery.id == this.props.match.params.specification_delivery
      ) || {};
    const is_creator = my_delivery.creator_id == auth.id;
    const is_public = my_delivery.delivered_at;

    const columns = [
      {
        key: 'code',
        name: __('specifications.flyout.delivery-specifications.code'),
        value: (code, row) => {
          const specification_subgroup =
            _.get(specification_subgroups, [row.specification_subgroup_id]) ||
            {};
          const specification_group =
            _.get(
              specification_groups,
              _.get(specification_subgroup, 'specification_group_id')
            ) || {};

          return (
            getLocalized(specification_group.code, project_language) +
            specification_subgroup.code +
            _.padStart(code, 2, '0')
          );
        },
        width: 90,
        sortable: false,
      },
      {
        key: 'custom',
        name: __('specifications.deliveries.table-title.type'),
        value: (custom, row) =>
          row.product ? (
            <Tooltip
              text={__(
                'specifications.flyout.delivery-specifications.specification-created-from-product'
              )}
            >
              <SpecificationFromProductIcon className={styles.type} />
            </Tooltip>
          ) : (
            <Tooltip
              text={__(
                'specifications.flyout.delivery-specifications.specification'
              )}
            >
              <SpecificationByArchitectIcon className={styles.type} />
            </Tooltip>
          ),
        sortable: false,
        width: 50,
      },
      {
        key: 'thumb',
        name: __('specifications.deliveries.table-title.thumbnail'),
        value: (created_by, row) =>
          row.thumbnail_url ? (
            <Tooltip
              placement='right'
              text={
                <Image
                  className={styles.thumbPreview}
                  src={row.thumbnail_url}
                />
              }
              html={true}
              dark={true}
            >
              <Image className={styles.thumb} src={row.thumbnail_url} />
            </Tooltip>
          ) : (
            <TableNA />
          ),
        width: 50,
        sortable: false,
      },
      {
        key: 'description',
        name: __('specifications.deliveries.table-title.description'),
        sortable: false,
        width: 120,
        value: (value, row) => {
          const description = getLocalized(value, project_language);
          return description ? (
            <Tooltip text={description}>{description}</Tooltip>
          ) : (
            <TableNA />
          );
        },
      },
      {
        key: 'model',
        name: __('specifications.delivery-specifications.model'),
        required: true,
        default: true,
      },
      {
        key: 'brand',
        name: __('specifications.delivery-specifications.manufacturer'),
        value: (brand, row) => (row.product ? brand : row.manufacturer_company),
        sortable: false,
      },
      {
        key: 'discipline',
        name: __('specifications.delivery-specifications.discipline'),
        value: (discipline, row) => {
          const prefixed_discipline = [
            row.discipline.code,
            row.discipline.id == null
              ? row.discipline.description + '*'
              : _.get(this.props.localization.disciplines, [
                  row.discipline.id,
                  'name',
                ]),
          ].join(': ');

          return (
            <Tooltip text={prefixed_discipline}>{row.discipline.code}</Tooltip>
          );
        },
        width: 50,
        sortable: false,
      },
      {
        key: 'revision',
        name: __('specifications.delivery-specifications.revision'),
        value: (value) => (!_.isNull(value) ? 'R' + value : <TableNA />),
        width: 60,
        sortable: false,
      },
      {
        key: 'team',
        name: __('specifications.delivery-specifications.team'),
        value: (team) => <Tooltip text={team.company}>{team.company}</Tooltip>,
        sortable: false,
      },
      {
        key: 'product',
        name: __('specifications.deliveries.flyout.product-in-catalog'),
        sortable: false,
        value: (product, row) =>
          product ? (
            <Tooltip
              text={__(
                'specifications.delivery-specifications.view-product-in-catalog'
              )}
            >
              <OriginProductIcon
                className={styles.product}
                onClick={(e) => {
                  e.stopPropagation();

                  redirect('/products?id=' + product.id);
                }}
              />
            </Tooltip>
          ) : (
            <TableNA />
          ),
        width: 50,
      },
      {
        key: 'created_at',
        name: __('specifications.delivery-specifications.added'),
        value: (value) => dateFrom(value),
        width: 80,
        sortable: false,
      },
      {
        key: 'created_by',
        name: __('specifications.delivery-specifications.added-by'),
        value: (created_by) => (
          <Avatar
            avatar_url={created_by.avatar_url}
            name={created_by.fullname}
            active={created_by.active}
          />
        ),
        width: 50,
        sortable: false,
      },
      {
        key: 'status',
        name: __('specifications.delivery-specifications.status'),
        value: (status) => (
          <>
            {status === 0 && (
              <div className={styles.pending}>
                {__('specifications.deliveries.table.pending')}
              </div>
            )}
            {status === 1 && (
              <div className={styles.approved}>
                {__('specifications.deliveries.table.approved')}
              </div>
            )}
            {status === 2 && (
              <div className={styles.rejected}>
                {__('specifications.deliveries.table.rejected')}
              </div>
            )}
            {status == null && <TableNA />}
          </>
        ),
        width: 120,
        sortable: false,
      },
    ];

    const singleActions = (specification_delivery_id) => {
      return [
        is_creator && !is_public && (
          <Tooltip
            text={__(
              'specifications.delivery-specifications.delete-specification-from-delivery'
            )}
            key={_.uniqueId()}
          >
            <DeleteIcon
              onClick={(e) => {
                e.stopPropagation();

                showAlertbox({
                  color: 'red',
                  title: __('general.alert.are-you-sure'),
                  description: __(
                    'specifications.delivery-specifications.delete-selected-specification-from-this-delivery.this-process-cannot-be-undone'
                  ),
                  buttons: [
                    {
                      color: 'lightGray',
                      text: __('general.alert.no-close'),
                      onClick: 'close',
                    },
                    {
                      color: 'gray',
                      text: __('general.alert.yes-delete'),
                      onClick: () => {
                        deleteDeliverySpecifications(
                          this.props.match.params.specification_delivery,
                          [specification_delivery_id]
                        ).then(() => {
                          hideAlertbox();

                          setNotification(
                            __(
                              'specifications.delivery-specifications.selected-specifications-deleted-from-delivery'
                            )
                          );
                          listDeliverySpecifications(
                            this.props.match.params.specification_delivery
                          );

                          this.props.actions.setTableParams({
                            actioned_row: undefined,
                          });
                        });
                      },
                    },
                  ],
                });
              }}
            />
          </Tooltip>
        ),
      ];
    };

    const groupActions = (specification_delivery_ids) => [
      <Tooltip
        text={__(
          'specifications.delivery-specifications.delete-specifications-from-delivery'
        )}
        key={_.uniqueId()}
      >
        <DeleteIcon
          onClick={(e) => {
            e.stopPropagation();

            showAlertbox({
              color: 'red',
              title: __('general.alert.are-you-sure'),
              description: __(
                'specifications.delivery-specifications.want-delete-selected-specifications-from-delivery.this-process-cannot-be-undone'
              ),
              buttons: [
                {
                  color: 'lightGray',
                  text: __('general.alert.no-close'),
                  onClick: 'close',
                },
                {
                  color: 'gray',
                  text: __('general.alert.yes-delete'),
                  onClick: () => {
                    deleteDeliverySpecifications(
                      this.props.match.params.specification_delivery,
                      specification_delivery_ids
                    ).then(() => {
                      hideAlertbox();

                      setNotification(
                        __(
                          'specifications.delivery-specifications.selected-specifications-deleted-from-delivery'
                        )
                      );
                      listDeliverySpecifications(
                        this.props.match.params.specification_delivery
                      );

                      this.props.actions.setTableParams({
                        selected_rows: undefined,
                      });
                    });
                  },
                },
              ],
            });
          }}
        />
      </Tooltip>,
    ];

    const gridOptions = {
      section: '',
      image: (id, row) => row.thumbnail_url,
      title: (row) => {
        const specification_subgroup =
          _.get(specification_subgroups, [row.specification_subgroup_id]) || {};
        const specification_group =
          _.get(
            specification_groups,
            _.get(specification_subgroup, 'specification_group_id')
          ) || {};

        return (
          getLocalized(specification_group.code, project_language) +
          specification_subgroup.code +
          _.padStart(row.code, 2, '0')
        );
      },
      subTitle: (row) => {
        return _.filter(
          [_.get(row.description, project_language), row.model, row.brand],
          _.identity
        ).join(' / ');
      },
      content: (row) => [],
    };

    return (
      <Table
        listAction={() =>
          listDeliverySpecifications(
            this.props.match.params.specification_delivery
          )
        }
        name='delivery_specifications'
        // title={(
        //     <div className={styles.breadcrumbs}>
        //         {this.state.delivery_title && (
        //             <>
        //                 {this.props.match.params.type == 'my' && <span onClick={() => window.previousLocation ? goBack() : redirect("/stages/" + this.props.match.params.stage + "/deliveries/my")}>{__('breadcrumbs.title.my-team-deliveries')}</span>}
        //                 {this.props.match.params.type == 'public' && <span onClick={() => window.previousLocation ? goBack() : redirect("/stages/" + this.props.match.params.stage + "/deliveries/")}>{__('breadcrumbs.title.public-deliveries')}</span>}
        //                 <ArrowRightSmallIcon />
        //                 {this.state.delivery_title}
        //             </>
        //         )}
        //     </div>
        // )}
        gridOptions={gridOptions}
        columns={columns}
        singleActions={singleActions}
        groupActions={groupActions}
        onLoad={() => {
          listActiveModules().then(() => {
            readProjectGeneral(getActiveProject()).then(({ response }) => {
              this.setState({ project_language: response.data.main_language });
            });
          });
        }}
      />
    );
  }
}

export default DeliverySpecifications;
