import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/EditSpecificationSetDynamicColumnsWizard.scss';
import autobind from 'autobind-decorator';
import ProductAttributeAddIcon from '../assets/images/product-attribute-add-60x60.svg';
import {
  __,
  mapStateToProps,
  setTableParams,
  validateForm,
} from '../core/utils';
import EditSpecificationSetDynamicColumnsForm from '../forms/edit_specification_set_dynamic_columns_wizard/EditSpecificationSetDynamicColumnsForm';
import {
  readSpecificationSet,
  readSpecificationSetDynamicColumns,
  readSpecificationSetItems,
  updateSpecificationSetDynamicColumns,
} from '../redux/actions/table/specification_sets';
import { setNotification } from '../redux/actions/general/notification';
import { withRouter } from 'react-router';

@mapStateToProps((state) => ({
  flyout: state.table.specification_sets.flyout,
}))
@withRouter
class EditSpecificationSetDynamicColumnsWizard extends Component {
  @autobind
  _handleSubmit(formData) {
    formData = {
      ...formData,
    };

    return validateForm(
      updateSpecificationSetDynamicColumns(this.props.flyout.id, formData).then(
        () =>
          readSpecificationSet(this.props.flyout.id).then(({ response }) => {
            setNotification(
              __(
                'specification_sets.flyout.edit_dynamic_columns.dynamic_columns-edited'
              )
            );

            setTableParams('specification_sets', {
              flyout: response.data,
            });

            readSpecificationSetItems(
              this.props.match.params.specification_set
            ).then(() => {
              readSpecificationSetDynamicColumns(
                this.props.match.params.specification_set
              );
            });

            this.props.onClose();
          })
      )
    );
  }

  render() {
    const { flyout } = this.props;

    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>
              {__('wizard.specification_set.title.edit-dynamic-columns')}
            </span>
            <span>
              {__('wizard.specification_set.subtitle.edit-dynamic-columns')}
            </span>
          </div>
          <div className={styles.right}>
            <ProductAttributeAddIcon />
          </div>
        </div>
        <div className={styles.body}>
          <EditSpecificationSetDynamicColumnsForm
            onSubmit={this._handleSubmit}
            initialValues={{
              template_field_ids: _.map(
                flyout.dynamic_columns,
                (column) => column.product_attribute_template_field_id
              ),
            }}
          />
        </div>
      </>
    );
  }
}

export default EditSpecificationSetDynamicColumnsWizard;
