import * as React from 'react';
import { Component } from 'react';
import { mapStateToProps, __, setTableParams } from '../../core/utils';
import styles from '../../styles/forms/working_set_specifications/EditSpecificationToolbarForm.scss';
import Select from '../../components/Select';
import Tooltip from '../../components/Tooltip';
import TaskStatusIcon from '../../assets/images/task-status-15x15.svg';
import classNames from 'classnames';
import * as _ from 'lodash';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
import { showAlertbox } from '../../redux/actions/general/alertbox';
import { updateSpecificationReadyForDelivery } from '../../redux/actions/table/working_set_specifications';

@mapStateToProps((state) => ({
  store: state.table.working_set_specifications,
}))
class EditWorkingSetSpecificationToolbarForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status_switcher: false,
    };
  }

  render() {
    const { flyout } = this.props.store;
    const { readOnly } = this.props;
    const status = _.get(flyout, 'status');

    console.log(status, 'STATUS');

    const statuses = [
      {
        value: 'active',
        label: __('working_set_specifications.status.active'),
        color: '#5777FC',
      },
      {
        value: 'active_25',
        label: __('working_set_specifications.status.active_at_25_percent'),
        color: '#F071EC',
        disabled: _.includes(['canceled'], status),
      },
      {
        value: 'active_50',
        label: __('working_set_specifications.status.active_at_50_percent'),
        color: '#75FCFD',
        disabled: _.includes(['canceled'], status),
      },
      {
        value: 'active_75',
        label: __('working_set_specifications.status.active_at_75_percent'),
        color: '#029DFC',
        disabled: _.includes(['canceled'], status),
      },
      {
        value: 'ready_for_delivery',
        label: __('working_set_specifications.status.ready_for_delivery'),
        color: '#ADD42A',
        disabled: _.includes(['canceled'], status),
      },
      {
        value: 'canceled',
        label: __('working_set_specifications.status.canceled'),
        color: '#CCCDD1',
      },
    ];

    return (
      <div className={styles.wrapper}>
        <Select
          disabled={readOnly}
          input={{
            value: flyout.status,

            onChange: (status) => {
              if (flyout.status === status) return;

              if (status === 'canceled')
                return showAlertbox({
                  color: 'red',
                  title: __('general.alert.are-you-sure'),
                  description: `${__(
                    'specifications.working-set.status.alert.first-warning'
                  )}\n\n${__(
                    'specifications.working-set.status.alert.second-warning'
                  )}`,
                  buttons: [
                    {
                      color: 'lightGray',
                      text: __('general.alert.no-close'),
                      onClick: 'close',
                    },
                    {
                      color: 'gray',
                      text: __('general.alert.yes-change-status'),
                      onClick: () => this.props.changeStatus(status),
                    },
                  ],
                });

              if (status === 'ready_for_delivery') {
                updateSpecificationReadyForDelivery(flyout.id, {
                  ready_for_delivery: 1,
                });

                setTableParams('working_set_specifications', {
                  edit_specification_revision_description_wizard: true,
                });
              }

              if (
                status !== 'ready_for_delivery' &&
                flyout.status === 'ready_for_delivery'
              ) {
                updateSpecificationReadyForDelivery(flyout.id, {
                  ready_for_delivery: 0,
                });
              }

              return this.props.changeStatus(status);
            },
            onBlur: () => this.setState({ status_switcher: false }),
            onFocus: () => this.setState({ status_switcher: true }),
          }}
          meta={{
            active: this.state.status_switcher,
          }}
          label={__('Status')}
          options={statuses}
          noSort
          dropdown
          top={34}
          left={-40}
          icon={<TaskStatusIcon className={styles.taskStatus} />}
          wrapper={(handleOpen, activeItem) => (
            <Tooltip
              text={__('working_set_specifications.tooltip.icon.status')}
            >
              <div
                className={classNames(styles.dropdown)}
                style={{ background: activeItem.color }}
                onClick={handleOpen}
              >
                <TaskStatusIcon className={styles.taskStatus} />
                <div className={styles.button}>
                  {activeItem.label}
                  <ArrowDownSmallIcon />
                </div>
              </div>
            </Tooltip>
          )}
        />
      </div>
    );
  }
}

export default EditWorkingSetSpecificationToolbarForm;
