import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/specification_sets/SpecificationSetsFlyout.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import CloseBigIcon from '../../assets/images/close-big-15x15.svg';
import classNames from 'classnames';
import * as _ from 'lodash';
import autobind from 'autobind-decorator';
import { __, setTableParams } from '../../core/utils';
import Properties from './Properties';
import { Element, ScrollLink, scrollSpy } from 'react-scroll';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import EditSpecificationSetToolbarForm from '../../forms/specification_sets/EditSpecificationSetToolbarForm';
import {
  listSpecificationSets,
  updateSpecificationSetToolbar,
} from '../../redux/actions/table/specification_sets';
import DynamicColumns from './DynamicColumns';

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.specification_sets/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@connect(
  (state) => ({
    user_id: state.auth.id,
    store: state.specification_sets,
  }),
  mapDispatchToProps
)
@withRouter
class SpecificationSetsFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed_tabs: [],
      highlighted_tab: null,
    };

    this.highlight_timeout = null;

    this.tabs = ['properties', 'dynamic_columns'];

    this.tab_refs = [];
  }

  componentDidMount() {
    scrollSpy.update();

    if (this.props.tab_opened) {
      setTimeout(() => {
        this.tab_refs[this.props.tab_opened] &&
          this.tab_refs[this.props.tab_opened].click();

        setTableParams('specification_sets', {
          tab: undefined,
        });
      }, 500);
    }
  }

  @autobind
  _toggleCollapseTab(tab) {
    this.setState({
      collapsed_tabs: _.xor(this.state.collapsed_tabs, [tab]),
    });
  }

  @autobind
  _highlightSection(section) {
    this.setState({ highlighted_tab: section }, () => {
      if (this.highlight_timeout) clearTimeout(this.highlight_timeout);

      this.highlight_timeout = setTimeout(() => {
        this.setState({ highlighted_tab: null });
      }, 200);
    });
  }

  @autobind
  _toggleCollapseTabs() {
    if (_.size(this.state.collapsed_tabs) == _.size(this.tabs)) {
      this.setState({
        collapsed_tabs: [],
      });
    } else {
      this.setState({
        collapsed_tabs: this.tabs,
      });
    }

    this.refs.scroll.scrollTop = 0;
  }

  @autobind
  _handleSubmit(formData) {
    updateSpecificationSetToolbar(formData.id, {
      ..._.omit(formData, ['id']),
    }).then(() => {
      this.props.match.params.stage &&
        listSpecificationSets(this.props.match.params.stage);
      this.props.readAction(formData.id);
      setNotification(__('specification_sets.properties.saved'));
    });
  }

  render() {
    const { collapsed_tabs, highlighted_tab } = this.state;

    const { data, readAction, user_id } = this.props;

    const is_administrator = _.isObject(
      _.find(data?.administrators, ['id', user_id])
    );

    const TabLink = ScrollLink((props) => (
      <span
        ref={(ref) => (this.tab_refs[props.target] = ref)}
        onClick={(e) => {
          this.setState({
            collapsed_tabs: _.reject(
              collapsed_tabs,
              (section) => section == props.target
            ),
          });

          this._highlightSection(props.target);

          props.onClick(e);
        }}
        className={props.className}
      >
        {props.children}
      </span>
    ));

    const Tab = (props) => (
      <TabLink
        target={props.target}
        activeClass={styles.active}
        to={props.target}
        spy={true}
        smooth={true}
        duration={500}
        container={this.refs.scroll}
      >
        {props.children}
      </TabLink>
    );

    return (
      <div className={styles.preview}>
        <div className={styles.header}>
          <div className={styles.title}>
            <span>
              <strong>{'IS-' + _.padStart(data?.identifier, 4, '0')}:</strong>
              {_.get(
                data?.title,
                _.findKey(
                  _.get(data, 'project_languages'),
                  (primary) => primary == 1
                )
              )}
            </span>
            <CloseBigIcon
              onClick={() =>
                setTimeout(() => {
                  this.props.actions.setTableParams({
                    clicked_row: undefined,
                  });
                }, 100)
              }
            />
          </div>
          {/* <div className={styles.actions}>
            {is_administrator && (
              <div className={styles.actionWrapper}>
                <Tooltip text={__('specification_sets.tooltip.icon.send-specification_set-report')}>
                  <SendIcon
                    onClick={(e) => {
                      e.stopPropagation();

                      setTableParams('specification_sets', {
                        send_specification_set_report_wizard: true,
                        specification_set_id: data.id,
                        send: true,
                      });
                    }}
                  />
                </Tooltip>
              </div>
            )}
            <div className={styles.actionWrapper}>
              <Tooltip text={__('specification_sets.tooltip.icon.print-specification_set-report')}>
                <PrintIcon
                  onClick={(e) => {
                    e.stopPropagation();

                    setTableParams('specification_sets', {
                      print_specification_set_report_wizard: true,
                      specification_set_id: data.id,
                      specification_set_status: data.status,
                      send: false,
                    });
                  }}
                />
              </Tooltip>
            </div>
          </div> */}
        </div>
        <div className={styles.toolbar}>
          <EditSpecificationSetToolbarForm
            data={data}
            onSubmit={this._handleSubmit}
            initialValues={{
              ..._.pick(data, ['id', 'status']),
              visibility: _.toString(data.visibility),
              date: {
                start: data?.start_date,
                end: data?.end_date,
                allDay: data?.all_day,
              },
            }}
          />
        </div>

        <div className={styles.tabsWrapper}>
          <ArrowDownMiddleIcon
            className={classNames(
              styles.collapseAll,
              _.size(collapsed_tabs) == _.size(this.tabs) && styles.collapsed
            )}
            onClick={this._toggleCollapseTabs}
          />
          <div className={styles.tabs}>
            <Tab target='properties'>
              {__('specification_set.flyout.properties')}
            </Tab>
            <Tab target='dynamic_columns'>
              {__('specification_set.flyout.dynamic_columns')}
              {!_.isEmpty(data.dynamic_columns) &&
                ' (' + _.size(data.dynamic_columns) + ')'}
            </Tab>
          </div>
        </div>

        <div className={styles.content} ref='scroll'>
          <Element
            name='properties'
            className={classNames(
              styles.section,
              highlighted_tab == 'properties' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('properties')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'properties') && styles.collapsed
                )}
              />
              {__('specification_set.flyout.properties')}
            </div>
            <Properties
              data={data}
              readAction={readAction}
              collapsed={_.includes(collapsed_tabs, 'properties')}
            />
          </Element>

          <Element
            name='dynamic_columns'
            className={classNames(
              styles.section,
              highlighted_tab == 'dynamic_columns' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('dynamic_columns')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'dynamic_columns') &&
                    styles.collapsed
                )}
              />
              {__('task.flyout.dynamic_columns')}
              {!_.isEmpty(data.dynamic_columns) &&
                ' (' + _.size(data.dynamic_columns) + ')'}
            </div>
            <DynamicColumns
              collapsed={_.includes(collapsed_tabs, 'dynamic_columns')}
              data={data}
            />
          </Element>
        </div>
      </div>
    );
  }
}

export default SpecificationSetsFlyout;

// Treba postaviti indekse na kreirane tabele tendetes specification_sets members specification_sets files

// Treba uvesti autorizaciju za biddere i followere ko sta moze vidjeti

// treba takodje napraviti strukturu za fajlove za biddere

// statusi

// Slanje notifikacija za followere i biddere, kada se sta Salje

// da li se moze dodati i admin i bidder i follower u jednom momentu, trenutno se to moze uraditi u aplikaciji npr na taskovima, meetingu...
