import React from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import styles from '../../styles/forms/edit_specification_revision_description_wizard/EditSpecificationRevisionDescriptionForm.scss';
import Button from '../../components/Button';
import { connect } from 'react-redux';
import { __ } from '../../core/utils';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import Input from '../../components/Input';

const EditSpecificationRevisionDescriptionForm = ({
  handleSubmit,
  onClose,
  submitting,
}) => {
  return (
    <Form onSubmit={handleSubmit} className={styles.wrapper}>
      <div className={styles.form}>
        <div className={styles.f1}>
          <Field
            name='description'
            component={Input}
            label={__('working_set_specifications.field.description')}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          lightGray
          medium
          left
          middleText={__('button.cancel')}
          onClick={onClose}
        />

        <Button
          lightBlue
          medium
          middleText={__('button.add')}
          spinning={submitting}
          onClick={handleSubmit}
        />
      </div>
    </Form>
  );
};

export default withRouter(
  reduxForm({
    form: 'edit_specification_revision_description_wizard.edit_specification_revision_description',
    enableReinitialize: true,
  })(
    subscribe(
      Localization,
      'localization'
    )(EditSpecificationRevisionDescriptionForm)
  )
);
