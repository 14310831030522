import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/ConvertGenericSpecificationToSpecificationWizard.scss';
import {
  __,
  getActiveProject,
  getActiveStage,
  mapStateToProps,
  setTableParams,
  validateForm,
} from '../core/utils';
import Localization from '../helpers/Localization';
import { subscribe } from 'react-contextual';
import autobind from 'autobind-decorator';
import { readProjectGeneral } from '../redux/actions/projects';

import AddSpecificationIcon from '../assets/images/add-specification-60x60.svg';
import ConvertGenericSpecificationToSpecificationForm from '../forms/convert_generic_specification_to_specification_wizard/ConvertGenericSpecificationToSpecificationForm';
import { createSpecification } from '../redux/actions/table/working_set_specifications';
import {
  convertGenericSpecificationToSpecification,
  readSpecificationSetItems,
} from '../redux/actions/table/specification_sets';
import { withRouter } from 'react-router';
import { setNotification } from '../redux/actions/general/notification';
import _, { template } from 'lodash';

@subscribe(Localization, 'localization')
@mapStateToProps((state) => ({
  store: state.table.specification_sets,
  data: _.get(
    state.table,
    'specification_sets.wizard_convert_generic_specification_to_specification'
  ),
}))
@withRouter
class ConvertGenericSpecificationToSpecificationWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      project_language: null,
    };
  }

  componentDidMount() {
    readProjectGeneral(getActiveProject()).then(({ response }) => {
      this.setState({ project_language: response.data.main_language });
    });
  }

  @autobind
  _handleCreateSpecification(formData) {
    const { onClose, data } = this.props;

    return validateForm(
      createSpecification(getActiveStage(), formData).then(({ response }) => {
        const specification_id = response.data.id;

        convertGenericSpecificationToSpecification(
          _.last(_.split(data.id, '-')),
          specification_id
        ).then(() => {
          readSpecificationSetItems(this.props.match.params.specification_set);

          setNotification(
            'A specification has been successfully added to the selected item.'
          );

          onClose();
        });
      })
    );
  }

  render() {
    const { project_language } = this.state;
    const {
      unit_id,
      description,
      specification_subgroup_id,
      manufacturer_company_id,
    } = this.props.data;

    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>
              {__('specification_sets.wizard.add-new.convert-to-specification')}
            </span>
            <span>
              {__(
                'specification_sets.wizard.add-new.create-new-specification-in-your-workspace'
              )}
            </span>
          </div>
          <div className={styles.right}>
            <AddSpecificationIcon />
          </div>
        </div>
        <div className={styles.body}>
          <ConvertGenericSpecificationToSpecificationForm
            onSubmit={this._handleCreateSpecification}
            projectLanguage={project_language}
            initialValues={{
              custom: '0',
              action: 'option_main',
              description,
              unit_id,
              specification_subgroup_id,
              manufacturer_company_id,
            }}
          />
        </div>
      </>
    );
  }
}

export default ConvertGenericSpecificationToSpecificationWizard;
