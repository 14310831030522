import React, { useEffect, useState } from 'react';
import styles from '../styles/wizards/CreateSpecificationSetWizard.scss';
import { __, getActiveStage, validateForm } from '../core/utils';
import { withRouter } from 'react-router';
import { setNotification } from '../redux/actions/general/notification';
import { connect } from 'react-redux';
import Localization from '../helpers/Localization';
import { subscribe } from 'react-contextual';
import AddSpecificationsToSetForm from '../forms/add_specifications_to_set_wizard/AddSpecificationsToSetForm';
import { getStagesLanguages } from '../redux/actions/table/stages';
import {
  createSpecificationSet,
  listSpecificationSets,
  createSpecificationSetFromTemplate,
  listSpecificationSetTemplates,
} from '../redux/actions/table/specification_sets';
import CreateSpecificationSetForm from '../forms/create_specification_set_wizard/CreateSpecificationSetForm';

// ICONS
import AddSpecificationToSetIcon from '../assets/images/add-to-tender-60x60.svg';

const CreateSpecificationSetWizard = (props) => {
  const [project_languages, set_project_languages] = useState(null);
  const [specification_set_templates, set_specification_set_templates] =
    useState(null);

  useEffect(() => {
    getStagesLanguages(getActiveStage())
      .then(({ response }) => {
        set_project_languages(response.data);
      })
      .then(() =>
        listSpecificationSetTemplates().then(({ response }) => {
          set_specification_set_templates(response.data);
        })
      );
  }, []);

  const _handleSubmit = (formData) => {
    formData = {
      ...formData,
      editors: _.map(formData.editors, 'id'),
      administrators: _.map(formData.administrators, 'id'),
    };

    let action = {};

    if (formData.new_set) {
      action = createSpecificationSet(getActiveStage(), formData);
    } else {
      action = createSpecificationSetFromTemplate(getActiveStage(), {
        specification_set_template_id: formData.specification_set_template_id,
      });
    }

    return validateForm(
      action.then(() => {
        setNotification(
          __(
            'specification_sets.notification.specification-set-has-been-created'
          )
        );

        listSpecificationSets(props.match.params.stage);

        props.onClose();
      })
    );
  };

  const { id, avatar_url, email, firstname, lastname } = props.user;

  return (
    <>
      <div className={styles.header}>
        <div className={styles.left}>
          <span>
            {__('wizard.specification_sets.title.create-specification-set')}
          </span>
          <span>
            {__('wizard.specification_sets.subtitle.create-specification-set')}
          </span>
        </div>
        <div className={styles.right}>
          <AddSpecificationToSetIcon />
        </div>
      </div>
      {project_languages && (
        <div className={styles.body}>
          <CreateSpecificationSetForm
            project_languages={project_languages}
            specification_set_templates={specification_set_templates}
            initialValues={{
              status: 'draft',
              visibility: '1', //TODO - fetch from project preferences !!!
              administrators: [
                { id, avatar_url, email, name: `${firstname} ${lastname}` },
              ],
              specification_ids: [],
              new_set: true,
            }}
            onSubmit={_handleSubmit}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    table: _.get(state.table, 'specification_sets'),
    user: _.get(state, 'auth'),
  };
};

export default withRouter(
  connect(mapStateToProps)(
    subscribe(Localization, 'localization')(CreateSpecificationSetWizard)
  )
);
