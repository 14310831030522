import * as React from 'react';
import styles from '../styles/wizards/AddSpecificationSetTemplateWizard.scss';
import { __, getActiveStage, validateForm } from '../core/utils';
import BOQIcon from '../assets/images/boq-24x24.svg';
import { withRouter } from 'react-router';
import { setNotification } from '../redux/actions/general/notification';
import Localization from '../helpers/Localization';
import { subscribe } from 'react-contextual';
import AddSpecificationSetTemplateForm from '../forms/add_specification_set_template_wizard/AddSpecificationSetTemplateForm';
import { createSpecificationSetTemplate } from '../redux/actions/table/specification_sets';

const AddSpecificationSetTemplateWizard = ({ onClose, match }) => {
  const _handleSubmit = (formData) => {
    return validateForm(
      createSpecificationSetTemplate(
        match.params.specification_set,
        formData
      ).then(() => {
        onClose();

        setTimeout(
          () =>
            setNotification(
              __('boq.notification.selected-rows-have-been-added-as-template')
            ),
          25
        );
      })
    );
  };

  return (
    <>
      <div className={styles.header}>
        <div className={styles.left}>
          <span>{__('wizard.add-specification-set-as-template.title')}</span>
          <span>{__('wizard.add-specification-set-as-template.subtitle')}</span>
        </div>
        <div className={styles.right}>
          <BOQIcon />
        </div>
      </div>
      <div className={styles.body}>
        <AddSpecificationSetTemplateForm
          onSubmit={_handleSubmit}
          onClose={onClose}
        />
      </div>
    </>
  );
};

export default withRouter(
  subscribe(Localization, 'localization')(AddSpecificationSetTemplateWizard)
);
