import store from '../../../core/store';

// ACTIONS RELATED TO SPECIFICATION MODULE IN WORKSPACE (WS)
export const listPossibleSpecificationSets = (stage_id) => {
  return store.dispatch({
    action: 'table.specification_sets/LIST_POSSIBLE_SPECIFICATION_SETS',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/specifications/sets/possible_sets',
  });
};

export const listPossibleSpecificationSetAdministrators = (stage_id) => {
  return store.dispatch({
    action: 'table.specification_sets/LIST_POSSIBLE_SET_ADMINISTRATORS',
    method: 'GET',
    endpoint:
      '/stages/' + stage_id + '/specifications/sets/possible_administrators',
  });
};

export const listPossibleSpecificationSetFollowers = (stage_id) => {
  return store.dispatch({
    action: 'table.specification_sets/LIST_POSSIBLE_SET_FOLLOWERS',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/specifications/sets/possible_editors',
  });
};

export const createSpecificationSet = (stage_id, formData) => {
  return store.dispatch({
    action: 'table.specification_sets/CREATE_SPECIFICATION_SET',
    method: 'POST',
    endpoint: '/stages/' + stage_id + '/specifications/sets',
    data: formData,
  });
};

export const addSpecificationsToExistingSet = (id, formData) => {
  return store.dispatch({
    action: 'table.specification_sets/ADD_SPECIFICATION_TO_EXISTING_SET',
    method: 'POST',
    endpoint: '/specifications/sets/' + id + '/add_specifications',
    data: formData,
  });
};

export const listSpecificationSets = (stage_id) => {
  return store.dispatch({
    action: 'table.specification_sets/LIST_SPECIFICATION_SETS',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/specifications/sets',
    main: true,
  });
};

export const readSpecificationSet = (id) => {
  return store.dispatch({
    action: 'table.specification_sets/READ_SPECIFICATION_SET',
    method: 'GET',
    endpoint: '/specifications/sets/' + id,
    has404: true,
  });
};

export const updateSpecificationSet = (id, formData) => {
  return store.dispatch({
    action: 'table.specification_sets/UPDATE_SPECIFICATION_SET',
    method: 'PUT',
    endpoint: '/specifications/sets/' + id,
    data: formData,
  });
};

export const updateSpecificationSetToolbar = (id, formData) => {
  return store.dispatch({
    action: 'table.specification_sets/UPDATE_SPECIFICATION_SET_TOOLBAR',
    method: 'PUT',
    endpoint: '/specifications/sets/' + id + '/toolbar',
    data: formData,
  });
};

export const updateSpecificationSetDynamicColumns = (id, formData) => {
  return store.dispatch({
    action: 'table.specification_sets/UPDATE_SPECIFICATION_SET',
    method: 'PUT',
    endpoint: '/specifications/sets/' + id + '/dynamic_columns',
    data: formData,
  });
};

export const readSpecificationSetItems = (id) => {
  return store.dispatch({
    action: 'table.specification_sets/READ_SPECIFICATION_SET_ITEMS',
    method: 'GET',
    endpoint: '/specifications/sets/' + id + '/items',
    has404: true,
    main: true,
  });
};

export const readSpecificationSetDynamicColumns = (id) => {
  return store.dispatch({
    action: 'table.specification_sets/READ_SPECIFICATION_SET_DYNAMIC_COLUMNS',
    method: 'GET',
    endpoint: '/specifications/sets/' + id + '/dynamic_columns',
    has404: true,
  });
};

export const createGenericSpecification = (id, formData) => {
  return store.dispatch({
    action: 'table.specification_sets/CREATE_GENERIC_SPECIFICATION',
    method: 'POST',
    endpoint: '/specifications/sets/' + id + '/generic_specification',
    data: formData,
  });
};

export const updateSpecificationSetFixedColumn = (id, formData) => {
  return store.dispatch({
    action: 'table.specification_sets/UPDATE_SPECIFICATION_SET',
    method: 'PUT',
    endpoint: '/specifications/sets/items/' + id,
    data: formData,
  });
};

export const updateGenericSpecificationProductAttributesGroupFields = (
  specification_set_item,
  attribute,
  formData
) => {
  return store.dispatch({
    action:
      'table.specification_sets/UPDATE_GENERIC_SPECIFICATION_PRODUCT_ATTRIBUTES_GROUP_FIELDS',
    method: 'PUT',
    endpoint:
      '/specifications/sets/items/' +
      specification_set_item +
      '/attributes/' +
      attribute +
      '/fields',
    data: formData,
  });
};

export const convertGenericSpecificationToSpecification = (
  specification_set_item_id,
  specification_id
) => {
  return store.dispatch({
    action:
      'table.specification_sets/CONVERT_GENERIC_SPECIFICATION_TO_SPECIFICATION',
    method: 'PUT',
    endpoint:
      '/specifications/sets/items/' +
      specification_set_item_id +
      '/specification/' +
      specification_id,
  });
};

export const listPossibleSpecificationSubgroups = (id) => {
  return store.dispatch({
    action: 'table.specification_sets/LIST_POSSIBLE_SPECIFICATION_SUBGROUPS',
    method: 'GET',
    endpoint:
      '/specifications/sets/' + id + '/possible_specification_subgroups',
  });
};

export const moveGenericSpecification = (id, formData) => {
  return store.dispatch({
    action: 'table.specification_sets/MOVE_GENERIC_SPECIFICATION',
    method: 'PUT',
    endpoint: '/specifications/sets/items/' + id + '/move',
    data: formData,
  });
};

export const attachSpecificationAttributeFieldToSpecificationSetItem = (
  dynamic_column_id,
  item_id,
  formData
) => {
  return store.dispatch({
    action: 'table.specification_sets/MOVE_GENERIC_SPECIFICATION',
    method: 'PUT',
    endpoint:
      '/specifications/sets/items/' +
      item_id +
      '/dynamic_columns/' +
      dynamic_column_id +
      '/attribute',
    data: formData,
  });
};

export const createSpecificationSetTemplate = (id, { name }) => {
  return store.dispatch({
    action: 'table.specification_sets/CREATE_SPECIFICATION_SET_TEMPLATE',
    method: 'POST',
    endpoint: '/specifications/sets/' + id + '/templates',
    data: {
      name,
    },
  });
};

export const listSpecificationSetTemplates = () => {
  return store.dispatch({
    action: 'table.specification_sets/LIST_SPECIFICATION_SET_TEMPLATE',
    method: 'GET',
    endpoint: '/specifications/sets/templates',
  });
};

export const createSpecificationSetFromTemplate = (stage_id, formData) => {
  return store.dispatch({
    action: 'table.specification_sets/CREATE_SPECIFICATION_SET_FROM_TEMPLATE',
    method: 'POST',
    endpoint: '/stages/' + stage_id + '/specifications/sets/templates',
    data: formData,
  });
};
