import ArrowDownMiddleIcon from '../../../assets/images/arrow-down-middle-15x15.svg';
import classNames from 'classnames';
import styles from '../../../styles/views/specifications/SpecificationSetItems.scss';
import * as _ from 'lodash';

const ExpandIcon = ({
  expanded,
  onExpand,
  expandable,
  depth,
  id,
  unallocated_group_click_handler,
  unallocated_group_collapsed,
}) => {
  if (expandable) {
    return (
      <ArrowDownMiddleIcon
        className={classNames(
          styles.arrow,
          expanded && styles.expanded,
          _.get(styles, `level${depth}`)
        )}
        onClick={() => onExpand(!expanded)}
      />
    );
  } else if (id == 'UN') {
    return (
      <ArrowDownMiddleIcon
        className={classNames(
          styles.arrow,
          !unallocated_group_collapsed && styles.expanded,
          _.get(styles, `level${depth}`)
        )}
        onClick={unallocated_group_click_handler}
      />
    );
  } else {
    return (
      <span
        className={classNames(
          styles.placeholder,
          _.includes(id, 'UN') ? styles.level1 : _.get(styles, `level${depth}`)
        )}
      />
    );
  }
};

export default ExpandIcon;
