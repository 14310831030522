import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/edit_specification_set_dynamic_columns_wizard/EditSpecificationSetDynamicColumnsWizard.scss';
import Button from '../../components/Button';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
  transformToListPairs,
} from '../../core/utils';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import MultiSelect from '../../components/MultiSelect';

// ICONS
import ArrowRightMiddleIcon from '../../assets/images/arrow-right-middle-15x15.svg';

@reduxForm({
  form: 'edit_specification_set_dynamic_columns_wizard.edit_specification_set_dynamic_columns_form',
})
@mapStateToProps((state, props) => ({
  store: state.table.specification_sets,
  values: getFormValues(
    'edit_specification_set_dynamic_columns_wizard.edit_specification_set_dynamic_columns_form'
  )(state),
  application_language_id: state.auth.language_id,
}))
@subscribe(Localization, 'localization')
@withRouter
class EditSpecificationSetDynamicColumnsForm extends Component {
  render() {
    const { flyout } = this.props.store;
    const { application_language_id } = this.props;

    const product_attribute_fields = _.sortBy(
      _.map(
        _.reject(this.props.localization.product_attribute_fields, (field) => {
          return (
            _.includes(
              _.transform(
                flyout.attribute_groups,
                (attributes, group) => {
                  return _.merge(
                    attributes,
                    _.map(group.attributes, 'template_field_ids')
                  );
                },
                []
              ),
              field.id
            ),
            field.discontinued
          );
        }),
        (field) => ({
          label: getLocalized(field.label, application_language_id),
          tooltip: getLocalized(field.description, application_language_id),
          value: field.id,
          groupPriority: _.get(
            this.props.localization.product_attribute_groups,
            [field.product_attribute_template_group_id, 'priority']
          ),
          group: getLocalized(
            _.get(this.props.localization.product_attribute_groups, [
              field.product_attribute_template_group_id,
              'name',
            ]),
            application_language_id
          ),
        })
      ),
      'groupPriority'
    );

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <Field
          name='template_field_ids'
          searchable={true}
          component={MultiSelect}
          label={__('specification_set.flyout.edit_dynamic_columns')}
          noSort
          options={product_attribute_fields}
        />

        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('specification_sets', {
                edit_specification_set_dynamic_columns_wizard: undefined,
              })
            }
          />
          <Button
            lightBlue
            medium
            submit
            right
            leftText={__('button.edit')}
            rightIcon={ArrowRightMiddleIcon}
            spinning={this.props.submitting}
          />
        </div>
      </Form>
    );
  }
}

export default EditSpecificationSetDynamicColumnsForm;
