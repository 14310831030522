import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/add_specification_product_attribute_group_field_wizard/AddSpecificationProductAttributeGroupFieldForm.scss';
import ArrowRightMiddleIcon from '../../assets/images/arrow-right-middle-15x15.svg';
import Button from '../../components/Button';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
  transformToListPairs,
} from '../../core/utils';
import Select from '../../components/Select';
import classNames from 'classnames';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import Input from '../../components/Input';
import MultiSelect from '../../components/MultiSelect';

@reduxForm({
  form: 'add_specification_product_attribute_group_field_wizard.add_specification_product_attribute_group_field',
})
@mapStateToProps((state, props) => ({
  store: state.table.working_set_specifications,
  values: getFormValues(
    'add_specification_product_attribute_group_field_wizard.add_specification_product_attribute_group_field'
  )(state),
  application_language_id: state.auth.language_id,
}))
@subscribe(Localization, 'localization')
@withRouter
class AddSpecificationProductAttributeGroupFieldForm extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.values.custom_attribute != this.props.values.custom_attribute
    ) {
      this.props.change('template_field_ids', null);
      this.props.change('unit_id', null);
      this.props.change('type', null);
      this.props.change('label', {});
    }
  }

  render() {
    const { flyout } = this.props.store;
    const { application_language_id } = this.props;
    const { custom_attribute, unit_id, type } = this.props.values;
    const units = custom_attribute
      ? this.props.localization.units
      : _.filter(this.props.localization.units, [
          'unit_group_id',
          _.get(this.props.localization.units, [unit_id, 'unit_group_id']),
        ]);

    const product_attribute_fields = _.sortBy(
      _.map(
        _.reject(this.props.localization.product_attribute_fields, (field) => {
          return (
            _.includes(
              _.transform(
                flyout.attribute_groups,
                (attributes, group) => {
                  return _.merge(
                    attributes,
                    _.map(group.attributes, 'template_field_ids')
                  );
                },
                []
              ),
              field.id
            ),
            field.discontinued
          );
        }),
        (field) => ({
          label: getLocalized(field.label, application_language_id),
          tooltip: getLocalized(field.description, application_language_id),
          value: field.id,
          groupPriority: _.get(
            this.props.localization.product_attribute_groups,
            [field.product_attribute_template_group_id, 'priority']
          ),
          group: getLocalized(
            _.get(this.props.localization.product_attribute_groups, [
              field.product_attribute_template_group_id,
              'name',
            ]),
            application_language_id
          ),
        })
      ),
      'groupPriority'
    );

    const application_languages = _.transform(
      _.filter(this.props.localization.languages, ['application_language', 1]),
      (languages, language) => {
        languages[language.id] = language.id == 1;
      },
      {}
    );

    const data_types = [
      {
        label: __(
          'specification.working-set.flyout.add-specification-attributes.custom.type.boolean'
        ),
        value: 'boolean',
      },
      {
        label: __(
          'specification.working-set.flyout.add-specification-attributes.custom.type.string'
        ),
        value: 'string',
      },
      {
        label: __(
          'specification.working-set.flyout.add-specification-attributes.custom.type.numeric'
        ),
        value: 'numeric',
      },
      {
        label: __(
          'specification.working-set.flyout.add-specification-attributes.custom.type.color'
        ),
        value: 'color',
      },
      {
        label: __(
          'specification.working-set.flyout.add-specification-attributes.custom.type.material'
        ),
        value: 'material',
      },
      {
        label: __(
          'specification.working-set.flyout.add-specification-attributes.custom.type.texture'
        ),
        value: 'texture',
      },
      {
        label: __(
          'specification.working-set.flyout.add-specification-attributes.custom.type.range'
        ),
        value: 'range',
      },
    ];

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.chooser}>
          <div
            className={classNames(
              styles.option,
              !custom_attribute && styles.selected
            )}
            onClick={() => this.props.change('custom_attribute', false)}
          >
            <span />
            {__(
              'specifications.working-set.flyout.add-specification-attributes.predefined-attribute'
            )}
          </div>
          <div
            className={classNames(
              styles.option,
              custom_attribute && styles.selected
            )}
            onClick={() => this.props.change('custom_attribute', true)}
          >
            <span />
            {__(
              'specifications.working-set.flyout.add-specification-attributes.custom-attribute'
            )}
          </div>
        </div>
        {!custom_attribute && (
          <Field
            name='template_field_ids'
            searchable={true}
            component={MultiSelect}
            label={__(
              'specification.working-set.flyout.add-specification-attributes.attributes'
            )}
            noSort
            options={product_attribute_fields}
          />
        )}
        {custom_attribute && (
          <>
            <Field
              name='type'
              component={Select}
              label={__(
                'specification.working-set.flyout.add-specification-attributes.custom-attributes.type'
              )}
              options={data_types}
            />
            <div className={styles.flex}>
              <div className={styles.f3}>
                <Field
                  name='label'
                  maxLength={50}
                  component={Input}
                  localized={application_languages}
                  label={__(
                    'specification.working-set.flyout.add-specification-attributes.custom-attributes.label'
                  )}
                />
              </div>
              {_.includes(['numeric', 'range'], type) && (
                <div className={styles.f1}>
                  <Field
                    name='unit_id'
                    component={Select}
                    searchable
                    noSort
                    label={__('specifications.units-of-measure')}
                    options={transformToListPairs(units, 'symbol')}
                  />
                </div>
              )}
            </div>
          </>
        )}
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('working_set_specifications', {
                add_specification_product_attribute_group_field_wizard:
                  undefined,
              })
            }
          />
          <Button
            lightBlue
            medium
            submit
            right
            leftText={__('button.add')}
            rightIcon={ArrowRightMiddleIcon}
            spinning={this.props.submitting}
          />
        </div>
      </Form>
    );
  }
}

export default AddSpecificationProductAttributeGroupFieldForm;
