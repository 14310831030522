import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/CreateGenericSpecification.scss';
import autobind from 'autobind-decorator';
import {
  __,
  getActiveProject,
  mapStateToProps,
  validateForm,
} from '../core/utils';
import MoveIcon from '../assets/images/move-64x64.svg';
import { withRouter } from 'react-router';
import { subscribe } from 'react-contextual';
import Localization from '../helpers/Localization';
import _ from 'lodash';
import {
  createGenericSpecification,
  listPossibleSpecificationSubgroups,
  moveGenericSpecification,
  readSpecificationSetItems,
} from '../redux/actions/table/specification_sets';
import CreateGenericSpecificationForm from '../forms/create_generic_specification_wizard/CreateGenericSpecificationForm';
import { readProjectGeneral } from '../redux/actions/projects';

@mapStateToProps((state) => ({
  id: _.get(
    state.table,
    'specification_sets.create_generic_specification_wizard.id'
  ),
  name: _.get(
    state.table,
    'specification_sets.create_generic_specification_wizard.name'
  ),
  store: state.table['specification_sets'],
}))
@subscribe(Localization, 'localization')
@withRouter
class CreateGenericSpecificationWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      project_language: null,
    };
  }

  componentDidMount() {
    listPossibleSpecificationSubgroups(
      this.props.match.params.specification_set
    )
      .then(({ response }) => {
        this.setState({ options: response.data });
      })
      .then(() => {
        readProjectGeneral(getActiveProject()).then(({ response }) => {
          this.setState({ project_language: response.data.main_language });
        });
      });
  }

  @autobind
  _handleSubmit(formData) {
    const specification_subgroup_id = formData.specification_subgroup_id;

    return createGenericSpecification(
      this.props.match.params.specification_set,
      { specification_subgroup_id }
    ).then(() => {
      readSpecificationSetItems(this.props.match.params.specification_set);

      this.props.onClose();
    });
  }

  render() {
    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>
              {__('specification-sets.create-generic-specification.title')}
            </span>
            <span>
              {__(
                'specification-sets.create-generic-specification.description'
              )}
            </span>
          </div>
          <div className={styles.right}>
            <MoveIcon />
          </div>
        </div>
        <div className={styles.body}>
          {this.state.project_language && (
            <CreateGenericSpecificationForm
              onSubmit={this._handleSubmit}
              projectLanguage={this.state.project_language}
              initialValues={{}}
              options={this.state.options}
            />
          )}
        </div>
      </>
    );
  }
}

export default CreateGenericSpecificationWizard;
