import * as React from 'react';
import { Component } from 'react';
import DuplicateIcon from '../../assets/images/duplicate-16x16.svg';
import {
  createSidebar,
  toggleSidebarCollapse,
  updateSidebar,
} from '../../redux/actions/general/sidebar';
import {
  __,
  dateFrom,
  getActiveProject,
  getActiveStage,
  getLocalized,
  getURLParam,
  redirect,
  setTableParams,
  setURLParam,
  formatMoney,
} from '../../core/utils';
import {
  enableProjectsDropdown,
  enableStagesDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import {
  deleteWSSpecification,
  listWorkingSetSpecifications,
  readWorkingSetSpecification,
  setMainSpecification,
} from '../../redux/actions/table/working_set_specifications';
import * as _ from 'lodash';
import { setPlusActions } from '../../redux/actions/general/plus';
import TableNA from '../../components/TableNA';
import Avatar from '../../components/Avatar';
import styles from '../../styles/views/specifications/WorkingSetSpecifications.scss';
import Tooltip from '../../components/Tooltip';
import DeliveryIcon from '../../assets/images/delivery-15x15.svg';
import GoToDeliveryIcon from '../../assets/images/go-to-delivery-16x16.svg';
import { setNotification } from '../../redux/actions/general/notification';
import OriginProductIcon from '../../assets/images/product-origin-16x16.svg';
import SpecificationByArchitectIcon from '../../assets/images/specification-by-architect-16x16.svg';
import SpecificationFromProductIcon from '../../assets/images/specification-from-product-16x16.svg';
import MakeMainIcon from '../../assets/images/make-main-16x16.svg';
import Image from '../../components/Image';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import classNames from 'classnames';
import WorkingSetSpecificationsFlyout from '../../flyouts/working_set_specifications/WorkingSetSpecificationsFlyout';
import { readProjectGeneral } from '../../redux/actions/projects';
import Table from '../../components/Table';
import PrintIcon from '../../assets/images/print-16x16.svg';
import TagIcon from '../../assets/images/tag-16x16.svg';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import ArrowDoubleRightSmallIcon from '../../assets/images/arrow-double-right-small-15x15.svg';
import { connect } from 'react-redux';
import Localization from '../../helpers/Localization';
import { subscribe } from 'react-contextual';
import AddToSetIcon from '../../assets/images/add-long-description-from-boq-16x16.svg';

const mapStateToProps = (state) => {
  return {
    table: state.table['working_set_specifications'],
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.working_set_specifications/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@subscribe(Localization, 'localization')
@connect(mapStateToProps, mapDispatchToProps)
class WorkingSetSpecifications extends Component {
  componentDidMount() {
    enableProjectsDropdown(({ stage_id }) =>
      redirect(
        '/stages/' + stage_id + '/specifications/working?filter.subgroup='
      )
    );
    enableStagesDropdown(({ stage_id }) =>
      redirect(
        '/stages/' + stage_id + '/specifications/working?filter.subgroup='
      )
    );

    createSidebar({
      title: __('submenu.title.specifications'),
      items: [
        {
          title: __('submenu.title.current-set-specifications'),
          link: () => '/stages/' + getActiveStage() + '/specifications/current',
        },
        {
          title: __('submenu.title.working-set-specifications'),
          link: () =>
            '/stages/' +
            getActiveStage() +
            '/specifications/working?filter.subgroup=',
          active: true,
          expanded: true,
          onArrowClick: () => toggleSidebarCollapse('items.1'),
        },
        {
          title: __('submenu.title.specification-sets'),
          link: () => '/stages/' + getActiveStage() + '/specifications/sets',
        },
        {
          title: __('submenu.title.deliveries'),
          link: () =>
            '/stages/' + getActiveStage() + '/specification_deliveries',
        },
        {
          title: __('submenu.title.approvals'),
          link: () =>
            '/stages/' + getActiveStage() + '/specification_approvals',
        },
      ],
    });

    setPlusActions([
      {
        title: __('button.add-new'),
        onClick: () =>
          setTableParams('working_set_specifications', {
            wizard_active: true,
            linked_specification_code_id: undefined,
          }),
      },
    ]);

    setURLParam('filter.status', 'active');
  }

  componentDidUpdate(prevProps) {
    //if there are new filter options available, update sidebar
    if (!_.isEqual(this.props.table.filters, prevProps.table.filters)) {
      const setSubgroups = (subgroups) => {
        subgroups = _.trim(
          _.join(
            _.reject(subgroups, (subgroup_id) => subgroup_id == 'n/a'),
            '|'
          ),
          '|'
        );

        setURLParam(
          'filter.subgroup',
          _.isEmpty(subgroups) ? 'n/a' : subgroups
        );
      };

      const groups = _.get(this.props.table.filters, 'subgroup.items');

      const all_ids = _.flattenDeep(
        _.map(groups, (group) => _.keys(group.items))
      );

      const items = _.map(
        _.map(_.sortBy(groups, 'title')),
        (group, group_index) => ({
          ...group,
          onClick: () => setSubgroups(_.keys(group.items)),
          onArrowClick: () =>
            toggleSidebarCollapse('items.1.items.' + group_index),
          active: () => {
            const active_subgroups = _.split(
              getURLParam('filter.subgroup'),
              '|'
            );

            return (
              _.isEmpty(getURLParam('filter.subgroup')) ||
              _.some(_.intersection(active_subgroups, _.keys(group.items)))
            );
          },
          checkbox: {
            status: () => {
              const active_subgroups = _.split(
                getURLParam('filter.subgroup'),
                '|'
              );

              const intersected = _.intersection(
                active_subgroups,
                _.keys(group.items)
              );

              if (_.isEmpty(getURLParam('filter.subgroup'))) {
                return 'checked';
              }

              if (_.isEmpty(intersected)) {
                return 'unchecked';
              } else if (_.size(intersected) == _.size(_.keys(group.items))) {
                return 'checked';
              } else {
                return 'neutral';
              }
            },
            onClick: () => {
              const active_subgroups =
                getURLParam('filter.subgroup') == ''
                  ? all_ids
                  : _.split(getURLParam('filter.subgroup'), '|');

              const intersected = _.intersection(
                active_subgroups,
                _.keys(group.items)
              );

              if (_.size(intersected) == _.size(_.keys(group.items))) {
                setSubgroups(
                  _.reject(active_subgroups, (id) =>
                    _.includes(_.keys(group.items), id)
                  )
                );
              } else {
                _.each(
                  _.keys(group.items),
                  (id) =>
                    !_.includes(active_subgroups, id) &&
                    active_subgroups.push(id)
                );

                setSubgroups(active_subgroups);
              }
            },
          },
          arrow: true,
          items: _.sortBy(
            _.map(group.items, (subgroup, subgroup_id) => ({
              ...subgroup,
              onClick: () => setURLParam('filter.subgroup', subgroup_id),
              active: () => {
                const active_subgroups = _.split(
                  getURLParam('filter.subgroup'),
                  '|'
                );

                return (
                  _.isEmpty(getURLParam('filter.subgroup')) ||
                  _.includes(active_subgroups, subgroup_id)
                );
              },
              checkbox: {
                status: () => {
                  const active_subgroups = _.split(
                    getURLParam('filter.subgroup'),
                    '|'
                  );

                  if (_.isEmpty(getURLParam('filter.subgroup'))) {
                    return 'checked';
                  }

                  return _.includes(active_subgroups, subgroup_id)
                    ? 'checked'
                    : 'unchecked';
                },
                onClick: () => {
                  const active_subgroups =
                    getURLParam('filter.subgroup') == ''
                      ? all_ids
                      : _.split(getURLParam('filter.subgroup'), '|');

                  if (_.includes(active_subgroups, subgroup_id)) {
                    setSubgroups(
                      _.reject(active_subgroups, (id) => id == subgroup_id)
                    );
                  } else {
                    active_subgroups.push(subgroup_id);

                    setSubgroups(active_subgroups);
                  }
                },
              },
            })),
            'title'
          ),
        })
      );

      updateSidebar('items.1.items', items);
    }

    if (
      this.props.table.clicked_row &&
      this.props.table.clicked_row !== prevProps.table.clicked_row
    ) {
      if (!prevProps.table.clicked_row) {
        setTableParams('working_set_specifications', {
          flyout: undefined,
        });
      }

      readWorkingSetSpecification(this.props.table.clicked_row).then(
        ({ response }) => {
          setTableParams('working_set_specifications', {
            flyout: response.data,
          });
        }
      );
    }
  }

  _handleOpacity(value, row) {
    return (row.product && row.product.discontinued) ||
      _.includes(['canceled'], row.status) ? (
      <div className={styles.faded}>{value}</div>
    ) : (
      value
    );
  }

  _openDrawingsTab(id) {
    this.props.actions.setTableParams({
      clicked_row: id,
      showFilters: undefined,
      tab: 'drawings',
    });
  }

  _openImagesTab(id) {
    this.props.actions.setTableParams({
      clicked_row: id,
      showFilters: undefined,
      tab: 'images',
    });
  }

  _openFilesTab(id) {
    this.props.actions.setTableParams({
      clicked_row: id,
      showFilters: undefined,
      tab: 'files',
    });
  }

  _openLinkedSpecificationsTab(id) {
    this.props.actions.setTableParams({
      clicked_row: id,
      showFilters: undefined,
      tab: 'linked-specifications',
    });
  }

  render() {
    const { auth } = this.props;
    const { specification_groups, specification_subgroups } =
      this.props.localization;
    const { project_language } = this.props.table;

    const columns = [
      {
        key: 'code',
        name: __('views.working-set-specifications.code'),
        value: (code, row) => {
          const specification_subgroup =
            _.get(specification_subgroups, [row.specification_subgroup_id]) ||
            {};
          const specification_group =
            _.get(
              specification_groups,
              _.get(specification_subgroup, 'specification_group_id')
            ) || {};

          return this._handleOpacity(
            (!row.main ? '↳ ' : '') +
              getLocalized(specification_group.code, project_language) +
              specification_subgroup.code +
              _.padStart(code, 2, '0'),
            row
          );
        },
        required: true,
        width: 80,
      },
      {
        key: 'custom',
        name: __('specifications.working-set.table-title.type'),
        value: (custom, row) =>
          this._handleOpacity(
            row.product ? (
              <Tooltip
                text={__(
                  'specifications.working-set-specifications.specification-created-from-product'
                )}
              >
                <SpecificationFromProductIcon className={styles.type} />
              </Tooltip>
            ) : (
              <Tooltip
                text={__(
                  'specifications.working-set-specifications.specification'
                )}
              >
                <SpecificationByArchitectIcon className={styles.type} />
              </Tooltip>
            ),
            row
          ),
        sortable: false,
        width: 50,
      },
      {
        key: 'thumb',
        name: __('specifications.working-set.table-title.thumbnail'),
        value: (created_by, row) =>
          this._handleOpacity(
            row.thumbnail_url ? (
              <Tooltip
                placement='right'
                text={
                  <Image
                    className={styles.thumbPreview}
                    src={row.thumbnail_url}
                  />
                }
                html={true}
                dark={true}
              >
                <Image className={styles.thumb} src={row.thumbnail_url} />
              </Tooltip>
            ) : (
              <TableNA />
            ),
            row
          ),
        width: 50,
        sortable: false,
      },
      {
        key: 'description',
        name: __('specifications.working-set.table-title.description'),
        required: true,
        value: (value, row) => {
          const description = getLocalized(value, project_language);
          return this._handleOpacity(
            description ? (
              <Tooltip text={description}>{description}</Tooltip>
            ) : (
              <TableNA />
            ),
            row
          );
        },
      },
      {
        key: 'model',
        name: __('specifications.working-set.table-title.model'),
        default: true,
        value: (value, row) => this._handleOpacity(value, row),
        width: 160,
      },
      {
        key: 'brand',
        name: __('specifications.working-set.table-title.manufacturer'),
        value: (brand, row) =>
          this._handleOpacity(
            row.product ? brand : row.manufacturer_company,
            row
          ),
        width: 160,
      },
      {
        key: 'discipline',
        name: __('specifications.working-set.table-title.discipline'),
        value: (discipline, row) => {
          const prefixed_discipline = [
            row.discipline.code,
            row.discipline.id == null
              ? row.discipline.description + '*'
              : _.get(this.props.localization.disciplines, [
                  row.discipline.id,
                  'name',
                ]),
          ].join(': ');

          return this._handleOpacity(
            <Tooltip text={prefixed_discipline}>{row.discipline.code}</Tooltip>,
            row
          );
        },
        width: 70,
        active: false,
      },
      {
        key: 'team',
        name: __('specifications.working-set.table-title.team'),
        value: (team, row) =>
          this._handleOpacity(
            <Tooltip text={team.company}>{team.company}</Tooltip>,
            row
          ),
        active: false,
      },
      {
        key: 'product',
        name: __('specifications.working-set.product-in-catalog'),
        value: (product, row) =>
          this._handleOpacity(
            product ? (
              <Tooltip
                text={__('specifications.working-set.view-product-in-catalog')}
              >
                <OriginProductIcon
                  className={styles.product}
                  onClick={(e) => {
                    e.stopPropagation();

                    redirect('/products?id=' + product.id);
                  }}
                />
              </Tooltip>
            ) : (
              <TableNA />
            ),
            row
          ),
        active: false,
        width: 50,
      },
      {
        key: 'created_at',
        name: __('specifications.working-set.table-title.added'),
        value: (value, row) => this._handleOpacity(dateFrom(value), row),
        active: false,
        width: 80,
      },
      {
        key: 'delivery',
        name: __('specifications.working-set.table-title.pending-delivery'),
        value: (value, row) =>
          !_.isEmpty(row.delivery) ? (
            this._handleOpacity(
              <Tooltip
                text={
                  __('specifications.working-set.go-to-delivery') +
                  ' "' +
                  row.delivery.title +
                  '"'
                }
              >
                <div
                  className={styles.delivery}
                  onClick={(e) => {
                    e.stopPropagation();

                    redirect(
                      '/stages/' +
                        this.props.match.params.stage +
                        '/specification_deliveries/my/' +
                        row.delivery.id
                    );
                  }}
                >
                  <GoToDeliveryIcon />
                </div>
              </Tooltip>,
              row
            )
          ) : (
            <TableNA />
          ),
        active: false,
        width: 50,
      },
      {
        key: 'quantity',
        name: __('specifications.working-set.table-title.quantity'),
        value: (value, row) => (
          <div className={styles.right}>
            {getURLParam('filter.room')
              ? this._handleOpacity(
                  formatMoney(
                    _.map(_.split(getURLParam('filter.room'), '|'), (room) =>
                      _.get(
                        _.find(
                          row.BOQ_positions,
                          (position) => position.area_id == room
                        ),
                        ['quantity']
                      )
                    )
                  ),
                  row
                )
              : this._handleOpacity(formatMoney(value), row)}
          </div>
        ),
        active: false,
        width: 80,
        fullWidth: true,
      },
      {
        key: 'unit_id',
        name: __('specifications.working-set.table-title.unit'),
        value: (value, row) =>
          this._handleOpacity(
            _.get(this.props.localization.units, [value, 'symbol']),
            row
          ),
        active: false,
        width: 70,
      },
      {
        key: 'price',
        name: __('specifications.working-set.table-title.price'),
        value: (value, row) => (
          <div className={styles.right}>
            {this._handleOpacity(formatMoney(value), row)}
          </div>
        ),
        active: false,
        width: 80,
        fullWidth: true,
      },
      {
        key: 'total',
        name: __('specifications.working-set.table-title.total'),
        value: (value, row) => (
          <div className={styles.right}>
            {getURLParam('filter.room')
              ? this._handleOpacity(
                  formatMoney(
                    _.map(_.split(getURLParam('filter.room'), '|'), (room) =>
                      _.get(
                        _.find(
                          row.BOQ_positions,
                          (position) => position.area_id == room
                        ),
                        ['quantity']
                      )
                    ) * row.price
                  ),
                  row
                )
              : this._handleOpacity(formatMoney(value), row)}
          </div>
        ),
        active: false,
        width: 100,
        fullWidth: true,
      },
      {
        key: 'source',
        name: __('specifications.working-set.table-title.source'),
        sortable: false,
        value: (x, row) => {
          if (row.product) {
            return this._handleOpacity(
              <Tooltip
                text={__(
                  'specifications.working-set.created-from-catalog-product'
                )}
              >
                <span className={classNames(styles.balloon, styles.catalog)}>
                  CA
                </span>
              </Tooltip>,
              row
            );
          } else if (row.created_by.company_id == auth.company.id) {
            return this._handleOpacity(
              <Tooltip
                text={__('specifications.working-set.created-by-your-company')}
              >
                <span className={classNames(styles.balloon, styles.myCompany)}>
                  MY
                </span>
              </Tooltip>,
              row
            );
          } else {
            return this._handleOpacity(
              <Tooltip
                text={__(
                  'specifications.working-set.created-by-your-partners-company'
                )}
              >
                <span
                  className={classNames(styles.balloon, styles.partnerCompany)}
                >
                  PA
                </span>
              </Tooltip>,
              row
            );
          }
        },
        active: false,
        width: 50,
      },
      {
        key: 'created_by',
        name: __('views.working-set-specifications.added-by'),
        value: (created_by, row) =>
          this._handleOpacity(
            <Avatar
              avatar_url={created_by.avatar_url}
              name={created_by.fullname}
              active={created_by.active}
            />,
            row
          ),
        width: 50,
      },
      {
        key: 'ready_for_delivery',
        name: __('views.working-set-specifications.ready-for-delivery'),
        value: (ready_for_delivery) => (
          <div
            className={classNames(
              styles.chip,
              ready_for_delivery && styles.yes
            )}
          >
            {ready_for_delivery ? 'Yes' : 'No'}
          </div>
        ),
        width: 100,
      },
      {
        key: 'drawings',
        name: __('table.columns.drawings'),
        width: 30,
        active: false,
        value: (drawings, row) =>
          drawings == 0 ? (
            0
          ) : (
            <div
              className={styles.goto}
              onClick={() => this._openDrawingsTab(row.id)}
            >
              {drawings}
              <Tooltip
                text={__('views.working-set-specifications.goto-drawings')}
              >
                <ArrowDoubleRightSmallIcon />
              </Tooltip>
            </div>
          ),
      },
      {
        key: 'images',
        name: __('table.columns.images'),
        width: 30,
        active: false,
        value: (images, row) =>
          images == 0 ? (
            0
          ) : (
            <div
              className={styles.goto}
              onClick={() => this._openImagesTab(row.id)}
            >
              {images}
              <Tooltip
                text={__('views.working-set-specifications.goto-images')}
              >
                <ArrowDoubleRightSmallIcon />
              </Tooltip>
            </div>
          ),
      },
      {
        key: 'files',
        name: __('table.columns.files'),
        width: 30,
        active: false,
        value: (files, row) =>
          files == 0 ? (
            0
          ) : (
            <div
              className={styles.goto}
              onClick={() => this._openFilesTab(row.id)}
            >
              {files}
              <Tooltip text={__('views.working-set-specifications.goto-files')}>
                <ArrowDoubleRightSmallIcon />
              </Tooltip>
            </div>
          ),
      },
      {
        key: 'linked_specifications_count',
        name: __('table.columns.linked_specifications'),
        width: 30,
        active: false,
        value: (linked_specifications_count, row) =>
          linked_specifications_count == 0 ? (
            0
          ) : (
            <div
              className={styles.goto}
              onClick={() => this._openLinkedSpecificationsTab(row.id)}
            >
              {linked_specifications_count}
              <Tooltip
                text={__(
                  'views.working-set-specifications.goto-linked_specifications'
                )}
              >
                <ArrowDoubleRightSmallIcon />
              </Tooltip>
            </div>
          ),
      },
      {
        key: 'last_revision',
        name: __('table.columns.revision'),
        value: (value, row) =>
          value ? this._handleOpacity('R' + value.number, row) : <TableNA />,

        width: 50,
      },
    ];

    const singleActions = (specification_id) => {
      const specification =
        _.find(this.props.table.data, { id: specification_id }) || {};

      return [
        <Tooltip
          text={__(
            'specifications.working-set-specifications.print-specification'
          )}
        >
          <PrintIcon
            key={_.uniqueId()}
            onClick={(e) => {
              e.stopPropagation();

              setTableParams('working_set_specifications', {
                print_specifications_wizard: 'print_selected',
                specification_ids: [specification_id],
              });
            }}
          />
        </Tooltip>,
        <Tooltip
          text={__(
            'specifications.working-set-specifications.tooltip.copy-specification'
          )}
        >
          <DuplicateIcon
            key={_.uniqueId()}
            onClick={(e) => {
              e.stopPropagation();
              setTableParams('working_set_specifications', {
                copy_wizard_active: specification_id,
              });
            }}
          />
        </Tooltip>,
        specification.main && specification.can_deliver && (
          <Tooltip
            text={__('specifications.working-set.flyout.deliver-specification')}
            key={_.uniqueId()}
          >
            <DeliveryIcon
              key={_.uniqueId()}
              onClick={(e) => {
                e.stopPropagation();

                specification.ready_for_delivery
                  ? setTableParams('working_set_specifications', {
                      actioned_row: undefined,
                      delivery_wizard_active: true,
                      selected_specifications: [specification],
                    })
                  : setNotification({
                      text: __(
                        'specifications.working-set.flyout.deliver-specification.specification-not-ready-for-delivery'
                      ),
                      type: 'warning',
                    });
              }}
            />
          </Tooltip>
        ),
        !_.isEmpty(specification.product) && (
          <Tooltip
            text={__('specifications.working-set.view-product-in-catalog')}
          >
            <OriginProductIcon
              key={_.uniqueId()}
              onClick={(e) => {
                e.stopPropagation();

                redirect(
                  '/products?id=' +
                    _.get(
                      _.find(this.props.table.data, { id: specification_id }),
                      'product.id'
                    )
                );
              }}
            />
          </Tooltip>
        ),
        !specification.main && (
          <Tooltip text={__('specifications.working-set.make-primary-option')}>
            <MakeMainIcon
              key={_.uniqueId()}
              onClick={(e) => {
                e.stopPropagation();

                showAlertbox({
                  color: 'red',
                  title: __('general.alert.are-you-sure'),
                  description: __(
                    'specifications.working-set.are-you-sure-make-specification-main'
                  ),
                  buttons: [
                    {
                      color: 'lightGray',
                      text: __('general.alert.no'),
                      onClick: 'close',
                    },
                    {
                      color: 'gray',
                      text: __('general.alert.yes'),
                      onClick: () => {
                        setMainSpecification(specification_id).then(() => {
                          hideAlertbox();

                          listWorkingSetSpecifications(
                            this.props.match.params.stage
                          );

                          setNotification(
                            __(
                              'specifications.working-set.specification-set-main-option'
                            )
                          );
                        });
                      },
                    },
                  ],
                });
              }}
            />
          </Tooltip>
        ),
        !specification.has_revisions && (
          <Tooltip
            text={__('specifications.working-set.delete-specification')}
            key={_.uniqueId()}
          >
            <DeleteIcon
              onClick={(e) => {
                e.stopPropagation();

                showAlertbox({
                  color: 'red',
                  title: __('general.alert.are-you-sure'),
                  description: __(
                    'specifications.working-set.delete-specification.alert.are-you-sure'
                  ),
                  buttons: [
                    {
                      color: 'lightGray',
                      text: __('general.alert.no-close'),
                      onClick: 'close',
                    },
                    {
                      color: 'gray',
                      text: __('general.alert.yes-delete'),
                      onClick: () => {
                        deleteWSSpecification(specification_id).then(() => {
                          hideAlertbox();

                          listWorkingSetSpecifications(
                            this.props.match.params.stage
                          );

                          setNotification(
                            __(
                              'specifications.working-set.delete-specification.notification.spec-deleted'
                            )
                          );
                        });
                      },
                    },
                  ],
                });
              }}
            />
          </Tooltip>
        ),
      ];
    };

    const groupActions = (specification_ids) => [
      // <Tooltip key={_.uniqueId()} text={__("specifications.working-set-specifications.print-selected-specifications")}>
      // 	<PrintIcon
      // 		onClick={(e) => {
      // 			e.stopPropagation();

      // 			setTableParams("working_set_specifications", {
      // 				print_specifications_wizard: specification_ids
      // 			});
      // 		}}
      // 	/>
      // </Tooltip>,
      <Tooltip
        text={__(
          'specifications.working-set-specifications.tooltip.add-specifications-to-set'
        )}
        key={_.uniqueId()}
      >
        <AddToSetIcon
          className={styles.addToSetIcon}
          onClick={(e) => {
            e.stopPropagation();

            setTableParams('working_set_specifications', {
              selected_rows: specification_ids,
              add_specifications_to_set_wizard: true,
            });
          }}
        />
      </Tooltip>,

      <Tooltip
        text={__('specifications.working-set.group-actions.multiple-edit-tags')}
        key={_.uniqueId()}
      >
        <TagIcon
          onClick={() =>
            setTableParams('working_set_specifications', {
              multiple_specification_tags_edit_wizard: specification_ids,
            })
          }
        />
      </Tooltip>,
      <Tooltip
        text={__(
          'specifications.working-set.flyout.deliver-specification.create-delivery'
        )}
        key={_.uniqueId()}
      >
        <DeliveryIcon
          onClick={(e) => {
            e.stopPropagation();

            const selected_specifications = _.filter(
              this.props.table.data,
              (specification) => _.includes(specification_ids, specification.id)
            );

            // if (_.size(_.groupBy(selected_specifications, 'team.id')) > 1) {
            //   setNotification({
            //     text: __(
            //       'specifications.working-set.flyout.deliver-specification.cannot-create-delivery-specifications-different-teams'
            //     ),
            //     type: 'warning',
            //   });
            // } else if (
            //   _.size(
            //     _.filter(
            //       selected_specifications,
            //       (specification) => !specification.main
            //     )
            //   ) > 0
            // ) {
            //   setNotification({
            //     text: __(
            //       'specifications.working-set.flyout.deliver-specification.deliver-only-main-option-specification'
            //     ),
            //     type: 'warning',
            //   });
            // } else

            // if (
            //   _.size(
            //     _.filter(
            //       selected_specifications,
            //       (specification) => !specification.can_deliver
            //     )
            //   ) > 0
            // ) {
            //   setNotification({
            //     text: __(
            //       'specifications.working-set.flyout.deliver-specification.cannot-create-delivery-some-pecifications-belong-delivery'
            //     ),
            //     type: 'warning',
            //   });
            // } else {
            setTableParams('working_set_specifications', {
              selected_rows: [],
              delivery_wizard_active: true,
              selected_specifications: selected_specifications,
            });
          }}
        />
      </Tooltip>,
      <Tooltip
        text={__(
          'specifications.working-set-specifications.tooltip.copy-specifications'
        )}
      >
        <DuplicateIcon
          key={_.uniqueId()}
          onClick={(e) => {
            e.stopPropagation();
            setTableParams('working_set_specifications', {
              copy_specifications_wizard_active: specification_ids,
            });
          }}
        />
      </Tooltip>,
    ];

    const viewActions = [
      <Tooltip
        key={_.uniqueId()}
        text={__(
          'specifications.working-set-specifications.print-all-specifications'
        )}
      >
        <PrintIcon
          onClick={(e) => {
            e.stopPropagation();

            const { selected_rows } = this.props.table;

            setTableParams('working_set_specifications', {
              specification_ids: selected_rows,
              print_specifications_wizard:
                selected_rows.length > 0 ? 'print_selected' : 'print_all',
            });
          }}
        />
      </Tooltip>,
    ];

    const gridOptions = {
      section: __('submenu.title.working-set-specifications'),
      image: (id, row) => row.thumbnail_url,
      title: (row) => {
        const specification_subgroup =
          _.get(specification_subgroups, [row.specification_subgroup_id]) || {};
        const specification_group =
          _.get(
            specification_groups,
            _.get(specification_subgroup, 'specification_group_id')
          ) || {};

        const status = row.status == 'canceled' ? ' (canceled)' : '';

        return this._handleOpacity(
          (!row.main ? '↳ ' : '') +
            getLocalized(specification_group.code, project_language) +
            specification_subgroup.code +
            _.padStart(row.code, 2, '0') +
            status,
          row
        );
      },
      rightTitle: (row) => (
        <>
          {row.last_revision && 'R' + row.last_revision?.number}
          {!_.isEmpty(row.delivery) && (
            <Tooltip
              text={__(
                'specifications.working-set.flyout.deliver-specification.specification-already-delivery-process'
              )}
            >
              <div className={styles.circleP}>P</div>
            </Tooltip>
          )}
        </>
      ),
      subTitle: (row) => {
        return _.filter(
          [_.get(row.description, project_language), row.model, row.brand],
          _.identity
        ).join(' / ');
      },
      content: (row) => [],
    };

    const preview = (
      <WorkingSetSpecificationsFlyout
        readAction={(specification_id) => {
          return new Promise((resolve) => {
            readWorkingSetSpecification(
              getURLParam('id') || specification_id
            ).then(({ response }) => {
              setTableParams('working_set_specifications', {
                flyout: response.data,
              });

              resolve(response);
            });
          });
        }}
      />
    );

    const filtersSort = (filter, value, label) => {
      switch (filter) {
        case 'creator':
        case 'drawings':
        case 'updater':
        case 'tag':
          return value;

        case 'delivery':
          return label === __('specifications.filters.delivery.unassigned')
            ? -1
            : label.toLowerCase();
      }
    };

    const footer = (
      <>
        <span className={styles.total}>Total:</span>
        <Tooltip
          text={__('specifications.working-set.footer.total-description')}
        >
          <span className={styles.number}>
            {formatMoney(
              _.get(this.props.table.additional_params, [
                'quantity_grand_total',
              ])
            )}
          </span>
        </Tooltip>

        {!!_.size(
          _.filter(this.props.table.filters, (filter, key) => {
            return getURLParam('filter.' + key) != '';
          })
        ) &&
          !this.props.table.loading && (
            <>
              <span className={styles.totalFiltered}>Total filtered:</span>
              <Tooltip
                text={__(
                  'specifications.working-set.footer.total-filtered-description'
                )}
              >
                <span className={styles.number}>
                  {`${formatMoney(
                    _.get(this.props.table.additional_params, [
                      'quantity_total_filtered',
                    ])
                  )} (${formatMoney(
                    (_.get(this.props.table.additional_params, [
                      'quantity_total_filtered',
                    ]) /
                      _.get(this.props.table.additional_params, [
                        'quantity_grand_total',
                      ])) *
                      100.0
                  )} %)`}
                </span>
              </Tooltip>
            </>
          )}
      </>
    );

    return (
      <Table
        filtersSort={filtersSort}
        footer={footer}
        listAction={() =>
          listWorkingSetSpecifications(this.props.match.params.stage)
        }
        name='working_set_specifications'
        title={__('submenu.title.working-set-specifications')}
        columns={columns}
        preview={this.props.table.flyout ? preview : []}
        gridOptions={gridOptions}
        groupActions={groupActions}
        viewActions={viewActions}
        singleActions={singleActions}
        onLoad={() => {
          listActiveModules().then(() => {
            readProjectGeneral(getActiveProject()).then(({ response }) => {
              setTableParams('working_set_specifications', {
                project_language: response.data.main_language,
              });
            });
          });
        }}
      />
    );
  }
}

export default WorkingSetSpecifications;
