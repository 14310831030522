import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/create_generic_specification_wizard/CreateGenericSpecificationForm.scss';
import Button from '../../components/Button';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';
import { withRouter } from 'react-router';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import Select from '../../components/Select';
import * as _ from 'lodash';

@withRouter
@subscribe(Localization, 'localization')
@reduxForm({
  form: 'create_generic_specification_wizard.create_generic_specification',
})
@mapStateToProps((state) => ({
  application_language_id: state.auth.language_id,
}))
class CreateGenericSpecificationForm extends Component {
  render() {
    const { specification_groups, specification_subgroups } =
      this.props.localization;

    const { projectLanguage, application_language_id } = this.props;

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.form}>
          <Field
            name='specification_subgroup_id'
            component={Select}
            searchable
            noSort
            label={__(
              'move-generic-specification-item.specification_subgroup_id'
            )}
            options={_.sortBy(
              _.map(specification_subgroups, (specification_subgroup) => {
                const specification_group = _.get(
                  specification_groups,
                  _.get(specification_subgroup, 'specification_group_id'),
                  {}
                );
                const label =
                  getLocalized(specification_group.code, projectLanguage) +
                  [
                    _.padEnd(specification_subgroup.code, 3, '0'),
                    getLocalized(
                      specification_subgroup.name,
                      application_language_id
                    ),
                  ].join(': ');
                const group = [
                  getLocalized(specification_group.code, projectLanguage),
                  getLocalized(
                    specification_group.name,
                    application_language_id
                  ),
                ].join(': ');

                return {
                  value: specification_subgroup.id,
                  label,
                  group,
                };
              }),
              'group'
            )}
          />
        </div>
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('specification_sets', {
                create_generic_specification_wizard_active: undefined,
              })
            }
          />
          <Button
            lightBlue
            medium
            right
            submit
            middleText={__('button.create')}
            spinning={this.props.submitting}
          />
        </div>
      </Form>
    );
  }
}

export default CreateGenericSpecificationForm;

// options={_.filter(
//   _.sortBy(
//     _.map(specification_subgroups, (specification_subgroup) => {
//       const specification_group = _.get(
//         specification_groups,
//         _.get(specification_subgroup, 'specification_group_id'),
//         {}
//       );
//       const label =
//         getLocalized(specification_group.code, projectLanguage) +
//         [
//           _.padEnd(specification_subgroup.code, 3, '0'),
//           getLocalized(
//             specification_subgroup.name,
//             application_language_id
//           ),
//         ].join(': ');
//       const group = [
//         getLocalized(specification_group.code, projectLanguage),
//         getLocalized(
//           specification_group.name,
//           application_language_id
//         ),
//       ].join(': ');

//       return {
//         value: specification_subgroup.id,
//         label,
//         group,
//       };
//     }),
//     'group'
//   ),
//   (item) => !_.includes(_.map(options, 'value'), item.value)
// )}
