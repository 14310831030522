import React, { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/create_specification_set_wizard/CreateSpecificationSetForm.scss';
import Button from '../../components/Button';
import { connect } from 'react-redux';
import { __, getActiveStage, getLocalized } from '../../core/utils';
import Select from '../../components/Select';
import ButtonGroup from '../../components/ButtonGroup';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import classNames from 'classnames';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import Input from '../../components/Input';
import DateTimePicker from '../../components/DateTimePicker';
import Tooltip from '../../components/Tooltip';
import MultiSelect from '../../components/MultiSelect';
import {
  listPossibleSpecificationSetAdministrators,
  listPossibleSpecificationSetFollowers,
} from '../../redux/actions/table/specification_sets';
import PeopleChooser from '../../components/PeopleChooser';
import { listPossibleSpecificationCodes } from '../../redux/actions/table/working_set_boq';

// ICONS
import SpecificationSetStatusIcon from '../../assets/images/tender-status-15x15.svg';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
import TaskVisibilityIcon from '../../assets/images/task-visibility-15x15.svg';

@withRouter
@subscribe(Localization, 'localization')
class CreateSpecificationSetForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      possible_editors: [],
      possible_administrators: [],
      possible_specification_codes: [],
    };
  }

  componentDidMount() {
    listPossibleSpecificationSetAdministrators(getActiveStage()).then(
      ({ response }) => {
        this.setState({ possible_administrators: response.data });
      }
    );

    listPossibleSpecificationSetFollowers(getActiveStage()).then(
      ({ response }) => {
        this.setState({ possible_editors: response.data });
      }
    );

    listPossibleSpecificationCodes(getActiveStage()).then(({ response }) => {
      this.setState({ possible_specification_codes: response.data });
    });
  }

  _handleSubmit = () => {
    setTimeout(() => this.props.submit(), 10);
  };

  render() {
    const {
      possible_editors,
      possible_administrators,
      possible_specification_codes,
    } = this.state;

    const { specification_groups, specification_subgroups } =
      this.props.localization;

    const {
      handleSubmit,
      project_languages,
      localization,
      application_language_id,
      submitting,
    } = this.props;

    const statuses = [
      {
        value: 'draft',
        label: __('table.columns.task-status-draft'),
        color: '#9C27B0',
      },
    ];

    const visibilities = [
      {
        value: '0',
        label: __('task.visibility.private'),
        color: '#7f7f7d',
      },
      {
        value: '1',
        label: __('task.visibility.public'),
        color: '#029DFC',
      },
    ];

    const languages = _.map(project_languages, (primary, language_id) => ({
      label: _.get(localization.languages, [language_id, 'name']),
      value: language_id,
    }));

    const product_attribute_fields = _.sortBy(
      _.map(localization.product_attribute_fields, (field) => ({
        label: getLocalized(field.label, application_language_id),
        tooltip: getLocalized(field.description, application_language_id),
        value: field.id,
        groupPriority: _.get(localization.product_attribute_groups, [
          field.product_attribute_template_group_id,
          'priority',
        ]),
        group: getLocalized(
          _.get(localization.product_attribute_groups, [
            field.product_attribute_template_group_id,
            'name',
          ]),
          application_language_id
        ),
      })),
      'groupPriority'
    );

    const specification_ids = _.map(possible_specification_codes, (item) => {
      const specification_subgroup =
        _.get(specification_subgroups, [item.specification_subgroup_id]) || {};
      const specification_group =
        _.get(
          specification_groups,
          _.get(specification_subgroup, 'specification_group_id')
        ) || {};

      const project_language_id = _.findKey(
        this.props.project_languages,
        (primary) => primary == true
      );

      const code =
        getLocalized(specification_group.code, project_language_id) +
        specification_subgroup.code +
        _.padStart(item.code, 2, '0');
      const title = _.filter(
        [
          getLocalized(item.description, this.props.application_language_id),
          item.model,
          item.brand,
        ],
        _.identity
      ).join(' / ');

      return {
        value: item.specification_id,
        label: _.filter([code, title], _.identity).join(': '),
      };
    });

    return (
      <Form onSubmit={handleSubmit} className={styles.wrapper}>
        <div className={styles.chooser}>
          <div
            className={classNames(
              styles.option,
              !this.props.values.new_set && styles.selected
            )}
            onClick={() => this.props.change('new_set', false)}
          >
            <span />
            {__('specification-sets.load-specification-from-template')}
          </div>

          <div
            className={classNames(
              styles.option,
              this.props.values.new_set && styles.selected
            )}
            onClick={() => this.props.change('new_set', true)}
          >
            <span />
            {__('specification-sets.add-new-specification-set')}
          </div>
        </div>

        {!this.props.values.new_set ? (
          <div className={styles.form}>
            <Field
              name='specification_set_template_id'
              component={Select}
              noSort
              searchable
              label={__(
                'specification-sets.load-specification-set-from-template'
              )}
              options={this.props.specification_set_templates}
            />
          </div>
        ) : (
          <>
            <div className={styles.top}>
              <Field
                name='status'
                component={Select}
                label={__('table.columns.specification-set-status')}
                options={statuses}
                noSort
                dropdown
                icon={<SpecificationSetStatusIcon className={styles.icon} />}
                wrapper={(handleOpen, activeItem) => (
                  <Tooltip text={__('specification-set.tooltip.icon.status')}>
                    <div
                      className={styles.dropdown}
                      style={{ background: activeItem.color }}
                      onClick={handleOpen}
                    >
                      <SpecificationSetStatusIcon className={styles.icon} />
                      <div className={styles.button}>
                        {activeItem.label}
                        <ArrowDownSmallIcon />
                      </div>
                    </div>
                  </Tooltip>
                )}
              />

              <Field
                name='visibility'
                component={Select}
                label={__('specification-set.tooltip.icon.visibility')}
                options={visibilities}
                noSort
                dropdown
                icon={<TaskVisibilityIcon className={styles.setVisibility} />}
                wrapper={(handleOpen, activeItem, elementRef) => (
                  <Tooltip
                    text={__('specification-sets.tooltip.icon.visibility')}
                  >
                    <div
                      className={styles.dropdown}
                      ref={elementRef}
                      style={{ background: activeItem.color }}
                      onClick={handleOpen}
                    >
                      <TaskVisibilityIcon className={styles.setVisibility} />
                      <div className={styles.button}>
                        {activeItem.label}
                        <ArrowDownSmallIcon />
                      </div>
                    </div>
                  </Tooltip>
                )}
              />
            </div>
            <div className={styles.form}>
              <div className={styles.flex}>
                <div className={styles.f5}>
                  <Field
                    name='languages'
                    component={MultiSelect}
                    searchable
                    label={__('Specification Set Languages')}
                    noSort
                    options={languages}
                  />
                </div>
              </div>

              <div className={styles.flex}>
                <div className={styles.f5}>
                  <Field
                    name='date'
                    component={DateTimePicker}
                    label={__('table.columns.specification-set-date-time')}
                    footer
                    range
                    time
                    presets
                  />
                </div>
              </div>

              <Field
                name='title'
                component={Input}
                localized={project_languages}
                label={__('table.columns.specification-set-title')}
                maxLength={50}
              />

              <div className={styles.flex}>
                <div className={styles.f1}>
                  <Field
                    name='administrators'
                    component={PeopleChooser}
                    label={__('table.columns.specification-set-administrators')}
                    searchable
                    options={possible_administrators}
                  />
                </div>
                <div className={styles.f1}>
                  <Field
                    name='editors'
                    component={PeopleChooser}
                    label={__('table.columns.specification-set-editors')}
                    searchable
                    options={possible_editors}
                  />
                </div>
              </div>

              <Field
                name='template_field_ids'
                searchable={true}
                component={MultiSelect}
                label={__(
                  'table.columns.specification-set-product-attribute-group-field'
                )}
                options={product_attribute_fields}
                noSort
              />

              <Field
                name='specification_ids'
                searchable={true}
                component={MultiSelect}
                label={__('table.columns.specification-set-specification-ids')}
                options={specification_ids}
                noSort
              />
            </div>
          </>
        )}

        <div className={styles.footer}>
          <Button
            lightGray
            medium
            middleText={__('button.cancel')}
            onClick={this.props.actions.hideCreateSpecificationSetWizard}
          />
          <ButtonGroup>
            <Button
              lightBlue
              medium
              middleText={__('button.create')}
              spinning={submitting}
              onClick={() => this._handleSubmit(false)}
            />
          </ButtonGroup>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    values: getFormValues(
      'create_specification_set_wizard.create_specification_set_form'
    )(state),
    stage_id: _.get(state.table, 'specification_sets.stage_id'),
    application_language_id: state.auth.language_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      hideCreateSpecificationSetWizard: () =>
        dispatch({
          type: 'table.specification_sets/SET_TABLE_PARAMS',
          params: {
            wizard_active: undefined,
          },
        }),
    },
  };
};

export default withRouter(
  reduxForm({
    form: 'create_specification_set_wizard.create_specification_set_form',
    //enableReinitialize: true,
  })(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(subscribe(Localization, 'localization')(CreateSpecificationSetForm))
  )
);
